import { Card } from 'react-bootstrap';

export type CustomCardProps = {
  imgSrc: string;
  cardTitle: string;
};

const CustomCard = ({ imgSrc, cardTitle }: CustomCardProps) => {
  return (
    <Card className="mb-2" style={{ width: '16rem' }}>
      <Card.Img variant="top" src={imgSrc} style={{ height: '14rem' }} />
      <Card.Body>
        <Card.Title>{cardTitle}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
