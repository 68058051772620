import { useState } from 'react';
import { Button, Form, Pagination, Spinner, Table } from 'react-bootstrap';

import Empty from '../Empty';

export interface Column<T> {
  title: string | React.ReactNode;
  key?: keyof T;
  dataIndex?: string;
  render?: (record: T, index: number, value?: any) => React.ReactNode;
}

type PaginationTypes = {
  defaultPageSize: number;
  pageSizeOptions: number[];
  totalPages: number;
  currentPage: number;
  onChangeCurrentPage: (page: number) => void;
  onChangePage: (page: number, pageSize: number) => void;
};

type EmptyTypes = {
  buttonText: string;
  onButtonClick: () => void;
};

interface CustomTableProps<T> {
  isLoading: boolean;
  dataSource: T[];
  pagination: PaginationTypes;
  columns: Column<T>[];
  rowKey: keyof T;
  empty: EmptyTypes;
}

const CustomTable = <T,>({
  isLoading,
  dataSource,
  columns,
  rowKey,
  pagination: {
    pageSizeOptions,
    totalPages,
    defaultPageSize,
    currentPage,
    onChangeCurrentPage,
    onChangePage,
  },
  empty: { buttonText, onButtonClick },
}: CustomTableProps<T>) => {
  const handlePageChange = (pageNumber: number) => {
    onChangePage(pageNumber, 10);
    onChangeCurrentPage(pageNumber);
  };

  const handleEllipsisClick = (direction: 'start' | 'end') => {
    const delta = 2;
    if (direction === 'start') {
      onChangeCurrentPage(Math.max(1, currentPage - (delta + 1)));
    } else {
      onChangeCurrentPage(Math.min(totalPages, currentPage + (delta + 1)));
    }
  };

  const getPaginationItems = () => {
    const items = [];
    const delta = 2;
    const startPage = Math.max(1, currentPage - delta);
    const endPage = Math.min(totalPages, currentPage + delta);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>,
      );
      if (startPage > 2) {
        items.push(
          <Pagination.Ellipsis
            key="start-ellipsis"
            onClick={() => handleEllipsisClick('start')}
          />,
        );
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>,
      );
    }

    if (endPage < totalPages - 1) {
      items.push(
        <Pagination.Ellipsis
          key="end-ellipsis"
          onClick={() => handleEllipsisClick('end')}
        />,
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>,
      );
    }

    return items;
  };

  const [currentPageSize, onChangeCurrentPageSize] = useState(defaultPageSize);

  const handlePageSizeChange = (pageSize: number) => {
    onChangeCurrentPageSize(pageSize);
  };

  return (
    <div>
      <div style={{ height: 600, overflow: 'auto' }}>
        <Table hover responsive bordered className="align-middle">
          <thead className="align-middle">
            <tr className="table-light">
              {columns.map((col, colIndex) => (
                <th key={col.key ? col.key.toString() : `col-${colIndex}`}>
                  {col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={12}>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: 400 }}
                  >
                    <Spinner variant="optain" />
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && dataSource.length === 0 && (
              <tr>
                <td colSpan={12}>
                  <Empty
                    children={
                      <Button variant="optain" onClick={onButtonClick}>
                        {buttonText}
                      </Button>
                    }
                  />
                </td>
              </tr>
            )}

            {!isLoading && dataSource.length > 0 && (
              <>
                {dataSource.map((record, rowIndex) => (
                  <tr key={String(record[rowKey])}>
                    {columns.map((col, colIndex) => (
                      <td
                        key={
                          col.key
                            ? col.key.toString()
                            : `cell-${rowIndex}-${colIndex}`
                        }
                      >
                        {col.render
                          ? col.dataIndex
                            ? col.render(record, rowIndex)
                            : col.render(
                                record,
                                rowIndex,
                                record[col.key as keyof T],
                              )
                          : `${record[col.key as keyof T]}`}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </div>

      {!isLoading && dataSource.length > 0 && (
        <div className="d-flex justify-content-center align-items-baseline gap-4">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {getPaginationItems()}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>

          <Form.Group controlId="pageSizeSelect">
            <Form.Control
              as="select"
              style={{ height: 38 }}
              value={currentPageSize}
              onChange={({ currentTarget: { value } }) => {
                handlePageSizeChange(+value);
                onChangePage(currentPage, +value);
              }}
            >
              {pageSizeOptions.map((size) => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
