import { Col, Row, Spinner, Stack } from 'react-bootstrap';

import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackendStatus,
  GlobalStatus,
} from 'src/models/GalenData/DeviceDataView.model';
import { Session } from 'src/models/Session.model';

import isGradable from 'src/utils/isGradable';

const GlobalStatusMapping: Record<GlobalStatus, string> = {
  [GlobalStatus.Null]: 'Awaiting Upload',
  [GlobalStatus.Failed]: 'Server Error',
  [GlobalStatus.InitializerStarted]: 'Initializing',
  [GlobalStatus.InitializerEnded]: 'Initialized',
  [GlobalStatus.ModelsStarted]: 'Analyzing',
  [GlobalStatus.ReportStarted]: 'Generating Results',
  [GlobalStatus.ReportEnd]: 'Complete',
};

const GlobalStack = ({
  session,
  status,
}: {
  session: Session;
  status: BackendStatus;
}) => {
  const StatusText = (globalStatus: GlobalStatus, gradable: boolean) => {
    if (!gradable) {
      return (
        <Col sm={3} className="text-danger text-end">
          Ungradable
        </Col>
      );
    }

    if (globalStatus === GlobalStatus.Failed) {
      return (
        <Col sm={3} className="text-danger text-end">
          Server Error
        </Col>
      );
    }

    return (
      <Col sm={3} className="text-secondary-400 text-end">
        {GlobalStatusMapping[globalStatus]}
      </Col>
    );
  };

  const globalStatus = status.GlobalStatus.value;

  const gradable = isGradable(globalStatus, session);

  return (
    <Stack gap={3}>
      <Row>
        <Col sm={9}>
          {globalStatus === GlobalStatus.Failed || !gradable ? (
            <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />
          ) : globalStatus === GlobalStatus.ReportEnd && gradable ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-optain-900" />
          ) : (
            <Spinner variant="optain" size="sm" />
          )}

          <strong className="ms-3">System Status</strong>
        </Col>
        {StatusText(globalStatus, gradable)}
      </Row>
    </Stack>
  );
};

export default GlobalStack;
