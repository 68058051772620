import axios from 'axios';

import useBoundStore from 'src/store/useBoundStore';

import {
  AxiosResponseWithRefreshToken,
  ContentTypeEnum,
} from 'src/models/GalenData/Request.model';

const baseURL = process.env.REACT_APP_BASE_URL_GALEN_DATA;

const httpRequest = axios.create({
  baseURL,
  timeout: 10_000,
  headers: {
    'X-APP-TYPE': process.env.REACT_APP_X_APP_TYPE,
    'X-API-VERSION': process.env.REACT_APP_X_API_VERSION,
    'X-TENANT-DOMAIN': process.env.REACT_APP_X_TENANT_DOMAIN,
    'Content-Type': ContentTypeEnum.JSON,
  },
});

httpRequest.interceptors.request.use((config) => {
  const authToken = useBoundStore.getState().authToken;

  if (authToken) {
    config.headers['Authorization'] = authToken;
  }

  return config;
});

httpRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // 1. network error
    if (!error.response) {
      throw error;
    }

    const { response, config } = error;

    // 2. server error
    if (response?.status !== 401) {
      throw error;
    }

    const { authToken, refreshToken } = useBoundStore.getState();

    // 3. refresh_token has been deleted
    if (!refreshToken) {
      // TODO: need to add AuthRouter
      // history.push('/login', { state: { from: history.location } });
      useBoundStore.getState().logOut();
      throw error;
    }

    // 4. if the auth_token has been expired, get new authorization token
    try {
      const res = await axios<void, AxiosResponseWithRefreshToken>({
        method: 'post',
        url: baseURL + '/auth/refresh-authtoken',
        headers: {
          'X-APP-TYPE': process.env.REACT_APP_X_APP_TYPE,
          'X-API-VERSION': process.env.REACT_APP_X_API_VERSION,
          'X-TENANT-DOMAIN': process.env.REACT_APP_X_TENANT_DOMAIN,
          'Content-Type': ContentTypeEnum.TEXT,
          Authorization: authToken,
        },
        data: refreshToken,
      });

      const newAuthToken = res.headers.authorization;
      const newRefreshToken = res.headers['x-refresh-token'];

      const { setAuth } = useBoundStore.getState();
      setAuth({
        authToken: newAuthToken,
        refreshToken: newRefreshToken,
      });

      // Retry the original request with the new token
      config.headers['Authorization'] = newAuthToken;
      return httpRequest(config);
    } catch (error) {
      // 5. if get new token failed
      useBoundStore.getState().logOut();
      // history.push('/login', { from: history.location });
      // Toast.info('Token expired', 1);
      throw error;
    }
  },
);

export default httpRequest;
