import { Product } from 'src/models/Product.model';

const getRetinalExamDataModelConfig = (product: Product) => {
  if (product === Product.TELEOPHTH) {
    return {
      deviceDataModelId: process.env.REACT_APP_MODEL_ID_RETINAL_EXAM_TELEOPHTH,
      devicePropertySetId:
        process.env
          .REACT_APP_PROPERTY_SET_ID_EXAM_SUBMISSION_RETINAL_EXAM_TELEOPHTH,
    };
  }
  return {
    deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
    devicePropertySetId:
      process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION,
  };
};

export default getRetinalExamDataModelConfig;
