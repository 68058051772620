import { Disease } from 'src/models/Disease.model';
import {
  FindGrading,
  GradingIsNegative,
  GradingIsPositive,
} from 'src/models/Grading.model';
import { Result } from 'src/models/Result.model';
import { Session } from 'src/models/Session.model';

const checkIsGradable = (results: Result[]) => {
  return !results.some(
    (res) =>
      res === undefined || res.error !== undefined || res.gradable === false,
  );
};

export default function getGradingResult(session: Session) {
  const isLeftPositive = (disease: Disease) => {
    const grading = FindGrading(session.leftResults, disease);

    return grading ? GradingIsPositive(grading) : false;
  };

  const isRightPositive = (disease: Disease) => {
    const grading = FindGrading(session.rightResults, disease);

    return grading ? GradingIsPositive(grading) : false;
  };

  const getPositiveResultDiseases = (diseases: Disease[]) => {
    return [
      ...new Set(
        diseases.filter(
          (disease) => isLeftPositive(disease) || isRightPositive(disease),
        ),
      ),
    ];
  };

  const getLeftPositiveResultDiseases = (diseases: Disease[]) => {
    return [...new Set(diseases.filter((disease) => isLeftPositive(disease)))];
  };

  const getRightPositiveResultDiseases = (diseases: Disease[]) => {
    return [...new Set(diseases.filter((disease) => isRightPositive(disease)))];
  };

  const isLeftEyeNegative = (disease: Disease) => {
    const grading = FindGrading(session.leftResults, disease);

    return grading ? GradingIsNegative(grading) : false;
  };

  const isRightEyeNegative = (disease: Disease) => {
    const grading = FindGrading(session.rightResults, disease);

    return grading ? GradingIsNegative(grading) : false;
  };

  const getNegativeResultDiseases = (diseases: Disease[]) => {
    return [
      ...new Set(
        diseases.filter(
          (disease) =>
            isLeftEyeNegative(disease) || isRightEyeNegative(disease),
        ),
      ),
    ];
  };

  const isLeftEyeGradable =
    !!session.leftResults?.length && checkIsGradable(session.leftResults);

  const isRightEyeGradable =
    !!session.rightResults?.length && checkIsGradable(session.rightResults);

  const isLeftEyeUngradable = !isLeftEyeGradable;

  const isRightEyeUngradable = !isRightEyeGradable;

  const hasUngradableResult = isLeftEyeUngradable || isRightEyeUngradable;

  const totalGradableEyes =
    (isLeftEyeGradable ? 1 : 0) + (isRightEyeGradable ? 1 : 0);

  return {
    getPositiveResultDiseases,
    getNegativeResultDiseases,
    getLeftPositiveResultDiseases,
    getRightPositiveResultDiseases,
    isLeftPositive,
    isRightPositive,
    isLeftEyeNegative,
    isRightEyeNegative,
    isLeftEyeGradable,
    isRightEyeGradable,
    isLeftEyeUngradable,
    isRightEyeUngradable,
    hasUngradableResult,
    totalGradableEyes,
  };
}
