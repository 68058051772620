import { Dispatch, SetStateAction, useState } from 'react';
import { Col, Container, Dropdown, Form, Row, Stack } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { faCamera, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import useBoundStore from 'src/store/useBoundStore';

import WarnNoConnectedCameraModal from 'src/components/custom_components/WarnNoConnectedCameraModal';
import PatientInfoConfirmationModal, {
  DilationEnum,
} from 'src/components/PatientInfoConfirmationModal/PatientInfoConfirmationModal';
import SearchPatientInfoFormGroup from 'src/components/SearchPatientInfoFormGroup/SearchPatientInfoFormGroup';

import { ApplicationInstance } from 'src/models/Application.model';
import { Camera } from 'src/models/Camera.model';
import { Voice } from 'src/models/Camera/Settings/Language/GetLanguages';
import { SetLanguageType } from 'src/models/Camera/Settings/Language/SetLanguage';
import { CameraMode } from 'src/models/CameraMode.model';
import { Gender } from 'src/models/Gender.model';
import { ReportTemplate } from 'src/models/ReportTemplate.model';
import { PatientInfoKeyMapOptain, Session } from 'src/models/Session.model';

import { useGetCameraLanguages } from 'src/hooks/camera/useGetCameraLanguages';
import { useGetCameraSettings } from 'src/hooks/camera/useGetCameraSettings';
import { useSetCameraLanguage } from 'src/hooks/camera/useSetCameraLanguage';

interface INewPatientExamProps {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  cameraMode: CameraMode;
}

const NewPatientExam = ({
  session,
  cameraMode,
  setSession,
}: INewPatientExamProps) => {
  const [formValues, setFormValues] = useState<Partial<Session>>(session);

  const history = useHistory();

  const handleConfirm = (data: {
    leftEyeDilatedTime: string;
    rightEyeDilatedTime: string;
    template: ReportTemplate;
  }) => {
    setSession({
      ...session,
      ...formValues,
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm:ss'),
      createdAt: new Date(),
      leftEyeDilatedTime:
        data.leftEyeDilatedTime === DilationEnum.Dilated
          ? new Date()
          : undefined,
      rightEyeDilatedTime:
        data.rightEyeDilatedTime === DilationEnum.Dilated
          ? new Date()
          : undefined,
      template: data.template,
      orderId: formValues.orderId,
    });

    history.push('/diabetes-data');
  };

  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);

  const handleCloseConfirmModal = () => {
    setIsShowConfirmModal(false);
  };

  const onSubmit: SubmitHandler<Partial<Session>> = (data) => {
    const dob = moment(data.dob).format('YYYY-MM-DD');
    const age = moment().diff(data.dob, 'years');

    setFormValues({
      ...data,
      dob,
      age,
    });
    setIsShowConfirmModal(true);
  };

  const { username, product, camera, cameraData } = useBoundStore();

  const methods = useForm({
    defaultValues: {
      id: session.id,
      firstName: session.firstName,
      lastName: session.lastName,
      dob: session.dob,
      leftEyeDilatedTime: session.leftEyeDilatedTime,
      rightEyeDilatedTime: session.rightEyeDilatedTime,
      template: ApplicationInstance(product, camera).templates[0],
      gender: Gender.MALE,
    },
  });

  const { languages } = useGetCameraLanguages(!!cameraData);

  const { settings, getCameraSettings } = useGetCameraSettings(!!cameraData);

  const { setCameraLanguage, isMutating: isUpdatingLanguage } =
    useSetCameraLanguage();

  return (
    <Container
      fluid
      className="main-content optain-bg"
      style={{ overflow: 'hidden' }}
    >
      <Stack gap={4} className="h-100">
        <Row>
          <Col md={6}>
            <div>
              <p className="text-optain mb-0">NEW EXAM</p>
              <h3>New Patient Exam</h3>
            </div>
          </Col>

          {cameraData && (
            <Col md={6} className="d-flex justify-content-end gap-4">
              <Dropdown>
                <Dropdown.Toggle variant="outline-info">
                  <FontAwesomeIcon
                    icon={isUpdatingLanguage ? faSpinner : faCamera}
                    className="me-2"
                  ></FontAwesomeIcon>
                  {isUpdatingLanguage ? 'Updating...' : 'Camera Language'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {languages?.obj.voice.map((lang: Voice) => (
                    <Dropdown.Item
                      key={lang.label}
                      active={lang.value === settings?.obj.settings.voice}
                      disabled={!settings}
                      onClick={async () => {
                        if (!settings) return;
                        await setCameraLanguage({
                          lang: lang.value,
                          type: SetLanguageType.Voice,
                        });
                        getCameraSettings();
                      }}
                    >
                      {lang.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          )}
        </Row>
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="h-100 d-flex flex-column justify-content-between pb-5"
          >
            <SearchPatientInfoFormGroup
              session={session}
              setSession={setSession}
              handleSubmit={methods.handleSubmit(onSubmit)}
              isCreateEnabled={false}
            />

            <PatientInfoConfirmationModal
              formValues={formValues}
              isShowConfirmModal={isShowConfirmModal}
              handleConfirmPatientInfo={handleConfirm}
              handleCloseConfirmModal={handleCloseConfirmModal}
              InfoConfirmationKeyMap={PatientInfoKeyMapOptain}
            />
          </Form>
        </FormProvider>
      </Stack>

      {username && (
        <WarnNoConnectedCameraModal
          isVisible={
            camera === Camera.SMALL &&
            cameraMode === CameraMode.API &&
            !cameraData
          }
        />
      )}
    </Container>
  );
};

export default NewPatientExam;
