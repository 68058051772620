import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { REM, SCALE } from 'src/utils/reactPdfStyles';

interface FontAwesomeIconProps {
  icon: IconDefinition;
  style?: Style[];
}

const FontAwesomeIcon = ({
  icon: { icon },
  style = [],
}: FontAwesomeIconProps) => {
  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];

  // default style
  style.unshift({ marginRight: 2 });
  style.unshift({ width: 1 * REM * SCALE });

  let color = 'black';
  for (const element of style) {
    if (element && element.color) {
      color = element.color as string;
    }
  }

  return (
    <Svg viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={style}>
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1}
          />
        ))}
    </Svg>
  );
};

export default FontAwesomeIcon;
