enum Camera {
  SMALL = 'small',
  LARGE = 'large',
  DRSPLUS = 'drsplus',
}

const CameraName = (camera: Camera): string => {
  switch (camera) {
    case Camera.SMALL: {
      return 'portable';
    }
    case Camera.LARGE: {
      return 'desktop';
    }
    case Camera.DRSPLUS: {
      return 'DRS Plus';
    }
    default: {
      return `unknown: ${camera}`;
    }
  }
};

export { Camera, CameraName };
