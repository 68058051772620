import { Font, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import * as Sentry from '@sentry/react';

import { Language } from 'src/models/Language.model';

import i18n from '../i18n';

// font repo: https://fonts.google.com/noto

Font.register({
  family: 'Noto Sans',
  src: '/fonts/NotoSans-Regular.ttf',
});

Font.register({
  family: 'Noto Sans Italic',
  src: '/fonts/NotoSans-Italic.ttf',
});

Font.register({
  family: 'Noto Sans Bold',
  src: '/fonts/NotoSans-Bold.ttf',
});

Font.register({
  family: 'Noto Sans SemiBold',
  src: '/fonts/NotoSans-SemiBold.ttf',
});

// Font.register({
//   family: 'Noto Sans Arabic',
//   src: '/fonts/NotoSansArabic-Regular.ttf',
// });

// Font.register({
//   family: 'Noto Sans Arabic Bold',
//   src: '/fonts/NotoSansArabic-Bold.ttf',
// });

// Font.register({
//   family: 'Noto Sans Arabic SemiBold',
//   src: '/fonts/NotoSansArabic-SemiBold.ttf',
// });

enum FontStyle {
  REGULAR = '',
  ITALIC = 'Italic', // only available for Latin languages
  BOLD = 'Bold',
  SEMIBOLD = 'SemiBold',
}

const languageFont = (lang: Language, style: FontStyle = FontStyle.REGULAR) => {
  switch (lang) {
    case Language.ENGLISH:
    case Language.SPANISH:
    case Language.FRENCH:
    case Language.ITALIAN:
    case Language.GERMAN:
    case Language.DUTCH:
    case Language.PORTUGUESE:
    case Language.VIETNAMESE:
    case Language.INDONESIAN: {
      return 'Noto Sans' + (style ? ` ${style}` : '');
    }
    // case Language.ARABIC: {
    //   return (
    //     'Noto Sans Arabic' +
    //     (style && style != FontStyle.ITALIC ? ` ${style}` : '')
    //   );
    // }
  }
};

const REM = 15;
const SCALE = 0.55;

const stylesheetMap: { [key: string]: any } = {};

const getStyleSheet = (lang: Language) => {
  if (lang in stylesheetMap) {
    return stylesheetMap[lang];
  }

  const stylesheet = StyleSheet.create({
    body: {
      fontFamily: languageFont(lang),
      fontSize: 1 * REM * SCALE,
      fontWeight: 400 * SCALE,
    },

    'pt-0': {
      paddingTop: 0,
    },
    'pt-1': {
      paddingTop: 0.25 * REM * SCALE,
    },
    'pt-2': {
      paddingTop: 0.5 * REM * SCALE,
    },
    'pt-3': {
      paddingTop: 1 * REM * SCALE,
    },
    'pt-4': {
      paddingTop: 1.5 * REM * SCALE,
    },
    'pt-5': {
      paddingTop: 3 * REM * SCALE,
    },
    'pb-0': {
      paddingBottom: 0,
    },
    'pb-1': {
      paddingBottom: 0.25 * REM * SCALE,
    },
    'pb-2': {
      paddingBottom: 0.5 * REM * SCALE,
    },
    'pb-3': {
      paddingBottom: 1 * REM * SCALE,
    },
    'pb-4': {
      paddingBottom: 1.5 * REM * SCALE,
    },
    'pb-5': {
      paddingBottom: 3 * REM * SCALE,
    },
    'ps-0': {
      paddingLeft: 0,
    },
    'ps-1': {
      paddingLeft: 0.25 * REM * SCALE,
    },
    'ps-2': {
      paddingLeft: 0.5 * REM * SCALE,
    },
    'ps-3': {
      paddingLeft: 1 * REM * SCALE,
    },
    'ps-4': {
      paddingLeft: 1.5 * REM * SCALE,
    },
    'ps-5': {
      paddingLeft: 3 * REM * SCALE,
    },
    'pe-0': {
      paddingRight: 0,
    },
    'pe-1': {
      paddingRight: 0.25 * REM * SCALE,
    },
    'pe-2': {
      paddingRight: 0.5 * REM * SCALE,
    },
    'pe-3': {
      paddingRight: 1 * REM * SCALE,
    },
    'pe-4': {
      paddingRight: 1.5 * REM * SCALE,
    },
    'pe-5': {
      paddingRight: 3 * REM * SCALE,
    },
    'px-0': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    'px-1': {
      paddingLeft: 0.25 * REM * SCALE,
      paddingRight: 0.25 * REM * SCALE,
    },
    'px-2': {
      paddingLeft: 0.5 * REM * SCALE,
      paddingRight: 0.5 * REM * SCALE,
    },
    'px-3': {
      paddingLeft: 1 * REM * SCALE,
      paddingRight: 1 * REM * SCALE,
    },
    'px-4': {
      paddingLeft: 1.5 * REM * SCALE,
      paddingRight: 1.5 * REM * SCALE,
    },
    'px-5': {
      paddingLeft: 3 * REM * SCALE,
      paddingRight: 3 * REM * SCALE,
    },
    'py-0': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    'py-1': {
      paddingTop: 0.25 * REM * SCALE,
      paddingBottom: 0.25 * REM * SCALE,
    },
    'py-2': {
      paddingTop: 0.5 * REM * SCALE,
      paddingBottom: 0.5 * REM * SCALE,
    },
    'py-3': {
      paddingTop: 1 * REM * SCALE,
      paddingBottom: 1 * REM * SCALE,
    },
    'py-4': {
      paddingTop: 1.5 * REM * SCALE,
      paddingBottom: 1.5 * REM * SCALE,
    },
    'py-5': {
      paddingTop: 1.5 * REM * SCALE,
      paddingBottom: 1.5 * REM * SCALE,
    },
    'p-0': {
      padding: 0,
    },
    'p-1': {
      padding: 0.25 * REM * SCALE,
    },
    'p-1.5': {
      padding: 0.375 * REM * SCALE,
    },
    'p-2': {
      padding: 0.5 * REM * SCALE,
    },
    'p-3': {
      padding: 1 * REM * SCALE,
    },
    'p-4': {
      padding: 1.5 * REM * SCALE,
    },
    'p-5': {
      padding: 3 * REM * SCALE,
    },

    'mt-0': {
      marginTop: 0,
    },
    'mt-1': {
      marginTop: 0.25 * REM * SCALE,
    },
    'mt-2': {
      marginTop: 0.5 * REM * SCALE,
    },
    'mt-3': {
      marginTop: 1 * REM * SCALE,
    },
    'mt-4': {
      marginTop: 1.5 * REM * SCALE,
    },
    'mt-5': {
      marginTop: 3 * REM * SCALE,
    },
    'mb-0': {
      marginBottom: 0,
    },
    'mb-1': {
      marginBottom: 0.25 * REM * SCALE,
    },
    'mb-2': {
      marginBottom: 0.5 * REM * SCALE,
    },
    'mb-3': {
      marginBottom: 1 * REM * SCALE,
    },
    'mb-4': {
      marginBottom: 1.5 * REM * SCALE,
    },
    'mb-5': {
      marginBottom: 3 * REM * SCALE,
    },
    'ms-0': {
      marginLeft: 0,
    },
    'ms-1': {
      marginLeft: 0.25 * REM * SCALE,
    },
    'ms-2': {
      marginLeft: 0.5 * REM * SCALE,
    },
    'ms-3': {
      marginLeft: 1 * REM * SCALE,
    },
    'ms-4': {
      marginLeft: 1.5 * REM * SCALE,
    },
    'ms-5': {
      marginLeft: 3 * REM * SCALE,
    },
    'ms-auto': {
      marginLeft: 'auto',
    },
    'me-0': {
      marginRight: 0,
    },
    'me-1': {
      marginRight: 0.25 * REM * SCALE,
    },
    'me-2': {
      marginRight: 0.5 * REM * SCALE,
    },
    'me-3': {
      marginRight: 1 * REM * SCALE,
    },
    'me-4': {
      marginRight: 1.5 * REM * SCALE,
    },
    'me-5': {
      marginRight: 3 * REM * SCALE,
    },
    'me-auto': {
      marginRight: 'auto',
    },
    'mx-0': {
      marginLeft: 0,
      marginRight: 0,
    },
    'mx-1': {
      marginLeft: 0.25 * REM * SCALE,
      marginRight: 0.25 * REM * SCALE,
    },
    'mx-2': {
      marginLeft: 0.5 * REM * SCALE,
      marginRight: 0.5 * REM * SCALE,
    },
    'mx-3': {
      marginLeft: 1 * REM * SCALE,
      marginRight: 1 * REM * SCALE,
    },
    'mx-4': {
      marginLeft: 1.5 * REM * SCALE,
      marginRight: 1.5 * REM * SCALE,
    },
    'mx-5': {
      marginLeft: 3 * REM * SCALE,
      marginRight: 3 * REM * SCALE,
    },
    'my-0': {
      marginTop: 0,
      marginBottom: 0,
    },
    'my-1': {
      marginTop: 0.25 * REM * SCALE,
      marginBottom: 0.25 * REM * SCALE,
    },
    'my-2': {
      marginTop: 0.5 * REM * SCALE,
      marginBottom: 0.5 * REM * SCALE,
    },
    'my-3': {
      marginTop: 1 * REM * SCALE,
      marginBottom: 1 * REM * SCALE,
    },
    'my-4': {
      marginTop: 1.5 * REM * SCALE,
      marginBottom: 1.5 * REM * SCALE,
    },
    'my-5': {
      marginTop: 1.5 * REM * SCALE,
      marginBottom: 1.5 * REM * SCALE,
    },
    'm-0': {
      margin: 0,
    },
    'm-1': {
      margin: 0.25 * REM * SCALE,
    },
    'm-2': {
      margin: 0.5 * REM * SCALE,
    },
    'm-3': {
      margin: 1 * REM * SCALE,
    },
    'm-4': {
      margin: 1.5 * REM * SCALE,
    },
    'm-5': {
      margin: 3 * REM * SCALE,
    },

    'text-end': {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-end',
    },

    vstack: {
      display: 'flex',
      flexDirection: 'column',
    },
    hstack: {
      display: 'flex',
      flexDirection: 'row',
    },

    'gap-1': {
      gap: 0.25 * REM * SCALE,
    },
    'gap-2': {
      gap: 0.5 * REM * SCALE,
    },
    'gap-3': {
      gap: 1 * REM * SCALE,
    },
    'gap-4': {
      gap: 1.5 * REM * SCALE,
    },
    'gap-5': {
      gap: 3 * REM * SCALE,
    },

    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
    'flex-1': {
      flex: 1,
    },
    'd-flex': {
      display: 'flex',
      flexDirection: 'row',
    },
    'justify-content-between': {
      justifyContent: 'space-between',
    },
    'justify-content-center': {
      justifyContent: 'center',
    },

    'align-items-center': {
      alignItems: 'center',
    },
    'align-middle': {
      marginVertical: 'auto',
    },
    'text-start': {
      textAlign: 'left',
    },
    'text-center': {
      textAlign: 'center',
    },
    'tex-end': {
      textAlign: 'right',
    },

    'rounded-pill': {
      borderRadius: 750 * SCALE,
    },

    'bg-primary': {
      backgroundColor: '#3ec8ae',
    },
    'bg-secondary': {
      backgroundColor: '#6c757d',
    },
    'bg-success': {
      backgroundColor: '#3fb618',
    },
    'bg-warning': {
      backgroundColor: '#ff7518',
    },
    'bg-danger': {
      backgroundColor: '#dc3545',
    },
    'bg-danger-200': {
      backgroundColor: '#ffe3e3',
    },
    'bg-primary-600': {
      backgroundColor: '#1c554f',
    },
    'bg-primary-200': {
      backgroundColor: '#d5efec',
    },
    'bg-secondary-700': {
      backgroundColor: '#585858',
    },
    'bg-secondary-400': {
      backgroundColor: '#bfbfbf',
    },
    'bg-secondary-200': {
      backgroundColor: '#efefef',
    },
    'bg-black': {
      backgroundColor: '#000000',
    },
    'bg-light': {
      backgroundColor: '#f8f9fa',
    },

    'text-primary': {
      color: '#3ec8ae',
    },
    'text-primary-600': {
      color: '#1c554f',
    },
    'text-secondary': {
      color: '#6c757d',
    },
    'text-secondary-700': {
      color: '#585858',
    },
    'text-white': {
      color: '#ffffff',
    },
    'text-black': {
      color: '#212529',
    },
    'text-success': {
      color: '#3fb618',
    },
    'text-warning': {
      color: '#ff7518',
    },
    'text-danger': {
      color: '#dc3545',
    },
    'text-muted': {
      color: '#6c757d',
    },
    'text-gray': {
      color: '#eaedf0',
    },
    'text-optain': {
      color: '#0d3082',
    },
    lead: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontWeight: 700 * SCALE,
      fontSize: 1.25 * REM * SCALE,
    },
    'text-classification': {
      fontSize: 0.9 * REM * SCALE,
    },
    small: {
      fontSize: 0.875 * REM * SCALE,
    },

    border: {
      borderWidth: 0.8 * SCALE,
      borderColor: '#dee2e6',
    },
    'border-top': {
      borderTopWidth: 0.8 * SCALE,
      borderTopColor: '#dee2e6',
    },
    'border-bottom': {
      borderBottomWidth: 0.8 * SCALE,
      borderbottomColor: '#dee2e6',
    },
    'border-0': {
      borderWidth: 0,
    },
    'border-1': {
      borderWidth: 0.8 * SCALE,
      borderColor: '#dee2e6',
    },
    'border-start': {
      borderLeftWidth: 0.8 * SCALE,
      borderLeftColor: '#dee2e6',
    },
    'border-end': {
      borderRightWidth: 0.8 * SCALE,
      borderRightColor: '#dee2e6',
    },

    h1: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontSize: 2.5 * REM * SCALE,
      fontWeight: 500 * SCALE,
      lineHeight: 1.2,
      marginBottom: 0.5 * REM * SCALE,
      marginTop: 0,
    },
    h2: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontSize: 2 * REM * SCALE,
      fontWeight: 500 * SCALE,
      lineHeight: 1.2,
      marginBottom: 0.5 * REM * SCALE,
      marginTop: 0,
    },
    h3: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontSize: 1.75 * REM * SCALE,
      fontWeight: 500 * SCALE,
      lineHeight: 1.2,
      marginBottom: 0.5 * REM * SCALE,
      marginTop: 0,
    },
    h4: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontSize: 1.5 * REM * SCALE,
      fontWeight: 500 * SCALE,
      lineHeight: 1.2,
      marginBottom: 0.5 * REM * SCALE,
      marginTop: 0,
    },
    h5: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontSize: 1.25 * REM * SCALE,
      fontWeight: 500 * SCALE,
      lineHeight: 1.2,
      marginBottom: 0.5 * REM * SCALE,
      marginTop: 0,
    },
    h6: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
      fontSize: 1 * REM * SCALE,
      fontWeight: 500 * SCALE,
      lineHeight: 1.2,
      marginBottom: 0.5 * REM * SCALE,
      marginTop: 0,
    },

    'w-10': {
      width: '10%',
    },
    'w-15': {
      width: '15%',
    },
    'w-20': {
      width: '20%',
    },
    'w-25': {
      width: '25%',
    },
    'w-30': {
      width: '30%',
    },
    'w-33': {
      width: '33%',
    },
    'w-34': {
      width: '34%',
    },
    'w-40': {
      width: '40%',
    },
    'w-50': {
      width: '50%',
    },
    'w-60': {
      width: '60%',
    },
    'w-70': {
      width: '70%',
    },
    'w-80': {
      width: '80%',
    },
    'w-90': {
      width: '90%',
    },
    'w-100': {
      width: '100%',
    },

    'disease-column': {
      width: '200px',
    },

    'disease-column-1': {
      width: '100px',
    },

    'disease-column-2': {
      width: '100px',
    },

    'disease-column-2-output-definition-columns': {
      width: '540px',
    },

    'output-definition-columns': {
      width: '440px',
    },

    b: {
      fontFamily: languageFont(lang, FontStyle.BOLD),
      fontWeight: 'bold',
    },
    bb: {
      // replacement of 'b' if inline 'b' is not supported
      backgroundColor: '#efefef', // bg-secondary-200
      color: '#0d3082', // text-optain
    },
    bold: {
      fontFamily: languageFont(lang, FontStyle.BOLD),
      fontWeight: 'bold',
    },
    strong: {
      fontFamily: languageFont(lang, FontStyle.BOLD),
      fontWeight: 'bold',
    },
    semibold: {
      fontFamily: languageFont(lang, FontStyle.SEMIBOLD),
    },

    i: {
      fontFamily: languageFont(lang, FontStyle.ITALIC),
    },

    rounded: {
      borderRadius: 5.625 * SCALE,
    },
    dashed: {
      borderLeftWidth: 1 * SCALE,
      borderLeftStyle: 'dashed',
      borderLeftColor: '#1f77d5',
    },
    'border-top-0': {
      borderTop: 0,
    },

    td: {
      paddingLeft: 0.8 * REM * SCALE,
      paddingRight: 0.8 * REM * SCALE,
      paddingTop: 0.5 * REM * SCALE,
      paddingBottom: 0.5 * REM * SCALE,
      borderBottom: 1 * SCALE,
      borderColor: '#dee2e6',
    },

    li: {
      marginBottom: 4 * SCALE,
    },

    'position-relative': {
      position: 'relative',
    },
    'position-absolute': {
      position: 'absolute',
    },

    'img-fluid': {
      height: 'auto',
      maxWidth: '100%',
    },

    alert: {},

    'bottom-0': {
      bottom: 0,
    },
    'start-0': {
      left: 0,
    },

    p: {
      width: '100%',
      marginBottom: 1 * REM * SCALE,
    },

    hr: {
      borderTopWidth: 1 * SCALE,
      borderColor: '#212529',
      marginTop: 1 * REM * SCALE,
      marginBottom: 1 * REM * SCALE,
      marginLeft: 0,
      marginRight: 0,
      opacity: 0.25,
    },

    sup: {
      top: -0.5 * REM * SCALE,
      fontSize: 0.75 * REM * SCALE,
      lineHeight: 0,
      position: 'relative',
      verticalAlign: 'super',
    },

    'optain_bg-report': {
      position: 'absolute',
      height: 500 * SCALE,
      width: 500 * SCALE,
      left: `${-6 * SCALE}%`,
      top: `${-13 * SCALE}%`,
    },
    'report-header-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    'report-header-no-logo': {
      color: '#1f77d5',
      fontWeight: 600 * SCALE,
      marginTop: 10 * SCALE,
    },
    'header-img': {
      width: 500 * SCALE,
    },
    'company-logo': {
      height: 50 * SCALE,
      marginTop: 75 * SCALE,
      maxWidth: 200 * SCALE,
    },
    'report-text': {
      backgroundColor: '#f5f3f1',
    },
    'section-text': {
      color: '#1f77d5',
    },
    'segmentation-image-1': {
      width: 139,
      height: 139,
      borderTopLeftRadius: 5.625 * SCALE,
      objectFit: 'cover',
      objectPosition: 'center',
    },
    'segmentation-image-2': {
      width: 139,
      height: 139,
      borderTopRightRadius: 5.625 * SCALE,
      objectFit: 'cover',
      objectPosition: 'center',
    },
    'segmentation-image-3': {
      width: 139,
      height: 139,
      borderBottomLeftRadius: 5.625 * SCALE,
      objectFit: 'cover',
      objectPosition: 'center',
    },
    'segmentation-image-4': {
      width: 139,
      height: 139,
      borderBottomRightRadius: 5.625 * SCALE,
      objectFit: 'cover',
      objectPosition: 'center',
    },

    'vascular-circle': {
      borderRadius: 75 * SCALE,
      width: 150 * SCALE,
      height: 150 * SCALE,
      fontSize: 56 * SCALE,
      backgroundColor: '#11588d',
      color: '#ffffff',
      textAlign: 'center',
      paddingTop: 35 * SCALE,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    'vascular-circle-small': {
      borderRadius: 50 * SCALE,
      width: 96 * SCALE,
      height: 96 * SCALE,
      fontSize: 36 * SCALE,
      backgroundColor: '#11588d',
      color: '#ffffff',
      textAlign: 'center',
      paddingTop: 24 * SCALE,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    'bg-vascular': {
      backgroundColor: '#11588d',
    },

    'bg-vascular-alt': {
      backgroundColor: '#588aaf',
    },

    badge: {
      color: '#ffffff',
      display: 'flex',
      flexDirection: 'row',
      paddingHorizontal: 0.65 * REM * SCALE,
      paddingVertical: 0.35 * REM * SCALE,
      fontSize: 0.9375 * REM * SCALE,
      fontWeight: 400 * SCALE,
      borderRadius: 20 * SCALE,
      width: 'auto',
    },
    'badge-font': {
      fontSize: 0.7 * REM * SCALE,
    },

    '': {},

    unknown: {
      color: 'red',
      backgroundColor: 'yellow',
      borderWidth: 1,
      borderStyle: 'dashed',
      borderColor: 'red',
    },
  });

  stylesheetMap[lang] = stylesheet;
  return stylesheet;
};

const cls = (classNames: string, inlineStyle?: Style): Style[] => {
  const lang = i18n.language as Language;

  const ret: Style[] = classNames.split(' ').map((className) => {
    const stylesheet = getStyleSheet(lang);
    const style = stylesheet[className];

    if (style) {
      return style;
    } else {
      const err = new Error(`cls: unknown className: ${className}`);
      console.error(err);
      Sentry.captureException(err);
      return stylesheet.unknown;
    }
  });

  if (inlineStyle) {
    ret.push(inlineStyle);
  }

  return ret;
};

export { cls, REM, SCALE };
