import { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { GalenDataUser } from 'src/models/GalenData/User.model';

import {
  emailPattern,
  namePattern,
  validatePassword,
} from 'src/utils/validationRules';

export type subUserFormDataTypes = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  confirmPassword: string;
};

interface IUserFormModalProps {
  show: boolean;
  handleClose: () => void;
  handleSave: (user: subUserFormDataTypes) => void;
  user?: GalenDataUser | null;
  isSavingUser?: boolean;
}

const UserFormModal = ({
  show,
  handleClose,
  handleSave,
  user,
  isSavingUser,
}: IUserFormModalProps) => {
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      confirmPassword: '',
    },
    criteriaMode: 'all',
  });

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress ?? '',
      });
    } else {
      reset({
        firstName: '',
        lastName: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
      });
    }
  }, [show, user, reset]);

  const handleCloseAndReset = () => {
    handleClose();
    reset();
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseAndReset}
      backdrop="static"
      animation={false}
      keyboard={false}
    >
      <Form onSubmit={handleSubmit(handleSave)}>
        <Modal.Header closeButton>
          <Modal.Title>{user ? 'Edit' : 'Add'} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>

            <Form.Control
              type="text"
              placeholder="Type First Name"
              {...register('firstName', {
                required: 'First name is required.',
                maxLength: {
                  value: 100,
                  message: 'First name must be 100 characters or less.',
                },
                pattern: {
                  value: namePattern,
                  message: 'First name contains invalid characters.',
                },
              })}
            />
            {errors.firstName?.message && (
              <p className="text-danger">{errors.firstName?.message}</p>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Type Last Name"
              {...register('lastName', {
                required: 'Last name is required.',
                maxLength: {
                  value: 100,
                  message: 'Last name must be 100 characters or less.',
                },
                pattern: {
                  value: namePattern,
                  message: 'Last name contains invalid characters.',
                },
              })}
            />
            {errors.lastName?.message && (
              <p className="text-danger ">{errors.lastName?.message}</p>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Type email address"
              autoComplete="off"
              {...register('emailAddress', {
                required: 'Your email address is required.',
                pattern: {
                  value: emailPattern,
                  message: 'Please enter a valid email address.',
                },
                maxLength: {
                  value: 254,
                  message: 'Email address must be less than 254 characters.',
                },
              })}
            ></Form.Control>
            {errors.emailAddress?.message && (
              <p className="text-danger ">{errors.emailAddress?.message}</p>
            )}
          </Form.Group>

          {!user && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Type Password"
                  {...register('password', {
                    required: 'New password is required.',
                    validate: validatePassword,
                  })}
                />
                <div className="text-danger">
                  {errors.password?.type === 'required' ? (
                    <p>Password required</p>
                  ) : (
                    <ul className="list-unstyled">
                      {errors.password?.types?.minLength && (
                        <li>{errors.password.message}</li>
                      )}
                      {errors.password?.types?.hasUpperCase && (
                        <li>{errors.password.types.hasUpperCase}</li>
                      )}
                      {errors.password?.types?.hasNumber && (
                        <li>{errors.password.types.hasNumber}</li>
                      )}
                      {errors.password?.types?.hasSpecialChar && (
                        <li>{errors.password.types.hasSpecialChar}</li>
                      )}
                    </ul>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  {...register('confirmPassword', {
                    required: 'Confirm Password is required.',
                    validate: (val: string) => {
                      if (watch('password') !== val) {
                        return 'Password and Confirm Password do not match.';
                      }
                    },
                  })}
                />
                {
                  <p className="text-danger ">
                    {errors.confirmPassword?.message}
                  </p>
                }
              </Form.Group>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light text-optain" onClick={handleCloseAndReset}>
            Cancel
          </Button>
          <Button
            variant="optain"
            type="submit"
            disabled={isSavingUser || (!isDirty && !!user)}
          >
            {isSavingUser ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserFormModal;
