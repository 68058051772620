import { Text, View } from '@react-pdf/renderer';

import { handleNSI18Next } from 'src/models/Language.model';

import { cls, SCALE } from 'src/utils/reactPdfStyles';

const FooterSection = () => {
  const { handleReportT } = handleNSI18Next();
  return (
    <View style={cls('mt-4')}>
      <View style={cls('border-top')}>
        <Text style={cls('mt-1 small', { marginBottom: 4 * SCALE })}>
          {handleReportT('footerSection.disclaimerText')}
        </Text>
        <Text style={cls('mt-1 small')}>
          ©️ Eyetelligence {new Date().getFullYear()}
        </Text>
      </View>
    </View>
  );
};

export default FooterSection;
