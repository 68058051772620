import { Text, View } from '@react-pdf/renderer';

import { CalculateCvdValue, CvdKey } from 'src/models/CvdLevel.model';
import { Disease, DiseaseFullName } from 'src/models/Disease.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface ICvdSectionProps {
  session: Session;
  disease: Disease; // WHOCVD_RISK or AUSCVD_RISK
  type: ReportType;
  showDiseaseName: boolean;
  showHealthStatus: boolean;
  showNotes: boolean;
}

const CvdSection = ({
  session,
  disease,
  type,
  showDiseaseName,
  showHealthStatus,
  showNotes,
}: ICvdSectionProps) => {
  // cvd risk and rank
  const risk = CalculateCvdValue(session, CvdKey.RISK, disease);
  const rank = CalculateCvdValue(session, CvdKey.RANK, disease);

  const { handleCommonT, handleReportT } = handleNSI18Next();

  // circle background color
  const vascularCircleBg =
    disease === Disease.AUSCVD_RISK ? 'bg-vascular-alt' : 'bg-vascular';

  // circle size
  const vascularCircleClass = showDiseaseName
    ? `vascular-circle-small ${vascularCircleBg}`
    : 'vascular-circle';

  return (
    <>
      <View style={cls('d-flex hstack justify-content-between')}>
        {showDiseaseName && (
          <View style={cls('td px-0 border-0 w-20')}>
            <Text style={cls('text-center align-middle strong pt-4')}>
              {handleReportT(`classificationSection.${disease}.caption`)}
            </Text>
          </View>
        )}

        <View style={cls('td px-0 border-0 w-50')}>
          <Text style={cls(vascularCircleClass)}>
            {risk === undefined ? (
              handleCommonT('exception.NA')
            ) : (
              <>
                {risk}
                <Text style={cls('small')}>%</Text>
              </>
            )}
          </Text>
        </View>

        <View style={cls('td px-3 py-0 border-0')}></View>

        <View style={cls('td px-0 border-0 w-50')}>
          {showHealthStatus && (
            <Text style={cls(vascularCircleClass)}>
              {rank === undefined ? (
                handleCommonT('exception.NA')
              ) : (
                <>
                  {rank}
                  <Text style={cls('small')}>%</Text>
                </>
              )}
            </Text>
          )}
        </View>
      </View>

      <View style={cls('d-flex hstack justify-content-between')}>
        {showDiseaseName && <View style={cls('td px-0 border-0 w-20')}></View>}

        <View style={cls('td px-0 border-0 w-50 text-center')}>
          <Text style={cls('strong')}>
            {handleCommonT(
              `diseaseFullName.${DiseaseFullName(Disease.WHOCVD_RISK)}`,
            )}
          </Text>
        </View>

        <View style={cls('td px-3 py-0 border-0')}></View>

        <View style={cls('td px-0 border-0 w-50 text-center')}>
          {showHealthStatus && (
            <Text style={cls('strong')}>
              {handleCommonT(
                `diseaseFullName.${DiseaseFullName(Disease.WHOCVD_RANK)}`,
              )}
            </Text>
          )}
        </View>
      </View>

      {showNotes && (
        <View style={cls('report-text mt-4')}>
          <View style={cls('p-4 pb-2')}>
            {type === ReportType.CLINICAL ? (
              <Text style={cls('p')}>
                {handleReportT('cvdSection.referToTheEndText')}
              </Text>
            ) : (
              <>
                <Text style={cls('p')}>
                  {handleReportT('resultSection.patient.cvdContactText')}
                </Text>
                <Text style={cls('p')}>
                  {handleReportT(
                    'resultSection.patient.artifactWarningTextAlt',
                  )}
                </Text>
              </>
            )}
          </View>
        </View>
      )}
    </>
  );
};

export default CvdSection;
