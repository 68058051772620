import { Image, Text, View } from '@react-pdf/renderer';

import { Disease, DiseaseName } from 'src/models/Disease.model';
import { Session } from 'src/models/Session.model';

import getGradingResult from 'src/utils/getGradingResult';
import { cls } from 'src/utils/reactPdfStyles';

import {
  InsufficientResultIconBadge,
  NegativeResultIconBadge,
  PositiveResultIconBadge,
  SingleEyeInsufficientResultBadge,
  SingleEyeNegativeResultBadge,
  SingleEyePositiveResultBadge,
} from './SingleEyeResultBadge';

interface ImageAnalysisProps {
  session: Session;
}

const ImageAnalysis = ({ session }: ImageAnalysisProps) => {
  const {
    getLeftPositiveResultDiseases,
    getRightPositiveResultDiseases,
    isLeftPositive,
    isRightPositive,
    isLeftEyeNegative,
    isRightEyeNegative,
    isLeftEyeGradable,
    isRightEyeGradable,
  } = getGradingResult(session);

  const positiveLeftResultDiseases = getLeftPositiveResultDiseases([
    Disease.MTMDR,
    Disease.VTDR,
  ]);

  const positiveRightResultDiseases = getRightPositiveResultDiseases([
    Disease.MTMDR,
    Disease.VTDR,
  ]);

  const hasLeftPositiveResult = positiveLeftResultDiseases.length > 0;

  const hasRightPositiveResult = positiveRightResultDiseases.length > 0;

  const isSessionWithoutLeftImage =
    session.username === undefined || session.leftImageFile === undefined;

  const isSessionWithoutRightImage =
    session.username === undefined || session.rightImageFile === undefined;

  return (
    <View>
      <Text style={cls('h4 text-optain')}>Image Analysis</Text>

      <View style={cls('d-flex px-4 py-3 bg-black rounded')}>
        <View
          style={cls(
            'position-relative d-flex justify-content-center align-items-center',
            { flex: '1 1' },
          )}
        >
          <View
            style={cls('d-flex justify-content-center align-items-center', {
              width: '200px',
              height: '200px',
            })}
          >
            {isSessionWithoutRightImage ? (
              <Text style={cls('p lead text-gray text-center')}>No Image</Text>
            ) : (
              <Image src={session?.rightImageFile} style={cls('img-fluid')} />
            )}
          </View>
          <Text
            style={cls('p text-white mb-0 position-absolute bottom-0 start-0')}
          >
            Right Eye
          </Text>
        </View>

        <View
          style={cls(
            'position-relative d-flex justify-content-center align-items-center',
            {
              flex: '1 1',
            },
          )}
        >
          <View
            style={cls('d-flex justify-content-center align-items-center', {
              width: '200px',
              height: '200px',
            })}
          >
            {isSessionWithoutLeftImage ? (
              <Text style={cls('p lead text-gray text-center')}>No Image</Text>
            ) : (
              <Image src={session?.leftImageFile} style={cls('img-fluid')} />
            )}
          </View>
          <Text
            style={cls('p text-white mb-0 position-absolute bottom-0 start-0')}
          >
            Left Eye
          </Text>
        </View>
      </View>

      <View style={cls('d-flex border border-1 rounded')}>
        <View
          style={cls('px-4 py-3 border-end vstack gap-3', {
            flex: '1 1',
          })}
        >
          <View style={cls('vstack gap-3 d-flex align-items-center')}>
            <Text style={cls('h6 mb-0')}>Results (Right Eye)</Text>
            <View style={cls('hstack gap-2')}>
              {hasRightPositiveResult ? (
                positiveRightResultDiseases.map((disease) => (
                  <SingleEyePositiveResultBadge
                    children={<Text>{DiseaseName(disease)}</Text>}
                    key={disease}
                  />
                ))
              ) : isRightEyeGradable ? (
                <SingleEyeNegativeResultBadge />
              ) : (
                <SingleEyeInsufficientResultBadge />
              )}
            </View>
          </View>

          {isRightEyeGradable ? (
            <View>
              {hasRightPositiveResult && (
                <View style={cls('hstack gap-3')}>
                  <PositiveResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    More than mild diabetic retinopathy (mtmDR) detected.
                  </Text>
                </View>
              )}

              {isRightEyeNegative(Disease.MTMDR) && (
                <View style={cls('hstack gap-3')}>
                  <NegativeResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    More than mild diabetic retinopathy (mtmDR) not detected.
                  </Text>
                </View>
              )}

              {isRightPositive(Disease.VTDR) && (
                <View style={cls('hstack gap-3')}>
                  <PositiveResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    Vision threatening diabetic retinopathy (vtDR) detected.
                  </Text>
                </View>
              )}

              {isRightEyeNegative(Disease.VTDR) && (
                <View style={cls('hstack gap-3')}>
                  <NegativeResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    Vision threatening diabetic retinopathy (vtDR) not detected.
                  </Text>
                </View>
              )}
            </View>
          ) : (
            <View style={cls('hstack gap-3')}>
              <InsufficientResultIconBadge />
              <Text style={cls('p mb-0')}> Insufficient exam.</Text>
            </View>
          )}
        </View>

        <View style={cls('px-4 py-3 vstack gap-3', { flex: '1 1' })}>
          <View style={cls('vstack gap-3 d-flex align-items-center')}>
            <Text style={cls('h6 mb-0')}>Results (Left Eye)</Text>
            <View style={cls('hstack gap-2')}>
              {hasLeftPositiveResult ? (
                positiveLeftResultDiseases.map((disease) => (
                  <SingleEyePositiveResultBadge
                    children={<Text>{DiseaseName(disease)}</Text>}
                    key={disease}
                  />
                ))
              ) : isLeftEyeGradable ? (
                <SingleEyeNegativeResultBadge />
              ) : (
                <SingleEyeInsufficientResultBadge />
              )}
            </View>
          </View>

          {isLeftEyeGradable ? (
            <View>
              {hasLeftPositiveResult && (
                <View style={cls('hstack gap-3')}>
                  <PositiveResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    More than mild diabetic retinopathy (mtmDR) detected.
                  </Text>
                </View>
              )}

              {isLeftEyeNegative(Disease.MTMDR) && (
                <View style={cls('hstack gap-3')}>
                  <NegativeResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    More than mild diabetic retinopathy (mtmDR) not detected.
                  </Text>
                </View>
              )}

              {isLeftPositive(Disease.VTDR) && (
                <View style={cls('hstack gap-3')}>
                  <PositiveResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    Vision threatening diabetic retinopathy (vtDR) detected.
                  </Text>
                </View>
              )}

              {isLeftEyeNegative(Disease.VTDR) && (
                <View style={cls('hstack gap-3')}>
                  <NegativeResultIconBadge />
                  <Text style={cls('p mb-0')}>
                    Vision threatening diabetic retinopathy (vtDR) not detected.
                  </Text>
                </View>
              )}
            </View>
          ) : (
            <View style={cls('hstack gap-3')}>
              <InsufficientResultIconBadge />
              <Text style={cls('p mb-0')}>Insufficient exam.</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default ImageAnalysis;
