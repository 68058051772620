import { StateCreator } from 'zustand';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { CameraData } from 'src/models/CameraData.model';

interface StateTypes {
  isCapturing: boolean;
  isCanceling: boolean;
  leftUrl: string;
  rightUrl: string;
  cameraData: CameraData | undefined;
  leftFileType: string;
  rightFileType: string;
}
type ActionsTypes = {
  handleSetIsCapturing: (isCapturing: boolean) => void;
  handleSetIsCanceling: (isCanceling: boolean) => void;
  handleSetLeftUrl: (leftUrl: string) => void;
  handleSetRightUrl: (rightUrl: string) => void;
  handleStartCaptureBoth: () => void;
  handleStartCaptureLeft: () => void;
  handleStartCaptureRight: () => void;
  setCameraData: (cameraData: CameraData | undefined) => void;
};

export type CameraSliceTypes = StateTypes & ActionsTypes;

export const createCameraSlice: StateCreator<
  CameraSliceTypes,
  [],
  [],
  CameraSliceTypes
> = (set) => ({
  isCapturing: false,
  isCanceling: false,
  leftUrl: '',
  rightUrl: '',
  cameraData: undefined,
  leftFileType: '',
  rightFileType: '',
  handleSetIsCapturing: (isCapturing: boolean) => set({ isCapturing }),
  handleSetIsCanceling: (isCanceling: boolean) => set({ isCanceling }),
  handleSetLeftUrl: (leftUrl: string) => set({ leftUrl }),
  handleSetRightUrl: (rightUrl: string) => set({ rightUrl }),
  handleStartCaptureBoth: () => {
    set({ leftUrl: '', rightUrl: '' });
    let completed = 0;
    set({ isCapturing: true });

    CameraApi.captureBothEyes(
      (err, url, leftFileType) => {
        if (err === undefined) {
          set({ leftUrl: url, leftFileType });
          completed++;
          if (completed === 2) {
            set({ isCapturing: false });
          }
          return;
        }
        set({ isCapturing: false });
      },
      (err, url, rightFileType) => {
        if (err === undefined) {
          set({ rightUrl: url, rightFileType });
          completed++;
          if (completed === 2) {
            set({ isCapturing: false });
          }
          return;
        }
        set({ isCapturing: false });
      },
    );
  },
  handleStartCaptureLeft: () => {
    set({ leftUrl: '', isCapturing: true });

    CameraApi.captureLeftEye((err, url) => {
      if (err === undefined) {
        set({ leftUrl: url, isCapturing: false });
        return;
      }
      set({ isCapturing: false });
    });
  },
  handleStartCaptureRight: () => {
    set({ rightUrl: '', isCapturing: true });

    CameraApi.captureRightEye((err, url) => {
      if (err === undefined) {
        set({ rightUrl: url, isCapturing: false });
        return;
      }
      set({ isCapturing: false });
    });
  },
  setCameraData: (cameraData: CameraData | undefined) => set({ cameraData }),
});
