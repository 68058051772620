import { Image, Text, View } from '@react-pdf/renderer';

import { handleNSI18Next } from 'src/models/Language.model';
import { SegmentationFile } from 'src/models/Segmentation.model';
import { SegmentationType } from 'src/models/SegmentationType.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface SegmentationImageSectionProps {
  session: Session;
}

const SegmentationImageSection = ({
  session,
}: SegmentationImageSectionProps) => {
  const { handleReportT } = handleNSI18Next();

  return (
    <View style={cls('d-flex hstack justify-content-between')}>
      <View
        style={cls('td p-0 border-0 w-100 report-text rounded', {
          height: 277,
        })}
      >
        {SegmentationFile(
          session.rightSegmentations || [],
          SegmentationType.GRID,
        ) ? (
          <Image
            src={SegmentationFile(
              session.rightSegmentations || [],
              SegmentationType.GRID,
            )}
            style={cls('rounded')}
          />
        ) : (
          <Text
            style={cls('p lead text-black text-center align-middle rounded')}
          >
            {handleReportT(`imageSection.noImg`)}
          </Text>
        )}
      </View>

      <View style={cls('px-3 py-0 border-0')}></View>

      <View
        style={cls('td p-0 border-0 w-100 report-text rounded', {
          height: 277,
        })}
      >
        {SegmentationFile(
          session.leftSegmentations || [],
          SegmentationType.GRID,
        ) ? (
          <Image
            src={SegmentationFile(
              session.leftSegmentations || [],
              SegmentationType.GRID,
            )}
            style={cls('rounded')}
          />
        ) : (
          <Text
            style={cls('p lead text-black text-center align-middle rounded')}
          >
            {handleReportT(`imageSection.noImg`)}
          </Text>
        )}
      </View>
    </View>
  );
};

export default SegmentationImageSection;
