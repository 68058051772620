import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { faCamera, faSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type WarnNoConnectedCameraModalProps = {
  isVisible: boolean;
};
export default function WarnNoConnectedCameraModal({
  isVisible,
}: WarnNoConnectedCameraModalProps) {
  const history = useHistory();

  const gotoSettings = () => {
    history.push('/settings');
  };

  return (
    <Modal show={isVisible} size="lg">
      <Modal.Body className="m-auto">
        <div className="p-5 ps-0 text-center">
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCamera} size="4x" className="ms-2" />
            <FontAwesomeIcon icon={faSlash} size="4x" className="text-muted" />
          </span>
        </div>

        <p className="text-center lead">There is no connected camera.</p>

        <p className="text-center">
          Please connect to your fundus camera in Settings.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="optain" onClick={gotoSettings}>
          Go to Settings
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
