import React from 'react';
import { Button, Card, Col, Modal, Row, Stack } from 'react-bootstrap';

import { Session } from 'src/models/Session.model';

export enum DilationEnum {
  NotDilated = 'Not Dilated',
  Dilated = 'Dilated',
}

type PatientInfoConfirmationModalProps = {
  formValues: Partial<Session>;
  isShowConfirmModal: boolean;
  handleConfirmPatientInfo: (data: any) => void;
  handleCloseConfirmModal: () => void;
  InfoConfirmationKeyMap: { [key in keyof Partial<Session>]?: string };
};

export default function PatientInfoConfirmationModal({
  formValues,
  isShowConfirmModal,
  handleConfirmPatientInfo,
  handleCloseConfirmModal,
  InfoConfirmationKeyMap,
}: PatientInfoConfirmationModalProps) {
  return (
    <Modal
      show={isShowConfirmModal}
      onHide={handleCloseConfirmModal}
      backdrop="static"
      animation={false}
      scrollable={true}
      fullscreen="xl-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Patient Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Stack gap={2}>
              {(
                ['dob', 'lastName', 'firstName', 'id'] as Array<
                  keyof Omit<
                    Session,
                    'leftEyeDilatedTime' | 'rightEyeDilatedTime' | 'template'
                  >
                >
              ).map((objKey) => (
                <Row key={objKey}>
                  <Col>
                    <span className="fw-medium">
                      {InfoConfirmationKeyMap[objKey]}
                    </span>
                  </Col>
                  <Col>
                    <span>{formValues[objKey]?.toString()}</span>
                  </Col>
                </Row>
              ))}
            </Stack>
          </Card.Body>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="light text-optain"
          className="shadow"
          onClick={handleCloseConfirmModal}
        >
          Cancel
        </Button>

        <Button
          variant="optain"
          className="shadow"
          onClick={handleConfirmPatientInfo}
        >
          Confirm Patient
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
