export interface EntityCriteria<T> {
  /**
   * The key for the criteria. Either name of the standard attributes or custom field identifier
   */
  key: keyof T;
  /**
   * The operator for the comparison.
   */
  operator: EntityCriteriaOperatorEnum;
  /**
   * The value (for single valued comparison objects).
   */
  value?: any;
  /**
   * Whether the key represents a custom field identifier.
   */
  isCustomField?: boolean;
}

export enum EntityCriteriaOperatorEnum {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  Like = 'Like',
}
