import { Document, Page, Text, View } from '@react-pdf/renderer';

import Watermark from 'src/components/custom_components/CustomWatermark';
import NotesOrNoticeSection from 'src/components/NotesOrNoticeSection';

import { Disease } from 'src/models/Disease.model';
import { HasPositiveHeatmap } from 'src/models/Grading.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import { cls } from 'src/utils/reactPdfStyles';

import ClassificationTableSection from './ClassificationTableSection';
import CvdSection from './CvdSection';
import FooterSection from './FooterSection';
import HeaderSection from './HeaderSection';
import HeatmapSection from './HeatmapSection';
import ImageSection from './ImageSection';
import InfoSection from './InfoSection';
import ResultSection from './ResultSection';
import SegmentationSection from './SegmentationSection/SegmentationSection';
import SummarySection from './SummarySection';

interface IAssurePlusReportProps {
  session: Session;
  settings: Settings;
  type: ReportType;
  logo: string;
}

const AssurePlusReport = ({
  session,
  settings,
  type,
  logo,
}: IAssurePlusReportProps) => {
  const { handleCommonT, handleReportT } = handleNSI18Next();

  return (
    <Document>
      <Page size="A4" style={cls('body')}>
        <View style={cls('p-4')}>
          <HeaderSection
            session={session}
            type={type}
            settings={settings}
            title={handleReportT(`title.${type}`)}
            logo={logo}
          ></HeaderSection>

          <InfoSection session={session} settings={settings}></InfoSection>

          <ImageSection type={type} session={session}></ImageSection>

          <ResultSection
            type={type}
            session={session}
            showNotes={true}
          ></ResultSection>

          <Text style={cls('p mb-0')}>
            <Text style={cls('small b')}>
              {handleReportT(`footerSection.title`)}
            </Text>
          </Text>

          <FooterSection></FooterSection>
        </View>
        <Watermark text={settings.watermark} />
      </Page>

      {type === ReportType.CLINICAL &&
        settings.reportHeatmap &&
        HasPositiveHeatmap(session) && (
          <Page size="A4" style={cls('body')}>
            <View style={cls('p-4')}>
              <HeaderSection
                session={session}
                type={type}
                settings={settings}
                title={handleReportT(`title.${type}`)}
                logo={logo}
              ></HeaderSection>

              <HeatmapSection session={session}></HeatmapSection>

              <Text style={cls('p pt-4 mb-0')}>
                <Text style={cls('small b')}>
                  {handleReportT(`footerSection.title`)}
                </Text>
              </Text>

              <FooterSection></FooterSection>
            </View>
            <Watermark text={settings.watermark} />
          </Page>
        )}

      {type === ReportType.PATIENT && (
        <Page size="A4" style={cls('body')}>
          <View style={cls('p-4')}>
            <HeaderSection
              session={session}
              type={type}
              settings={settings}
              title=""
              logo={logo}
            ></HeaderSection>

            <SummarySection session={session} />

            <NotesOrNoticeSection session={session} />

            <Text style={cls('p section-text lead mt-5 pt-5')}>
              {handleCommonT('contact.visitWebsite')}
            </Text>

            <Text style={cls('p pt-4 mb-0')}>
              <Text style={cls('small b')}>
                {handleReportT(`footerSection.title`)}
              </Text>
            </Text>

            <FooterSection />
          </View>
          <Watermark text={settings.watermark} />
        </Page>
      )}

      <Page size="A4" style={cls('body')}>
        <View style={cls('p-4')}>
          <HeaderSection
            session={session}
            type={type}
            settings={settings}
            title={handleReportT('title.cvd') || handleReportT(`title.${type}`)}
            logo={logo}
          ></HeaderSection>

          <InfoSection session={session} settings={settings}></InfoSection>

          <SegmentationSection
            session={session}
            type={type}
          ></SegmentationSection>

          {session.template?.includes('auscvd') ? (
            <>
              <CvdSection
                session={session}
                disease={Disease.WHOCVD_RISK}
                type={type}
                showDiseaseName={true}
                showHealthStatus={true}
                showNotes={false}
              ></CvdSection>

              <CvdSection
                session={session}
                disease={Disease.AUSCVD_RISK}
                type={type}
                showDiseaseName={true}
                showHealthStatus={false}
                showNotes={true}
              ></CvdSection>
            </>
          ) : (
            <CvdSection
              session={session}
              disease={Disease.WHOCVD_RISK}
              type={type}
              showDiseaseName={false}
              showHealthStatus={true}
              showNotes={true}
            ></CvdSection>
          )}

          <FooterSection></FooterSection>
        </View>
        <Watermark text={settings.watermark} />
      </Page>

      <Page size="A4" style={cls('body')}>
        <View style={cls('p-4')}>
          <HeaderSection
            session={session}
            type={type}
            settings={settings}
            title={handleReportT('title.cvd') || handleReportT(`title.${type}`)}
            logo={logo}
          ></HeaderSection>

          <ClassificationTableSection session={session} />

          <FooterSection></FooterSection>
        </View>
        <Watermark text={settings.watermark} />
      </Page>
    </Document>
  );
};

export default AssurePlusReport;
