import React, { useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';
import { validatePassword } from 'src/utils/validationRules';

import CustomToast from './CustomToast';

export type ChangePwdModalProps = {
  isShowChangePwdModal: boolean;
  handleCloseChangePwdModal: () => void;
  userName: string;
  canBeCanceled?: boolean;
};

export default function ChangePwdModal({
  isShowChangePwdModal,
  handleCloseChangePwdModal,
  canBeCanceled,
}: ChangePwdModalProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    criteriaMode: 'all',
  });

  const [isShowToast, setIsShowToast] = useState(false);
  const [toastText, setToastText] = useState('');

  const handleShowToast = (text: string) => {
    setToastText(text);
    setIsShowToast(true);
  };

  const { userId } = useBoundStore();

  const closeModalAndReset = () => {
    handleCloseChangePwdModal();
    reset();
  };

  const onSubmit: SubmitHandler<{
    currentPassword: string;
    password: string;
    confirmPassword: string;
  }> = async (data) => {
    const { currentPassword, password } = data;

    try {
      await Api.remote.change_password(userId, currentPassword, password);

      closeModalAndReset();
      handleShowToast('Password changed.');
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      handleShowToast(errorMessage);
    }
  };

  return (
    <>
      <Modal
        show={isShowChangePwdModal}
        backdrop="static"
        animation={false}
        keyboard={canBeCanceled}
        onHide={closeModalAndReset}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton={canBeCanceled}>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!canBeCanceled && (
              <Alert variant="danger">
                Password too weak. You must use a stronger password to continue.
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                {...register('currentPassword', {
                  required: 'Current password is required.',
                })}
              />
              {errors.currentPassword?.message && (
                <p className="text-danger">{errors.currentPassword?.message}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Type Password"
                {...register('password', {
                  required: 'New password is required.',
                  validate: validatePassword,
                })}
              />
              <div className="text-danger">
                {errors.password?.type === 'required' ? (
                  <p>Password required</p>
                ) : (
                  <ul className="list-unstyled">
                    {errors.password?.types?.minLength && (
                      <li>{errors.password.message}</li>
                    )}
                    {errors.password?.types?.hasUpperCase && (
                      <li>{errors.password.types.hasUpperCase}</li>
                    )}
                    {errors.password?.types?.hasNumber && (
                      <li>{errors.password.types.hasNumber}</li>
                    )}
                    {errors.password?.types?.hasSpecialChar && (
                      <li>{errors.password.types.hasSpecialChar}</li>
                    )}
                  </ul>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                {...register('confirmPassword', {
                  required: 'Confirm Password is required.',
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Password and Confirm Password do not match.';
                    }
                  },
                })}
              />
              {
                <p className="text-danger ">
                  {errors.confirmPassword?.message}
                </p>
              }
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            {canBeCanceled && (
              <Button variant="outline-primary" onClick={closeModalAndReset}>
                Cancel
              </Button>
            )}
            <Button variant="primary" type="submit">
              Change
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <CustomToast
        children={toastText}
        isShow={isShowToast}
        handleToggleShowToast={(isShow) => setIsShowToast(isShow)}
      />
    </>
  );
}
