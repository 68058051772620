import { Container } from 'react-bootstrap';

const ErrorBoundaryPage = () => {
  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h1>Whoops, something went wrong.</h1>

        <p>Please try restarting the application.</p>

        <p>
          If the problem persists, please{' '}
          <a href="mailto:service@optainhealth.com">get in touch</a>.
        </p>
      </div>
    </Container>
  );
};

export default ErrorBoundaryPage;
