import { Api } from 'src/models/Api.model';
import { CameraData } from 'src/models/CameraData.model';
import { ModelEnum } from 'src/models/Model.model';
import { Product } from 'src/models/Product.model';
import { Session } from 'src/models/Session.model';

import { CustomDeviceDataModel } from '../CustomDeviceDataModel.model';
import {
  setUploadStatus,
  updateRetinalExamData,
  uploadImages,
} from './submitSession';

const submitRetinalRetakeSession = async ({
  practiceId,
  practiceUserId,
  session,
  models,
  sessionId,
  cameraData,
}: {
  practiceId: string;
  practiceUserId: string;
  session: Session;
  models: ModelEnum[];
  sessionId: string;
  cameraData?: CameraData;
}) => {
  const deviceDataModelId = process.env.REACT_APP_DEVICE_MODEL_ID;
  const devicePropertySetId =
    process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION;

  const previousExamId = session.iid;

  // step 1: reuse patient and set hidden for the previous exam
  const previousExam = await Api.remote._get_device_data<CustomDeviceDataModel>(
    {
      deviceDataModelId,
      deviceDataId: previousExamId,
    },
  );

  const patientUserId = previousExam.data.ownerId;

  await Api.remote._save_device_data_record<{ IsHidden: boolean }>({
    deviceDataModelId,
    devicePropertySetId,
    deviceDataId: previousExamId,
    data: {
      IsHidden: true,
    },
  });

  const examSubmissionData = {
    SessionId: sessionId,
    PracticeUserId: practiceUserId,
    PracticeId: practiceId,
    ExamSubmissionMetadata: {
      models,
    },
    ExamSubmissionDatetime: new Date().toISOString(),
    ReportDatetime: new Date().toISOString(),
    IsHidden: false,
    RightEyeDilatedTime: previousExam.data.data?.RightEyeDilatedTime?.value,
    LeftEyeDilatedTime: previousExam.data.data?.LeftEyeDilatedTime?.value,
    OrderId: previousExam.data.data?.OrderId?.value,
    CameraSn: cameraData?.sn,
    CameraVersion: cameraData?.version,

    Gender: session.gender,
  };

  // step 2: submit device data and get id
  const deviceDataId = await updateRetinalExamData({
    deviceDataModelId,
    devicePropertySetId,
    examSubmissionData,
    patientUserId,
  });

  // step 3: upload images
  await uploadImages({
    deviceDataModelId,
    deviceDataId,
    session,
  });

  // step 4: set upload status
  return await setUploadStatus({
    deviceDataModelId,
    devicePropertySetId,
    deviceDataId,
    patientUserId,
  });
};

const submitTeleophthRetakeSession = async ({
  practiceId,
  practiceUserId,
  session,
  models,
  sessionId,
  cameraData,
}: {
  practiceId: string;
  practiceUserId: string;
  session: Session;
  models: ModelEnum[];
  sessionId: string;
  cameraData?: CameraData;
}) => {
  const deviceDataModelId =
    process.env.REACT_APP_MODEL_ID_RETINAL_EXAM_TELEOPHTH;
  const devicePropertySetId =
    process.env
      .REACT_APP_PROPERTY_SET_ID_EXAM_SUBMISSION_RETINAL_EXAM_TELEOPHTH;

  const previousExamId = session.iid;

  // step 1: reuse patient and set hidden for the previous exam
  const previousExam = await Api.remote._get_device_data<CustomDeviceDataModel>(
    {
      deviceDataModelId,
      deviceDataId: previousExamId,
    },
  );

  const patientUserId = previousExam.data.ownerId;

  await Api.remote._save_device_data_record<{ IsHidden: boolean }>({
    deviceDataModelId,
    devicePropertySetId,
    deviceDataId: previousExamId,
    data: {
      IsHidden: true,
    },
  });

  const examSubmissionData = {
    SessionId: sessionId,
    PracticeUserId: practiceUserId,
    PracticeId: practiceId,
    ExamSubmissionMetadata: {
      models,
    },
    ExamSubmissionDatetime: new Date().toISOString(),
    ReportDatetime: new Date().toISOString(),
    IsHidden: false,
    RightEyeDilatedTime: previousExam.data.data?.RightEyeDilatedTime?.value,
    LeftEyeDilatedTime: previousExam.data.data?.LeftEyeDilatedTime?.value,
    OrderId: previousExam.data.data?.OrderId?.value,
    DiabetesType: previousExam.data.data?.DiabetesType?.value,
    DurationOfDiabetes: previousExam.data.data?.DurationOfDiabetes?.value,
    LastEyeExam: previousExam.data.data?.LastEyeExam?.value,
    CameraSn: cameraData?.sn,
    CameraVersion: cameraData?.version,
  };

  // step 2: submit device data and get id
  const deviceDataId = await updateRetinalExamData({
    deviceDataModelId,
    devicePropertySetId,
    examSubmissionData,
    patientUserId,
  });

  // step 3: upload images
  await uploadImages({
    deviceDataModelId,
    deviceDataId,
    session,
  });

  // step 4: set upload status
  return await setUploadStatus({
    deviceDataModelId,
    devicePropertySetId,
    deviceDataId,
    patientUserId,
  });
};

const retakeSession = async (
  practiceId: string,
  practiceUserId: string,
  session: Session,
  models: ModelEnum[],
  sessionId: string,
  product: Product,
  cameraData?: CameraData,
): Promise<string> => {
  if (product === Product.TELEOPHTH) {
    return await submitTeleophthRetakeSession({
      session,
      practiceId,
      practiceUserId,
      models,
      sessionId,
      cameraData,
    });
  }

  return await submitRetinalRetakeSession({
    session,
    practiceId,
    practiceUserId,
    models,
    sessionId,
    cameraData,
  });
};

export default retakeSession;
