enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  UNSPECIFIED = 'unspecified',
}

const GenderName = (gender: Gender): string => {
  switch (gender) {
    case Gender.MALE: {
      return 'Male';
    }
    case Gender.FEMALE: {
      return 'Female';
    }
    case Gender.UNSPECIFIED: {
      return 'Unspecified';
    }
    default: {
      return `Unknown: ${gender}`;
    }
  }
};

export { Gender, GenderName };
