import { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import { Button, Card, Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import useBoundStore from 'src/store/useBoundStore';

import { Camera } from 'src/models/Camera.model';
import { PatientInfoKeyMapOptain, Session } from 'src/models/Session.model';

import { DilationEnum } from './ExtraPatientInfoConfirmationModal/ExtraPatientInfoConfirmationModal';

interface ConfirmPatientInformationModalProps {
  currentSession: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  isShowConfirmModal: boolean;
  handleSetIsShowConfirmModal: (isShowConfirmModal: boolean) => void;
}

const ConfirmPatientInformationModal: FunctionComponent<
  ConfirmPatientInformationModalProps
> = ({
  currentSession,
  setSession,
  isShowConfirmModal,
  handleSetIsShowConfirmModal,
}) => {
  const history = useHistory();

  const patientInfoConfirmation = useMemo(() => {
    const { id, firstName, lastName, dob } = currentSession;
    return { id, firstName, lastName, dob };
  }, [currentSession]);

  const handlePushToSessionPreviewPage = () => {
    history.push({
      pathname: '/session-preview',
      state: { isResume: true },
    });
  };

  const handlePushToSessionUploadPage = () => {
    history.push({
      pathname: '/session-upload',
      state: { isResume: true },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      leftEyeDilated: DilationEnum.NotDilated,
      rightEyeDilated: DilationEnum.NotDilated,
    },
  });

  const { camera } = useBoundStore();

  const onSubmit: SubmitHandler<{
    leftEyeDilated: DilationEnum;
    rightEyeDilated: DilationEnum;
  }> = (data) => {
    if (isDirty) {
      const updatedSession = {
        ...currentSession,
        leftEyeDilatedTime: data.leftEyeDilated ? new Date() : undefined,
        rightEyeDilatedTime: data.rightEyeDilated ? new Date() : undefined,
      };

      setSession(updatedSession);
    } else {
      setSession(currentSession);
    }

    if (camera === Camera.SMALL) {
      handlePushToSessionPreviewPage();
    } else {
      handlePushToSessionUploadPage();
    }
  };

  return (
    <Modal
      show={isShowConfirmModal}
      onHide={() => handleSetIsShowConfirmModal(false)}
      backdrop="static"
      animation={false}
      scrollable={true}
      fullscreen="xl-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm to Resume</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Card className="mb-4">
            <Card.Body>
              <Stack gap={2}>
                {(
                  Object.keys(patientInfoConfirmation) as Array<
                    keyof Session & keyof typeof PatientInfoKeyMapOptain
                  >
                ).map((objKey) => (
                  <Row key={objKey}>
                    <Col>
                      <span className="fw-medium">
                        {PatientInfoKeyMapOptain[objKey]}
                      </span>
                    </Col>
                    <Col>
                      <span>{patientInfoConfirmation[objKey]}</span>
                    </Col>
                  </Row>
                ))}
              </Stack>
            </Card.Body>
          </Card>
          <div className="border rounded p-3 border-info shadow">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column className="fw-medium">
                Left Eye Dilation
              </Form.Label>

              <Col>
                <Form.Group className="gap-2">
                  <Form.Check
                    type="radio"
                    label="Not Dilated"
                    value="Not Dilated"
                    {...register('leftEyeDilated')}
                  />
                  <Form.Check
                    type="radio"
                    label="Dilated"
                    value="Dilated"
                    {...register('leftEyeDilated')}
                  />
                </Form.Group>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column className="fw-medium">
                Right Eye Dilation
              </Form.Label>
              <Col>
                <Form.Group className="gap-2">
                  <Form.Check
                    type="radio"
                    label="Not Dilated"
                    value="Not Dilated"
                    {...register('rightEyeDilated')}
                  />
                  <Form.Check
                    type="radio"
                    label="Dilated"
                    value="Dilated"
                    {...register('rightEyeDilated')}
                  />
                </Form.Group>
              </Col>
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="text-black w-200px"
            onClick={() => handleSetIsShowConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="text-black w-200px"
            type="submit"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ConfirmPatientInformationModal;
