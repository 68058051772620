import preval from 'preval.macro';

import packageJson from '../../package.json';
import { BackendType } from './BackendType.model';
import { Camera } from './Camera.model';
import { Language } from './Language.model';
import { Product } from './Product.model';
import { ReportTemplate } from './ReportTemplate.model';
import { System } from './System.model';

export interface Application {
  product: Product;
  camera: Camera;
  header: string;
  version: string;
  releasedAt: Date;
  templates: ReportTemplate[];
  languages: Language[];
  system?: System;
  backend?: BackendType;
}

const APP_VERSION = packageJson.version;
const RELEASE_DATE = new Date(preval`module.exports = new Date().valueOf();`);

// ---- Assure Plus ----
const appAssurePlusLarge: Application = {
  product: Product.ASSUREPLUS,
  camera: Camera.LARGE,
  header: 'eyetelligence',
  version: APP_VERSION,
  releasedAt: RELEASE_DATE,
  templates: [
    ReportTemplate.ASSURE_LARGE_EYE,
    ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD,
  ],
  languages: [Language.ENGLISH],
};

const appAssurePlusSmall: Application = {
  product: Product.ASSUREPLUS,
  camera: Camera.SMALL,
  header: 'eyetelligence',
  version: APP_VERSION,
  releasedAt: RELEASE_DATE,
  templates: [
    ReportTemplate.ASSURE_SMALL_EYE,
    ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD,
  ],
  languages: [Language.ENGLISH],
};

// ---- Assure Plus Au ----
const appAssurePlusAuLarge: Application = {
  product: Product.ASSUREPLUS_AU,
  camera: Camera.LARGE,
  header: 'eyetelligence',
  version: APP_VERSION,
  releasedAt: RELEASE_DATE,
  templates: [
    ReportTemplate.ASSURE_LARGE_EYE,
    ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD_AUSCVD,
  ],
  languages: [Language.ENGLISH],
};

const appAssurePlusAuSmall: Application = {
  product: Product.ASSUREPLUS_AU,
  camera: Camera.SMALL,
  header: 'eyetelligence',
  version: APP_VERSION,
  releasedAt: RELEASE_DATE,
  templates: [
    ReportTemplate.ASSURE_SMALL_EYE,
    ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD_AUSCVD,
  ],
  languages: [Language.ENGLISH],
};

// ---- Optain ----
const appOptainSmall = {
  product: Product.OPTAIN,
  camera: Camera.SMALL,
  header: 'optain',
  version: APP_VERSION,
  releasedAt: RELEASE_DATE,
  templates: [ReportTemplate.OPTAIN_SMALL_DR],
  languages: [Language.ENGLISH],
};

// ---- Teleophth ----
const appTeleophthSmall = {
  product: Product.TELEOPHTH,
  camera: Camera.SMALL,
  header: 'teleophth',
  version: APP_VERSION,
  releasedAt: RELEASE_DATE,
  templates: [],
  languages: [Language.ENGLISH],
};

const ApplicationInstance = (product: Product, camera: Camera): Application => {
  if (product === Product.ASSUREPLUS && camera === Camera.LARGE) {
    return appAssurePlusLarge;
  }

  if (product === Product.ASSUREPLUS && camera === Camera.SMALL) {
    return appAssurePlusSmall;
  }

  if (product === Product.ASSUREPLUS_AU && camera === Camera.LARGE) {
    return appAssurePlusAuLarge;
  }

  if (product === Product.ASSUREPLUS_AU && camera === Camera.SMALL) {
    return appAssurePlusAuSmall;
  }

  if (product === Product.OPTAIN && camera === Camera.SMALL) {
    return appOptainSmall;
  }

  if (product === Product.TELEOPHTH && camera === Camera.SMALL) {
    return appTeleophthSmall;
  }

  console.log(
    `Unknown app for ${product} ${camera}, use assure large as default`,
  );
  return appOptainSmall;
};

export { ApplicationInstance };
