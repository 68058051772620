import { Dispatch, SetStateAction, useState } from 'react';
import { Badge, Button, ButtonGroup, Container } from 'react-bootstrap';

import useBoundStore from 'src/store/useBoundStore';

import ConfirmPatientInformationModal from 'src/components/ConfirmPatientInformationModal';
import WarnNoConnectedCameraModal from 'src/components/custom_components/WarnNoConnectedCameraModal';
import OHResults from 'src/components/OHResults/OHResults';
import ReportViewer from 'src/components/ReportViewer';

import { Api } from 'src/models/Api.model';
import { Camera } from 'src/models/Camera.model';
import { CameraMode } from 'src/models/CameraMode.model';
import {
  CompletedReasonEnum,
  pendingStatusText,
  Session,
  SessionStatusEnum,
} from 'src/models/Session.model';

import getGradingResult from 'src/utils/getGradingResult';

interface OptainResultsProps {
  cameraMode: CameraMode;
  setSession: Dispatch<SetStateAction<Session>>;
}

const OptainResults = ({ cameraMode, setSession }: OptainResultsProps) => {
  const [showViewer, setShowViewer] = useState<boolean>(false);

  const handleSetShowViewer = (showViewer: boolean) => {
    setShowViewer(showViewer);
  };

  const [currentSession, setCurrentSession] = useState<Session>();

  const handleSetCurrentSession = (currentSession: Session) => {
    setCurrentSession(currentSession);
  };

  const { userPermission, camera, cameraData, getSettings } = useBoundStore();
  const settings = getSettings();

  const handleViewReport = async (session: Session) => {
    const allowed = userPermission.read;

    if (!allowed) {
      Api.alertBox(
        'Error',
        'Failed to view the report. User permission denied.',
      );
      return;
    }
    handleSetCurrentSession({
      ...session,
      template: session.order?.OrderType.value,
    });
    handleSetShowViewer(true);
  };

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleSetIsShowConfirmModal = (isShowConfirmModal: boolean) => {
    setIsShowConfirmModal(isShowConfirmModal);
  };

  const [
    shouldShowWarnNoConnectedCameraModal,
    setShouldShowWarnNoConnectedCameraModal,
  ] = useState(false);

  const handleSetShouldShowWarnNoConnectedCameraModal = (
    shouldShowWarnNoConnectedCameraModal: boolean,
  ) => {
    setShouldShowWarnNoConnectedCameraModal(
      shouldShowWarnNoConnectedCameraModal,
    );
  };

  return (
    <Container fluid className="main-content">
      <OHResults
        extraColumns={[
          {
            title: 'Session Status',
            dataIndex: 'status',
            render: (record) => {
              const { isLeftEyeGradable, isRightEyeGradable } =
                getGradingResult(record);

              return (
                <div>
                  {!record.graded && (
                    <Badge pill bg="secondary-200" className="text-black">
                      {pendingStatusText(record)}
                    </Badge>
                  )}

                  {record.graded && !record.completedReason && (
                    <Badge pill bg="secondary">
                      {SessionStatusEnum.IN_PROGRESS}
                    </Badge>
                  )}

                  {(record.completedReason ===
                    CompletedReasonEnum.COMPLETED_EXAM ||
                    record.completedReason ===
                      CompletedReasonEnum.AUTO_COMPLETE) &&
                    (record.leftResults?.length ? isLeftEyeGradable : true) &&
                    (record.rightResults?.length
                      ? isRightEyeGradable
                      : true) && (
                      <Badge pill bg="success">
                        {SessionStatusEnum.EXAM_SUCCESSFUL}
                      </Badge>
                    )}

                  {(record.completedReason ===
                    CompletedReasonEnum.END_SESSION ||
                    ((record.completedReason ===
                      CompletedReasonEnum.COMPLETED_EXAM ||
                      record.completedReason ===
                        CompletedReasonEnum.AUTO_COMPLETE) &&
                      ((!!record.leftResults?.length && !isLeftEyeGradable) ||
                        (!!record.rightResults?.length &&
                          !isRightEyeGradable)))) && (
                    <Badge pill bg="secondary-200" className="text-black">
                      {SessionStatusEnum.EXAM_ENDED}
                    </Badge>
                  )}
                </div>
              );
            },
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (record) => {
              return (
                <ButtonGroup aria-label="Review report and resume">
                  <Button
                    variant="optain"
                    onClick={() => handleViewReport(record)}
                    disabled={
                      !record.order ||
                      !record.graded ||
                      (record.graded && !record.completedReason)
                    }
                  >
                    View report
                  </Button>
                  <Button
                    variant="outline-optain"
                    disabled={!(record.graded && !record.completedReason)}
                    onClick={() => {
                      if (
                        camera === Camera.SMALL &&
                        cameraMode === CameraMode.API &&
                        !cameraData
                      ) {
                        handleSetShouldShowWarnNoConnectedCameraModal(true);
                        return;
                      }
                      handleSetCurrentSession({
                        ...record,
                        template: record.order?.OrderType.value,
                      });
                      handleSetIsShowConfirmModal(true);
                    }}
                  >
                    Resume
                  </Button>
                </ButtonGroup>
              );
            },
          },
        ]}
      />
      {currentSession && (
        <>
          {showViewer && (
            <ReportViewer
              show={showViewer}
              settings={settings}
              setShow={handleSetShowViewer}
              session={currentSession}
            />
          )}

          <ConfirmPatientInformationModal
            currentSession={currentSession}
            setSession={setSession}
            isShowConfirmModal={isShowConfirmModal}
            handleSetIsShowConfirmModal={handleSetIsShowConfirmModal}
          />

          <WarnNoConnectedCameraModal
            isVisible={shouldShowWarnNoConnectedCameraModal}
          />
        </>
      )}
    </Container>
  );
};

export default OptainResults;
