import { GlobalStatus } from 'src/models/GalenData/DeviceDataView.model';
import { Session } from 'src/models/Session.model';

export default function isGradable(
  globalStatus: GlobalStatus,
  session: Session,
): boolean {
  if (globalStatus !== GlobalStatus.ReportEnd) {
    return true;
  }

  const leftGradable =
    session.leftResults?.every((result) => result.gradable) ?? true;
  const rightGradable =
    session.rightResults?.every((result) => result.gradable) ?? true;

  return leftGradable && rightGradable;
}
