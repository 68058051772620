import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { REM, SCALE } from 'src/utils/reactPdfStyles';

interface WatermarkProps {
  text: string;
}

export default function Watermark({ text }: WatermarkProps) {
  const overlayStyle: Style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    transform: 'rotate(-45deg)',
    color: '#cccccc',
    opacity: 0.2,
    lineHeight: 1.5,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  };

  const calculateFontSize = () => {
    const baseFontSize = 15;

    const textLength = text.length;

    return Math.max(baseFontSize - textLength * 0.2, 4);
  };

  return (
    <View
      style={[overlayStyle, { fontSize: calculateFontSize() * REM * SCALE }]}
    >
      <Text>{text}</Text>
    </View>
  );
}
