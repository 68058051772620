import useBoundStore from 'src/store/useBoundStore';

import { CameraApi } from 'src/models/Camera/CameraApi.model';

export default function useCancelCaptureImages() {
  const { handleSetIsCanceling } = useBoundStore();

  const handleCancelCapture = (handleResetData: () => void) => {
    CameraApi.reset((err) => {
      if (err === undefined) {
        handleSetIsCanceling(true);

        setTimeout(() => {
          handleSetIsCanceling(false);

          handleResetData();
        }, 30_000);
      }
    });
  };

  return {
    handleCancelCapture,
  };
}
