import { Text, View } from '@react-pdf/renderer';

import { handleNSI18Next } from 'src/models/Language.model';

import { cls } from 'src/utils/reactPdfStyles';

const EyeHealthSection = () => {
  const { handleReportT } = handleNSI18Next();

  return (
    <>
      <Text style={cls('p section-text lead mt-4')}>
        {handleReportT('eyeHealthSection.title')}
      </Text>

      <View style={cls('border-top pt-4')}>
        <Text style={cls('p')}>
          {handleReportT('eyeHealthSection.disclaimerText')}
        </Text>
      </View>
    </>
  );
};

export default EyeHealthSection;
