import useSWRMutation from 'swr/mutation';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { SetLanguageParams } from 'src/models/Camera/Settings/Language/SetLanguage';

export function useSetCameraLanguage() {
  const { trigger, isMutating } = useSWRMutation(
    'set-camera-language',
    async (_, { arg }: { arg: SetLanguageParams }) => {
      return CameraApi.setLanguage(arg);
    },
  );

  return {
    setCameraLanguage: trigger,
    isMutating,
  };
}
