import { Button, Modal, Stack } from 'react-bootstrap';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EyesPosition, Session } from 'src/models/Session.model';

import useActionAnalyzeModel from 'src/hooks/useActionAnalyzeModel';

import RetakeActionButtons from './RetakeActionButtons';

interface ProgressModalFooterProps {
  handleUnsetSavedEyePosition: (position: EyesPosition) => void;
  session: Session;
  leftSaved: boolean;
  rightSaved: boolean;
  totalOfImagingEyes: number;
  hasCompletedExam: boolean;
  shouldShowDilationRecommendation: boolean;
  shouldShowReachMaxRecommendedAttempts: boolean;
  handleCloseAnalyzingProgressModal: () => void;
  handleResetSession: () => void;
  totalLeftImageAttempt: number;
  totalRightImageAttempt: number;
  isFindingRetakeSessions: boolean;
}

const ProgressModalFooter = ({
  handleUnsetSavedEyePosition,
  session,
  leftSaved,
  rightSaved,
  totalOfImagingEyes,
  hasCompletedExam,
  shouldShowDilationRecommendation,
  shouldShowReachMaxRecommendedAttempts,
  handleCloseAnalyzingProgressModal,
  handleResetSession,
  totalLeftImageAttempt,
  totalRightImageAttempt,
  isFindingRetakeSessions,
}: ProgressModalFooterProps) => {
  const {
    handleCompleteExam,
    handleSaveAndDilate,
    handleEndSession,
    isUpdating,
  } = useActionAnalyzeModel(
    handleCloseAnalyzingProgressModal,
    handleResetSession,
    session,
  );

  return (
    <Modal.Footer>
      {session.graded && !isFindingRetakeSessions ? (
        <Stack direction="horizontal" gap={4}>
          {(shouldShowReachMaxRecommendedAttempts || hasCompletedExam) && (
            <Button
              variant="optain"
              disabled={isUpdating}
              onClick={handleCompleteExam}
            >
              Complete exam
            </Button>
          )}

          {shouldShowDilationRecommendation && !hasCompletedExam && (
            <Button
              variant="optain"
              className="w-250px"
              onClick={handleSaveAndDilate}
            >
              Save + Dilate
            </Button>
          )}

          <RetakeActionButtons
            session={session}
            handleUnsetSavedEyePosition={handleUnsetSavedEyePosition}
            leftSaved={leftSaved}
            rightSaved={rightSaved}
            totalOfImagingEyes={totalOfImagingEyes}
            shouldShowDilationRecommendation={shouldShowDilationRecommendation}
            shouldShowReachMaxRecommendedAttempts={
              shouldShowReachMaxRecommendedAttempts
            }
            handleCloseAnalyzingProgressModal={
              handleCloseAnalyzingProgressModal
            }
            totalLeftImageAttempt={totalLeftImageAttempt}
            totalRightImageAttempt={totalRightImageAttempt}
          />

          {hasCompletedExam && (
            <Stack direction="horizontal" gap={2}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-optain-900 ms-2"
              />
              <span className="text-primary-400">Success!</span>
            </Stack>
          )}

          {!hasCompletedExam && !shouldShowReachMaxRecommendedAttempts && (
            <Button
              variant="outline-secondary"
              disabled={!session.graded}
              onClick={handleEndSession}
            >
              End Session
            </Button>
          )}
        </Stack>
      ) : (
        <div>
          <Button variant="outline-secondary" disabled={true}>
            End Session
          </Button>
        </div>
      )}
    </Modal.Footer>
  );
};

export default ProgressModalFooter;
