import { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  DiabetesDataLabelEnum,
  LengthOfDiabetesDiagnosisEnum,
  TimeSinceLastEyeExamEnum,
  TypeOfDiabetesEnum,
} from 'src/models/DiabetesData.model';
import { Session } from 'src/models/Session.model';

interface DiabetesDataProps {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
}

const DiabetesData = ({ session, setSession }: DiabetesDataProps) => {
  const history = useHistory();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      typeOfDiabetes: TypeOfDiabetesEnum.Other,
      lengthOfDiabetesDiagnosis: LengthOfDiabetesDiagnosisEnum.Unknown,
      timeSinceLastEyeExam: TimeSinceLastEyeExamEnum.Unknown,
    },
  });

  const submitDiabetesData: SubmitHandler<{
    typeOfDiabetes: TypeOfDiabetesEnum;
    lengthOfDiabetesDiagnosis: LengthOfDiabetesDiagnosisEnum;
    timeSinceLastEyeExam: TimeSinceLastEyeExamEnum;
  }> = (data) => {
    setSession({
      ...session,
      typeOfDiabetes: data.typeOfDiabetes,
      lengthOfDiabetesDiagnosis: data.lengthOfDiabetesDiagnosis,
      timeSinceLastEyeExam: data.timeSinceLastEyeExam,
    });
    setIsShowConfirmModal(true);
  };

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleCloseConfirmModal = () => {
    setIsShowConfirmModal(false);
  };

  return (
    <Container fluid className="main-content optain-bg">
      <Stack gap={4} className="h-100">
        <Row>
          <Col md={6}>
            <div>
              <p className="text-optain mb-0">NEW EXAM</p>
              <h3>
                {session.lastName}, {session.firstName}
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="h-100">
          <Form
            className="d-flex flex-column justify-content-between"
            onSubmit={handleSubmit(submitDiabetesData)}
          >
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {DiabetesDataLabelEnum.TypeOfDiabetes}
                  </Form.Label>
                  <Form.Select {...register('typeOfDiabetes')}>
                    {Object.values(TypeOfDiabetesEnum).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {DiabetesDataLabelEnum.LengthOfDiabetesDiagnosis}
                  </Form.Label>
                  <Form.Select {...register('lengthOfDiabetesDiagnosis')}>
                    {Object.values(LengthOfDiabetesDiagnosisEnum).map(
                      (type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ),
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {DiabetesDataLabelEnum.TimeSinceLastEyeExam}
                  </Form.Label>
                  <Form.Select {...register('timeSinceLastEyeExam')}>
                    {Object.values(TimeSinceLastEyeExamEnum).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Stack
              direction="horizontal"
              className="justify-content-end mb-5"
              gap={2}
            >
              <Button
                variant="light text-optain"
                className="w-200px shadow"
                onClick={() => history.goBack()}
              >
                Previous
              </Button>

              <Button variant="optain" className="w-200px shadow" type="submit">
                Next
              </Button>
            </Stack>
          </Form>
        </Row>

        <Modal
          show={isShowConfirmModal}
          onHide={handleCloseConfirmModal}
          backdrop="static"
          animation={false}
          scrollable={true}
          fullscreen="xl-down"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Diabetes Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <Stack gap={2}>
                  <Row>
                    <Col>
                      <span className="fw-medium">
                        {DiabetesDataLabelEnum.TypeOfDiabetes}
                      </span>
                    </Col>
                    <Col>
                      <span>{session.typeOfDiabetes}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="fw-medium">
                        {DiabetesDataLabelEnum.LengthOfDiabetesDiagnosis}
                      </span>
                    </Col>
                    <Col>
                      <span>{session.lengthOfDiabetesDiagnosis}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="fw-medium">
                        {DiabetesDataLabelEnum.TimeSinceLastEyeExam}
                      </span>
                    </Col>
                    <Col>
                      <span>{session.timeSinceLastEyeExam}</span>
                    </Col>
                  </Row>
                </Stack>
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="light text-optain"
              className="shadow"
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </Button>

            <Button
              variant="optain"
              className="shadow"
              onClick={() => history.push('/session-hint')}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Stack>
    </Container>
  );
};

export default DiabetesData;
