import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';

export default function useCheckNavigation() {
  const { isCapturing, isCanceling } = useBoundStore();

  const checkNavigation = (e: any) => {
    // camera in use?
    if (isCapturing || isCanceling) {
      e.preventDefault(); // stop navigation

      Api.alertBox(
        'Wanning',
        'Please finish the imaging process before navigating to another page.',
      );
      return;
    }

    // workflow in progress?
    if (
      window.location.hash === '#/session-hint' ||
      window.location.hash === '#/session-preview' ||
      window.location.hash === '#/session-upload'
    ) {
      const yes = window.confirm(
        'Are you sure you want to exit the imaging process?',
      );

      if (!yes) {
        e.preventDefault(); // stop navigation
      }
    }
  };

  return { checkNavigation };
}
