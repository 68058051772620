import { Language } from './Language.model';
import { Settings } from './Settings.model';

// ---- settings ----
const initSettings: Settings = {
  language: Language.ENGLISH,

  folder: '',

  reportGender: true,
  reportHeatmap: false,
  reportLogo: false,
  logoFile: '',

  broadcast: '',

  billingCode: false,

  watermark: '',

  userPermission: process.env.REACT_APP_USER_PERMISSION
    ? JSON.parse(process.env.REACT_APP_USER_PERMISSION)
    : {
        create: true,
        list: true,
        read: false,
        update: false,
        delete: false,
        print: false,
      },
};

export { initSettings };
