import React from 'react';
import { useTranslation } from 'react-i18next';

import { Language } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';

import AppointmentSection from '../reports/AssurePlus/AppointmentSection';
import EyeHealthSection from '../reports/AssurePlus/EyeHealthSection';
import NotesSection from '../reports/AssurePlus/NotesSection';
import NoticeSection from '../reports/AssurePlus/NoticeSection';

type NotesOrNoticeSectionProps = {
  session: Session;
};

export default function NotesOrNoticeSection({
  session,
}: NotesOrNoticeSectionProps) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <>
      {language === Language.GERMAN ? (
        <>
          <NoticeSection />
          <EyeHealthSection />
        </>
      ) : (
        <>
          <NotesSection session={session} />
          <AppointmentSection session={session} />
        </>
      )}
    </>
  );
}
