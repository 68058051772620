import { useCallback, useState } from 'react';

import { Api } from 'src/models/Api.model';
import { Session } from 'src/models/Session.model';

import getSessionsImageFiles from 'src/utils/getSessionsImageFiles';
import handleHttpRequestError from 'src/utils/handleHttpRequestError';

import useGetRetinalExamDataModelId from './useGetRetinalExamDataModelConfig';

export default function useGetSessions(session: Session) {
  const [totalLeftImageAttempt, setTotalLeftImageAttempt] = useState(0);

  const [totalRightImageAttempt, setTotalRightImageAttempt] = useState(0);

  const { deviceDataModelId } = useGetRetinalExamDataModelId();

  const getSessions = useCallback(
    async (
      handleSetIsFindingRetakeSessions: (
        isFindingRetakeSessions: boolean,
      ) => void,
    ) => {
      try {
        if (!session.graded) return;

        handleSetIsFindingRetakeSessions(true);
        const sessions = await Api.remote.find_retake_sessions(
          deviceDataModelId,
          session.iid,
        );

        const sessionList = sessions.length > 0 ? sessions : [session];
        const { leftImageFileContainer, rightImageFileContainer } =
          getSessionsImageFiles(sessionList);

        setTotalLeftImageAttempt(leftImageFileContainer.length);
        setTotalRightImageAttempt(rightImageFileContainer.length);
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        Api.alertBox(
          'Error',
          `Failed to find retake sessions. ${errorMessage}`,
        );
      } finally {
        handleSetIsFindingRetakeSessions(false);
      }
    },
    [deviceDataModelId, session],
  );

  return {
    getSessions,
    totalLeftImageAttempt,
    totalRightImageAttempt,
  };
}
