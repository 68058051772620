import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { Text, View } from '@react-pdf/renderer';

import FontAwesomeIcon from 'src/components/custom_components/PdfFontAwesomeIcon';

import { Appointment } from 'src/models/Appointment.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';

import { cls, REM, SCALE } from 'src/utils/reactPdfStyles';

interface IAppointmentSectionProps {
  session: Session;
}

const AppointmentSection = ({ session }: IAppointmentSectionProps) => {
  const appointmentTd = (appointment: Appointment) => {
    const { handleCommonT } = handleNSI18Next();
    return (
      <View key={appointment} style={cls('td border-0')}>
        <View
          style={cls('badge text-black pe-3', { fontSize: 1 * REM * SCALE })}
        >
          <FontAwesomeIcon
            icon={
              session.appointment === appointment ? faCircleCheck : faCircle
            }
            style={cls('section-text me-2')}
          ></FontAwesomeIcon>

          <Text>{handleCommonT(`appointment.${appointment}`)}</Text>
        </View>
      </View>
    );
  };

  const { handleReportT } = handleNSI18Next();

  return (
    <>
      <Text style={cls('section-text lead')}>
        {handleReportT('appointmentSection.title')}:
      </Text>

      <View style={cls('d-flex hstack justify-content-between')}>
        {[
          Appointment.THREE_MONTHS,
          Appointment.SIX_MONTHS,
          Appointment.TWELVE_MONTHS,
          Appointment.TWENTY_FOUR_MONTHS,
          Appointment.THIRTY_SIX_MONTHS,
          Appointment.REFERRAL,
        ].map(appointmentTd)}
      </View>
    </>
  );
};

export default AppointmentSection;
