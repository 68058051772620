enum TypeOfDiabetesEnum {
  Type1 = 'Type-1',
  Type2 = 'Type-2',
  Other = 'Other',
}

enum LengthOfDiabetesDiagnosisEnum {
  Unknown = 'Unknown',
  NotDiabetic = 'Not diabetic',
  BorderLine = 'Border line',
  Gestational = 'Gestational',
  OneYearOrLess = '1 year or less',
  TwoYears = '2 years',
  ThreeYears = '3 years',
  FourYears = '4 years',
  FiveYears = '5 years',
  SixToTenYears = '6-10 years',
  ElevenToFifteenYears = '11-15 years',
  SixteenToTwentyYears = '16-20 years',
  MoreThanTwentyYears = 'More than 20 years',
}

enum TimeSinceLastEyeExamEnum {
  Unknown = 'Unknown',
  Within9Months = 'Within 9 months',
  NineToFifteenMonths = '9 to 15 months',
  TwoToFiveYears = '2 to 5 years',
  MoreThanFiveYears = 'More than 5 years',
  Never = 'Never',
}

enum DiabetesDataLabelEnum {
  TypeOfDiabetes = 'Type of Diabetes',
  LengthOfDiabetesDiagnosis = 'Length of Diabetes Diagnosis',
  TimeSinceLastEyeExam = 'Time Since Last Eye Exam',
}

export {
  DiabetesDataLabelEnum,
  LengthOfDiabetesDiagnosisEnum,
  TimeSinceLastEyeExamEnum,
  TypeOfDiabetesEnum,
};
