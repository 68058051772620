import { InputGroup } from 'react-bootstrap';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type CustomInputGroupWithIconProps = {
  children: React.ReactNode;
};
function CustomInputGroup({ children }: CustomInputGroupWithIconProps) {
  return (
    <InputGroup className="mb-3">
      <InputGroup.Text style={{ backgroundColor: 'unset' }}>
        <FontAwesomeIcon icon={faSearch} className="text-secondary" />
      </InputGroup.Text>
      {children}
    </InputGroup>
  );
}

export default CustomInputGroup;
