import React, { useState } from 'react';
import { Button, ButtonProps, Form, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import Sentry from 'src/services/sentry';
import * as z from 'zod';

import useBoundStore from 'src/store/useBoundStore';

import { CameraApi } from 'src/models/Camera/CameraApi.model';

const schema = z.object({
  ip: z.string().ip({
    message: 'Please enter a valid IP address in the format xxx.xxx.xxx.xxx',
  }),
});

export type ManualConnectCameraProps = {
  isScanning: boolean;
  connectButtonProps?: ButtonProps;
} & ButtonProps;

export default function ManualConnectCamera({
  isScanning,
  connectButtonProps,
  ...props
}: ManualConnectCameraProps) {
  const { setCameraData } = useBoundStore();

  const [isOpenInputIPModal, setIsOpenInputIPModal] = useState(false);
  const [cameraNotFoundMessage, setCameraNotFoundMessage] = useState('');

  const handleCloseInputIPModal = () => {
    setIsOpenInputIPModal(false);
    reset();
    setCameraNotFoundMessage('');
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ip: '',
    },
    resolver: zodResolver(schema),
  });

  const [isConnecting, setIsConnecting] = useState(false);

  const onSubmit: SubmitHandler<{
    ip: string;
  }> = (data) => {
    setIsConnecting(true);

    CameraApi.connect(data.ip, (err, res) => {
      if (err === undefined && res !== false) {
        setCameraData(res);
      } else {
        Sentry.captureMessage('Camera has not been found!');
        setCameraNotFoundMessage('Camera has not been found.');
      }
      setIsConnecting(false);
    });
  };

  return (
    <>
      <Button
        variant="dark"
        onClick={() => setIsOpenInputIPModal(true)}
        disabled={isScanning}
        {...props}
      >
        <FontAwesomeIcon
          icon={faPlug}
          className="me-2"
          data-test="manual-connect-btn"
        />
        Manual connect
      </Button>

      <Modal show={isOpenInputIPModal} onHide={handleCloseInputIPModal}>
        <Modal.Header closeButton>
          <Modal.Title>Connect to device</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="ip">
              <Form.Label>IP address:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the IP address of the camera"
                {...register('ip')}
                onInput={() => setCameraNotFoundMessage('')}
              />
              {cameraNotFoundMessage && (
                <p className="text-danger">{cameraNotFoundMessage}</p>
              )}
              {errors.ip?.message && (
                <p className="text-danger">{errors.ip?.message}</p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light text-optain"
              className="shadow"
              onClick={handleCloseInputIPModal}
            >
              Close
            </Button>
            <Button
              variant="optain"
              className="shadow"
              type="submit"
              disabled={isConnecting}
              {...connectButtonProps}
              onClick={() => setCameraNotFoundMessage('')}
            >
              {isConnecting ? 'Connecting...' : 'Connect'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
