import { Disease } from './Disease.model';
import { FindGrading } from './Grading.model';
import { Session } from './Session.model';

enum CvdKey {
  RISK = 'pred',
  RANK = 'rank',
}

const getRiskOrRank = (results: any, disease: Disease) => {
  const risk = FindGrading(results, disease);
  const rank = FindGrading(
    results,
    disease === Disease.AUSCVD_RISK ? Disease.AUSCVD_RANK : Disease.WHOCVD_RANK,
  );

  return {
    risk: typeof risk === 'number' ? risk : undefined,
    rank: typeof rank === 'number' ? rank : undefined,
  };
};

const isValidCvdAge = (age: number | undefined) => {
  return age !== undefined && age >= 40 && age <= 80;
};

const roundToDecimal = (value: number, decimals: number) => {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
};

const calculateAverage = (
  odValue: number,
  osValue: number,
  isRisk: boolean,
) => {
  const average = (odValue + osValue) / 2;
  return isRisk ? roundToDecimal(average, 1) : Math.round(average);
};

const CalculateCommonCvdValue = (
  session: Session,
  key: CvdKey,
  disease: Disease,
): number | undefined => {
  const od = getRiskOrRank(session.rightResults, disease);
  const os = getRiskOrRank(session.leftResults, disease);

  if (od.risk === undefined && os.risk === undefined) {
    return undefined; // N/A
  }

  if (!isValidCvdAge(session.age)) {
    return undefined; // N/A
  }

  // both numbers
  if (
    od.risk !== undefined &&
    od.rank !== undefined &&
    os.risk !== undefined &&
    os.rank !== undefined
  ) {
    return key === CvdKey.RISK
      ? calculateAverage(od.risk, os.risk, true)
      : calculateAverage(od.rank * 100, os.rank * 100, false);
  }

  // single number
  if (od.risk !== undefined && od.rank !== undefined) {
    return key === CvdKey.RISK
      ? roundToDecimal(od.risk, 1)
      : Math.round(od.rank * 100);
  }

  if (os.risk !== undefined && os.rank !== undefined) {
    return key === CvdKey.RISK
      ? roundToDecimal(os.risk, 1)
      : Math.round(os.rank * 100);
  }

  // no numbers
  return undefined; // N/A
};

const CalculateCvdValue = (
  session: Session,
  key: CvdKey,
  disease: Disease,
): number | undefined => {
  if (!session.graded) {
    return undefined;
  }

  return CalculateCommonCvdValue(session, key, disease);
};

export { CalculateCvdValue, CvdKey, isValidCvdAge };
