import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-regular-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

import useBoundStore from 'src/store/useBoundStore';

import AnalyzingProgressModal from 'src/components/AnalyzingProgressModal/AnalyzingProgressModal';

import { Api } from 'src/models/Api.model';
import { getErrorMessage } from 'src/models/GalenData/helpers/getErrorMessage';
import { initData } from 'src/models/InitialData.model';
import { ModelEnum } from 'src/models/Model.model';
import { getModels } from 'src/models/Product.model';
import { EyesPosition, Session } from 'src/models/Session.model';

import useCancelCaptureImages from 'src/hooks/camera/useCancelCaptureImages';
import useRetake from 'src/hooks/useRetake';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

import SmallPupilModal from './SmallPupilModal';

interface ISessionPreviewProps {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
}

const SessionPreview = ({ session, setSession }: ISessionPreviewProps) => {
  const {
    leftUrl,
    rightUrl,
    leftFileType,
    rightFileType,
    handleSetLeftUrl,
    handleSetRightUrl,
    isCapturing,
    isCanceling,
    handleSetIsCapturing,
    handleStartCaptureBoth,
    handleStartCaptureLeft,
    handleStartCaptureRight,
    product,
    practiceId,
    camera,
    userId,
    cameraData,
  } = useBoundStore();

  const [leftSaved, setLeftSaved] = useState<boolean>(false);
  const [rightSaved, setRightSaved] = useState<boolean>(false);
  const [deviceDataId, setDeviceDataId] = useState<string>('');
  const [models, setModels] = useState<ModelEnum[]>([]);
  const [sessionId, setSessionId] = useState<string>('');
  const [grading, setGrading] = useState(false);

  const history = useHistory();

  const handlePrevious = () => {
    history.goBack();
  };

  const handleSaveLeft = () => {
    setLeftSaved(true);
    if (rightSaved) {
      handleSetIsCapturing(false);
    }
  };

  const handleSaveRight = () => {
    setRightSaved(true);
    if (leftSaved) {
      handleSetIsCapturing(false);
    }
  };

  const submitSession = async () => {
    setGrading(true);

    try {
      if (!practiceId) {
        throw new Error(getErrorMessage('practice-is-required'));
      }

      const formValues = Object.assign({}, session);

      if (Object.prototype.hasOwnProperty.call(formValues, 'retakeSessions')) {
        delete formValues.retakeSessions;
      }

      if (leftSaved) {
        formValues.leftImageFile = leftUrl;
        const leftFileName = leftUrl.split('/').pop();
        formValues.leftImageFileName = leftFileName
          ? leftFileName + leftFileType
          : '';
      }

      if (rightSaved) {
        formValues.rightImageFile = rightUrl;
        const rightFileName = rightUrl.split('/').pop();
        formValues.rightImageFileName = rightFileName
          ? rightFileName + rightFileType
          : '';
      }

      const models = getModels(product, camera, formValues.template);

      setModels(models);

      const sessionId = uuidv4();
      setSessionId(sessionId);

      // submit session
      const iid = await Api.remote.submit_session(
        practiceId,
        userId,
        formValues,
        models,
        sessionId,
        product,
        cameraData,
      );

      setSession(formValues);
      setDeviceDataId(iid);
    } catch (error) {
      setGrading(false);
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', errorMessage);
    }
  };

  const { handleCancelCapture } = useCancelCaptureImages();

  const handleSetNotGrading = () => {
    setGrading(false);
  };

  const handleResetSession = () => {
    setSession(initData.tempSession);
  };

  const location = useLocation<{
    isResume: boolean;
  }>();

  useEffect(() => {
    if (location.state?.isResume) {
      setGrading(true);

      if (session.leftImageFile) {
        handleSetLeftUrl(session.leftImageFile);
        setLeftSaved(true);
      }

      if (session.rightImageFile) {
        handleSetRightUrl(session.rightImageFile);
        setRightSaved(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [retakeEyePosition, setRetakeEyePosition] = useState('');

  const {
    uploadAnalyzeLeftEye,
    uploadAnalyzeRightEye,
    uploadAnalyzeBothEyes,
    resubmitExistingSession,
  } = useRetake({
    session,
    setSession,
    setSessionId,
    setDeviceDataId,
    setModels,
    handleSetLeftUrl,
    handleSetRightUrl,
  });

  const handleUnsetSavedEyePosition = (position: EyesPosition) => {
    if (position === EyesPosition.BOTH) {
      setLeftSaved(false);
      setRightSaved(false);

      handleStartCaptureBoth();

      setRetakeEyePosition(EyesPosition.BOTH);
    } else if (position === EyesPosition.LEFT) {
      setLeftSaved(false);
      handleStartCaptureLeft();

      setRetakeEyePosition(EyesPosition.LEFT);
    } else {
      setRightSaved(false);
      handleStartCaptureRight();
      setRetakeEyePosition(EyesPosition.RIGHT);
    }
  };

  const handleRetakeAnalyze = () => {
    setGrading(true);
    setSession({ ...session, graded: false });
    if (retakeEyePosition === EyesPosition.BOTH) {
      uploadAnalyzeBothEyes(leftUrl, rightUrl);
    } else if (retakeEyePosition === EyesPosition.LEFT) {
      uploadAnalyzeLeftEye(leftUrl);
    } else {
      uploadAnalyzeRightEye(rightUrl);
    }
  };

  return (
    <Container fluid className="main-content optain-bg">
      <Row>
        <Col md={4}>
          <div>
            <p className="text-optain mb-0">NEW EXAM: {session.id}</p>
            <h3 className="mb-4">
              {session.lastName}, {session.firstName}
            </h3>
          </div>

          <div>
            {isCanceling ? (
              <div
                style={{ height: 300 }}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <iframe
                src={cameraData ? `http://${cameraData.ip}:8080/streamer` : ''}
                style={{
                  border: '1px solid #ccc',
                  transform: 'scale(0.64)',
                  transformOrigin: '0 0',
                  width: 643,
                  height: 706,
                  background: '#fff',
                }}
                title="Preview"
              />
            )}
          </div>
        </Col>
        <Col md={8}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="pb-2">
              <p className="lead">
                Image Capture
                {isCapturing ? (
                  <Badge bg="warning ms-2">In Progress</Badge>
                ) : (
                  <Badge bg="secondary ms-2">Idle</Badge>
                )}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-5 gap-2">
            <div>
              <div>
                <div style={{ maxWidth: 460 }}>
                  <img
                    src={leftUrl || '/img/optain-scanning.png'}
                    alt="left eye"
                    className="img-fluid"
                  />
                </div>
                <Badge
                  bg="dark"
                  className="position-relative"
                  style={{
                    top: -25,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  Left Eye
                </Badge>
              </div>

              <div className="d-flex justify-content-center flex-md-column flex-xxl-row">
                <Button
                  variant={
                    isCapturing || leftSaved ? 'outline-secondary' : 'danger'
                  }
                  onClick={handleStartCaptureLeft}
                  disabled={isCapturing || leftSaved || isCanceling}
                >
                  <FontAwesomeIcon icon={faCamera} className="me-2" />
                  <span>Retake</span>
                </Button>
                <Button
                  variant={
                    leftUrl === '' || leftSaved
                      ? 'outline-secondary'
                      : 'primary'
                  }
                  className="ms-xxl-3 mt-sm-3 mt-xxl-0"
                  onClick={handleSaveLeft}
                  disabled={leftUrl === '' || leftSaved}
                >
                  <FontAwesomeIcon icon={faCircleCheck} className="me-2" />
                  <span>Accept</span>
                </Button>
              </div>
            </div>

            <div>
              <div>
                <div style={{ maxWidth: 460 }}>
                  <img
                    src={rightUrl || '/img/optain-scanning.png'}
                    alt="right eye"
                    className="img-fluid"
                  />
                </div>
                <Badge
                  bg="dark"
                  className="position-relative"
                  style={{
                    top: -25,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  Right Eye
                </Badge>
              </div>

              <div className="d-flex justify-content-center flex-md-column flex-xxl-row">
                <Button
                  variant={
                    isCapturing || rightSaved ? 'outline-secondary' : 'danger'
                  }
                  onClick={handleStartCaptureRight}
                  disabled={isCapturing || rightSaved || isCanceling}
                >
                  <FontAwesomeIcon icon={faCamera} className="me-2" />
                  <span>Retake</span>
                </Button>
                <Button
                  variant={
                    rightUrl === '' || rightSaved
                      ? 'outline-secondary'
                      : 'primary'
                  }
                  className="ms-xxl-3 mt-sm-3 mt-xxl-0"
                  onClick={handleSaveRight}
                  disabled={rightUrl === '' || rightSaved}
                >
                  <FontAwesomeIcon icon={faCircleCheck} className="me-2" />
                  <span>Accept</span>
                </Button>
              </div>
            </div>
          </div>
          <Stack
            direction="horizontal"
            gap={3}
            className="d-flex justify-content-end pt-2"
          >
            <Button
              variant="light text-optain"
              className="me-4 w-200px shadow"
              onClick={handlePrevious}
              disabled={isCapturing || isCanceling}
            >
              Previous
            </Button>
            <Button
              variant="optain"
              className="me-4 w-200px shadow"
              onClick={() => {
                retakeEyePosition ? handleRetakeAnalyze() : submitSession();
              }}
              disabled={!leftSaved || !rightSaved}
            >
              Submit
            </Button>

            <Button
              variant="warning"
              className="shadow"
              disabled={isCanceling || !isCapturing}
              onClick={() => {
                handleCancelCapture(() => {
                  handleSetLeftUrl('');
                  handleSetRightUrl('');
                });
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} className="me-2" />
              Cancel
            </Button>
          </Stack>
        </Col>
      </Row>

      <AnalyzingProgressModal
        leftSaved={leftSaved}
        handleUnsetSavedEyePosition={handleUnsetSavedEyePosition}
        rightSaved={rightSaved}
        session={session}
        isShowAnalyzingProgressModal={grading}
        handleCloseAnalyzingProgressModal={handleSetNotGrading}
        handleResetSession={handleResetSession}
        handleSetLeftUrl={handleSetLeftUrl}
        handleSetRightUrl={handleSetRightUrl}
        sessionId={sessionId}
        deviceDataId={deviceDataId}
        models={models}
        setSession={setSession}
        resubmitExistingSession={resubmitExistingSession}
      />
      <SmallPupilModal />
    </Container>
  );
};

export default SessionPreview;
