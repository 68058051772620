import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
  Stack,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Api } from 'src/models/Api.model';
import { SortOrder, SortOrderEnum } from 'src/models/GalenData/SortOrder.model';
import { pendingStatusText, Session } from 'src/models/Session.model';

import useGetRetinalExamDataModelId from 'src/hooks/useGetRetinalExamDataModelConfig';

import getGradingResult from 'src/utils/getGradingResult';
import getSessionsImageFiles from 'src/utils/getSessionsImageFiles';
import handleHttpRequestError from 'src/utils/handleHttpRequestError';
import { validateSearchValue } from 'src/utils/validationRules';

import CustomTable, {
  Column,
} from '../custom_components/CustomTable/CustomTable';

interface NewResultsPageProps {
  extraColumns?: Column<Session>[];
}

const OHResults = ({ extraColumns }: NewResultsPageProps) => {
  const defaultPageSize = 10;

  const [currentSearchValue, setCurrentSearchValue] = useState<string>('');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      searchValue: '',
    },
  });

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onChangeCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleReset = () => {
    reset();
    onChangeCurrentPage(1);

    handleLoadSessions(0, defaultPageSize, '', SortOrderEnum.DESC);
  };

  const [isLoading, setIsLoading] = useState(false);

  const { deviceDataModelId } = useGetRetinalExamDataModelId();

  const [dataResource, setDataResource] = useState<{
    sessions: Session[];
    totalElements: number;
    totalPages: number;
  }>({
    sessions: [],
    totalElements: 0,
    totalPages: 0,
  });

  const handleLoadSessions = useCallback(
    async (
      page: number,
      pageSize: number,
      searchValue: string,
      sessionsSortOrder: SortOrder,
    ) => {
      try {
        setIsLoading(true);

        const res = await Api.remote.load_sessions_with_extra_data(
          deviceDataModelId,
          page,
          pageSize,
          searchValue,
          sessionsSortOrder,
        );

        setDataResource(res);
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);

        Api.alertBox('Error', `Failed to load sessions. ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    },
    [deviceDataModelId],
  );

  const handleSearch = useCallback(
    (data: { searchValue: string }) => {
      const { searchValue } = data;
      setCurrentSearchValue(searchValue);
      handleLoadSessions(0, defaultPageSize, searchValue, SortOrderEnum.DESC);
      onChangeCurrentPage(1);
    },
    [handleLoadSessions],
  );

  const submitRef = useRef<HTMLFormElement>(null);

  const location = useLocation<{
    keyword: string;
  }>();

  useEffect(() => {
    if (location.state?.keyword) {
      setValue('searchValue', location.state?.keyword, {
        shouldValidate: true,
      });
      setFocus('searchValue');
    }
    submitRef.current?.requestSubmit();
  }, [
    handleLoadSessions,
    handleSearch,
    handleSubmit,
    location.state?.keyword,
    setFocus,
    setValue,
  ]);

  const columns: Column<Session>[] = [
    { title: 'User Email', key: 'username' },
    {
      title: 'Date Time',
      dataIndex: 'dateTime',
      render: (record) => (
        <div>
          <b>{record.date}</b>
          <p>{record.time}</p>
        </div>
      ),
    },

    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      render: (record) => (
        <div>
          {record.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: 'Patient MRN',
      key: 'id',
    },
    { title: 'DOB', key: 'dob' },
    {
      title: (
        <div>
          <span>Session Details</span>
          <p>(# of Attempts)</p>
        </div>
      ),
      dataIndex: 'attempts',
      render: (record) => {
        const { leftImageFileContainer, rightImageFileContainer } =
          getSessionsImageFiles([record, ...(record.retakeSessions ?? [])]);

        const { isLeftEyeGradable, isRightEyeGradable } =
          getGradingResult(record);
        return (
          <div>
            {record.graded ? (
              <Stack gap={2}>
                <Badge pill bg={isLeftEyeGradable ? 'primary' : 'secondary'}>
                  Left Eye (
                  {leftImageFileContainer.length > 0
                    ? leftImageFileContainer.length
                    : 'N/A'}
                  )
                </Badge>
                <Badge pill bg={isRightEyeGradable ? 'primary' : 'secondary'}>
                  Right Eye (
                  {rightImageFileContainer.length > 0
                    ? rightImageFileContainer.length
                    : 'N/A'}
                  )
                </Badge>
              </Stack>
            ) : (
              <Badge pill bg="secondary-200" className="text-black">
                {pendingStatusText(record)}
              </Badge>
            )}
          </div>
        );
      },
    },
    ...(extraColumns || []),
  ];

  const history = useHistory();

  return (
    <>
      <Form onSubmit={handleSubmit(handleSearch)} ref={submitRef}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="searchValue">
            <InputGroup>
              <Form.Control
                autoComplete="off"
                type="text"
                className="rounded-3 me-3"
                placeholder="Search by name, MRN, or patient date of birth (YYYY-MM-DD)"
                {...register('searchValue', {
                  maxLength: {
                    value: 100,
                    message: 'The search must be less than 100 characters.',
                  },
                  validate: {
                    isValid: (value) =>
                      validateSearchValue(value) ||
                      'Please enter a valid name, MRN, or date (YYYY-MM-DD).',
                  },
                  onChange: (event) =>
                    setValue('searchValue', event.target.value, {
                      shouldValidate: true,
                    }),
                })}
              />

              <ButtonGroup style={{ height: 55 }}>
                <Button variant="outline-primary" type="submit">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={classNames({
                      'icon-shake': isValid && getValues('searchValue'),
                    })}
                  />
                </Button>
                <Button
                  variant="outline-warning"
                  onClick={handleReset}
                  disabled={!getValues('searchValue')}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              </ButtonGroup>
            </InputGroup>
          </Form.Group>
        </Row>
        {errors.searchValue?.message && (
          <p className="text-danger">{errors.searchValue?.message}</p>
        )}
      </Form>
      <CustomTable
        isLoading={isLoading}
        dataSource={dataResource.sessions}
        rowKey="iid"
        columns={columns}
        pagination={{
          defaultPageSize,
          pageSizeOptions: [10],
          totalPages: dataResource.totalPages,
          currentPage,
          onChangeCurrentPage,
          onChangePage: (pageNumber, pageSize) => {
            handleLoadSessions(
              pageNumber - 1,
              pageSize,
              currentSearchValue,
              SortOrderEnum.DESC,
            );
          },
        }}
        empty={{
          buttonText: 'Create New Exam',
          onButtonClick: () => {
            history.push('/session-form');
          },
        }}
      />
    </>
  );
};

export default OHResults;
