import { Button, Modal } from 'react-bootstrap';

import { useSWRConfig } from 'swr';

import useBoundStore from 'src/store/useBoundStore';

import { SWRKeysEnum } from 'src/models/SWRKeys.model';

import useCancelCaptureImages from 'src/hooks/camera/useCancelCaptureImages';
import { useContinueCaptureProcess } from 'src/hooks/camera/useContinueCaptureProcess';
import { useGetCurrentCaptureStatus } from 'src/hooks/camera/useGetCurrentCaptureStatus';

const SmallPupilModal = () => {
  const { handleSetLeftUrl, handleSetRightUrl, isCapturing, isCanceling } =
    useBoundStore();

  const { handleCancelCapture } = useCancelCaptureImages();

  const { continueCaptureProcess } = useContinueCaptureProcess();

  const handleContinueCaptureProcess = () => {
    continueCaptureProcess();
  };

  const { statusResponse } = useGetCurrentCaptureStatus(isCapturing);

  const { mutate } = useSWRConfig();

  const handleExit = async () => {
    await mutate(SWRKeysEnum.GET_CURRENT_CAPTURE_STATUS, undefined, false);

    handleCancelCapture(() => {
      handleSetLeftUrl('');
      handleSetRightUrl('');
    });
  };

  return (
    <Modal show={statusResponse?.isPupilSmall} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Notice: Pupil Size</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The current patient's pupil is too small, please confirm whether to
        continue shooting or cancel the shooting
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleContinueCaptureProcess}>
          Continue
        </Button>
        <Button variant="primary" onClick={handleExit} disabled={isCanceling}>
          Exit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SmallPupilModal;
