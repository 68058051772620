import { NavLink } from 'react-router-dom';

import {
  faCirclePause,
  faCirclePlay,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { adminRoleList } from 'src/models/GalenData/UserRole.model';

import useCheckNavigation from 'src/hooks/useCheckNavigation';

interface ISidebarProps {
  show: boolean;
}
const itemClass = (isActive: boolean) => {
  return isActive
    ? 'list-group-item list-group-item-action text-bg-primary active'
    : 'list-group-item list-group-item-action text-bg-primary';
};

const isSessionPage = (match: any, location: any) => {
  const pages = [
    '/session-form',
    '/session-hint',
    '/session-preview',
    '/session-upload',
    '/diabetes-data',
  ];
  return pages.includes(location.pathname);
};

const Sidebar: React.FC<ISidebarProps> = ({ show }) => {
  const { userType, userPermission } = useBoundStore();
  const { checkNavigation } = useCheckNavigation();

  return show ? (
    <div
      className="d-flex flex-column flex-shrink-0 text-bg-dark border-right sidebar d-none d-lg-block d-print-none"
      data-testid="sidebar"
    >
      <div className="sidebar-heading">
        <img
          src={`/icons/logo/optain.png`}
          className="sidebar-icon"
          alt="logo"
        />
      </div>

      <div className="list-group-container">
        <div className="list-group list-group-flush">
          {userPermission.create && (
            <NavLink
              to="/session-form"
              exact={true}
              className={itemClass}
              role="list-group-item"
              onClick={checkNavigation}
              isActive={isSessionPage}
            >
              <div className="item-text">
                <img src="/icons/session.png" alt="session" />
                New Exam
                {!Api.isBrowser() && (
                  <FontAwesomeIcon icon={faCirclePlay} className="menu-icon" />
                )}
                {!Api.isBrowser() && (
                  <FontAwesomeIcon icon={faCirclePause} className="menu-icon" />
                )}
              </div>
            </NavLink>
          )}

          {userPermission.list && (
            <NavLink
              to="/results"
              exact={true}
              className={itemClass}
              onClick={checkNavigation}
            >
              <div className="item-text">
                <img src="/icons/results.png" alt="results" />
                Results
              </div>
            </NavLink>
          )}

          <NavLink
            to="/settings"
            exact={true}
            className={itemClass}
            onClick={checkNavigation}
          >
            <div className="item-text">
              <img src="/icons/settings.png" alt="settings" />
              Settings
            </div>
          </NavLink>

          {userType && adminRoleList.includes(userType) && (
            <NavLink
              to="/subuser"
              exact={true}
              className={itemClass}
              onClick={checkNavigation}
            >
              <div className="item-text">
                <img src="/icons/subuser.png" alt="users" />
                Users
              </div>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Sidebar;
