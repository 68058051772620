import { Session } from 'src/models/Session.model';

export default function getSessionsImageFiles(sessions: Session[]) {
  const leftImageFileContainer = [
    ...new Set(sessions.map((session) => session.leftImageFileName)),
  ];

  const rightImageFileContainer = [
    ...new Set(sessions.map((session) => session.rightImageFileName)),
  ];

  return { leftImageFileContainer, rightImageFileContainer };
}
