import { AxiosResponse } from 'axios';

import { EntityCriteria } from './EntityCriteria.model';
import { SortOrder } from './SortOrder.model';
import { GalenDataUser } from './User.model';
import { UserRoleEnum } from './UserRole.model';

export type AxiosResponseWithRefreshToken<T = any> = AxiosResponse<T> & {
  headers: {
    'x-refresh-token': string;
  };
};

/**
 * Represents an HTTP file which will be transferred from or to a server.
 */
export type HttpFile = Blob & { readonly name: string };

export enum ApiUrlsEnum {
  LOGIN = '/auth/login',
  VERIFY_MFA_CODE = '/auth/mfa/verify',
  SEND_MFA_CODE = '/auth/mfa/send-code',
  CHANGE_PASSWORD = '/user/updatepassword',
  RESET_PASSWORD = '/user/password/send-reset-code',
  USER = '/user/user',
  USER_ADVANCED = '/user/user-advanced',
  DEVICE_DATA = '/data/devicedata',
  COUNT_DEVICE_DATA = '/data/devicedata-count',
  DEVICE_DATA_OWNER = '/data/devicedata/owner',
  DEVICE_DATA_ADVANCED = '/data/devicedata-advanced',
  DEVICE_DATA_ADVANCED_OWNER_CUSTOM_CRITERIA = '/data/devicedata-advanced/owner/custom-criteria',
  DEVICE_DATA_AGGREGATE = '/data/devicedata/aggregate',
  DEVICE_DATA_AGGREGATE_ADVANCED = '/data/devicedata/aggregate-advanced',
  DEVICE_DATA_MEDIA = '/data/devicedata-media',
  USER_CUSTOM = '/user/user-custom',
  USER_CUSTOM_FIELD = '/user/custom-field',
  PRACTICE = '/user/practice',
  USER_CUSTOM_FIELD_DATA = '/user/custom-field/data',
  USER_ME = '/user/me',
  PRACTICE_CUSTOM = '/user/practice-custom',
  SUPPLIER = '/user/supplier',
}

export enum ContentTypeEnum {
  JSON = 'application/json',
  TEXT = 'application/text',
  URL_ENCODED = 'application/x-www-form-urlencoded',
  MULTIPART = 'multipart/form-data',
  TEXT_PLAIN = 'text/plain',
}

export interface IGetUsersRequestBody {
  supplierId?: string;
  practiceId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  dateOfBirth?: string;
  patientId?: string;
  role?: UserRoleEnum;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: Array<string>;
  sortOrder?: SortOrder;
  lastLoggedInVia?: string;
}

export type IGetUsersAdvancedRequestBody = Array<
  EntityCriteria<GalenDataUser & IGetUsersRequestBody>
>;

export interface IGetUserAdvancedParams<T> {
  pageNumber?: number;
  pageSize?: number;
  sortBy?: Array<keyof T> | keyof T;
  sortOrder?: 'ASC' | 'DESC';
}
