import { Image, Text, View } from '@react-pdf/renderer';

import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import { cls } from 'src/utils/reactPdfStyles';

interface IHeaderSectionProps {
  session: Session;
  type: ReportType;
  title: string;
  settings: Settings;
  logo: string;
}

const HeaderSection = ({
  session,
  type,
  title,
  settings,
  logo,
}: IHeaderSectionProps) => {
  switch (type) {
    case ReportType.CLINICAL: {
      return (
        <View>
          <View style={cls('report-header-container')}>
            <Image
              src="/img/report-header-clinic.png"
              style={cls('header-img')}
            />
            {settings.reportLogo && settings.logoFile && logo ? (
              <Image src={logo} style={cls('company-logo')} />
            ) : (
              <Image src="/img/eyetel-logo.png" style={cls('company-logo')} />
            )}
          </View>

          <View style={cls('flex')}>
            <Text style={cls('h1 report-header-no-logo')}>{title}</Text>
          </View>
        </View>
      );
    }
    case ReportType.PATIENT: {
      return (
        <View>
          <View style={cls('report-header-container')}>
            <Image
              src="/img/report-header-patient.png"
              style={cls('header-img')}
            />
            {settings.reportLogo && settings.logoFile && logo ? (
              <Image src={logo} style={cls('company-logo')} />
            ) : (
              <Image src="/img/eyetel-logo.png" style={cls('company-logo')} />
            )}
          </View>

          <View style={cls('flex')}>
            <Text style={cls('h1 report-header-no-logo')}> {title}</Text>
          </View>
        </View>
      );
    }

    default: {
      return (
        <Text style={cls('p')}>
          Unknown report type: {type}, title: {title}
        </Text>
      );
    }
  }
};

export default HeaderSection;
