import { Image, Text, View } from '@react-pdf/renderer';

import { handleNSI18Next } from 'src/models/Language.model';
import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface IImageSectionProps {
  session: Session;
  type: ReportType;
}

const ImageSection = ({ session, type }: IImageSectionProps) => {
  const { handleReportT } = handleNSI18Next();

  return type === ReportType.CLINICAL ? (
    <>
      <View style={cls('d-flex hstack justify-content-between my-0')}>
        <View style={cls('w-50')}>
          <Text style={cls('px-0 py-2 border-0 semibold')}>
            {handleReportT(`imageSection.OD`)}
          </Text>
        </View>

        <View style={cls('px-3 py-0 border-0')}></View>

        <View style={cls('w-50')}>
          <Text style={cls('px-0 py-2 border-0 semibold')}>
            {handleReportT(`imageSection.OS`)}
          </Text>
        </View>
      </View>

      <View style={cls('d-flex hstack justify-content-between my-0')}>
        <View style={cls('w-50 p-0 border-0 bg-black rounded')}>
          {session.rightImageFile ? (
            <Image src={session.rightImageFile} style={cls('rounded')} />
          ) : (
            <View style={cls('text-center align-middle')}>
              <Text style={cls('p lead text-white m-0')}>
                {handleReportT(`imageSection.noImg`)}
              </Text>
            </View>
          )}
        </View>

        <View style={cls('px-3 py-0 border-0')}></View>

        <View style={cls('w-50 p-0 border-0 bg-black rounded')}>
          {session.leftImageFile ? (
            <Image src={session.leftImageFile} style={cls('rounded')} />
          ) : (
            <View style={cls('text-center align-middle')}>
              <Text style={cls('p lead text-white m-0')}>
                {handleReportT(`imageSection.noImg`)}
              </Text>
            </View>
          )}
        </View>
      </View>
    </>
  ) : (
    <>
      <View style={cls('report-text p-4 mt-2 mb-4')}>
        <Text style={cls('p')}>
          {handleReportT(`imageSection.${type}.reportDisclaimerText`)}
        </Text>
        <Text style={cls('p mb-0 b')}>
          {handleReportT(`imageSection.${type}.contactText`)}
        </Text>
      </View>

      <Text style={cls('p section-text lead')}>
        {handleReportT(`imageSection.${type}.title`)}
      </Text>

      <View
        style={cls('d-flex hstack justify-content-between border-top mb-0')}
      >
        <View style={cls('w-20 border-0')}></View>

        <View style={cls('w-40 border-0 bg-light text-center')}>
          {session.rightImageFile ? (
            <Image src={session.rightImageFile} style={cls('rounded m-1')} />
          ) : (
            <View style={cls('text-center align-middle')}>
              <Text style={cls('p lead m-0')}>
                {handleReportT(`imageSection.noImg`)}
              </Text>
            </View>
          )}
        </View>

        <View style={cls('w-40 border-0 bg-light text-center')}>
          {session.leftImageFile ? (
            <Image src={session.leftImageFile} style={cls('rounded m-1')} />
          ) : (
            <View style={cls('text-center align-middle')}>
              <Text style={cls('p lead m-0')}>
                {handleReportT(`imageSection.noImg`)}
              </Text>
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default ImageSection;
