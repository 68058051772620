import React, { Fragment, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  Pagination,
  Spinner,
  Stack,
  Table,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
  faPlusCircle,
  faSearch,
  faSquareMinus,
  faSquarePlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { Api } from 'src/models/Api.model';
import {
  DeviceDataView,
  ExamData,
} from 'src/models/GalenData/DeviceDataView.model';
import { OrderData, OrderStatusEnum } from 'src/models/GalenData/Order.model';
import { GalenDataUser } from 'src/models/GalenData/User.model';

import useGetRetinalExamDataModelId from 'src/hooks/useGetRetinalExamDataModelConfig';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

import './MainTable.scss';
import Empty from '../custom_components/Empty';

export type DataIndex = string | number | readonly (string | number)[];

export type Key = React.Key;

export interface CellType {
  key?: Key;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  /** Only used for table header */
  hasSubColumns?: boolean;
  colStart?: number;
  colEnd?: number;
}

export interface RenderedCell {
  props?: CellType;
  children?: React.ReactNode;
}

interface NestedColumnTypes<T> {
  title: string;
  key: string;
  dataIndex?: DataIndex;
  render?: (value: any, record?: T, index?: number) => React.ReactNode;
}

interface Column {
  title: string;
  key: keyof GalenDataUser;
}

type NestedDataType = {
  examOrder: {
    exam: DeviceDataView<ExamData>;
    order: DeviceDataView<OrderData>;
  }[];
  order: DeviceDataView<OrderData>[];
};

interface NestedTableProps {
  data: NestedDataType;
  columns: NestedColumnTypes<
    DeviceDataView<OrderData> | DeviceDataView<ExamData>
  >[];
  isCreateEnabled: boolean;
  handleCreateNewOrder: () => void;
  handleJumpToResultsPage: () => void;
}

type PaginationTypes = {
  defaultPageSize: number;
  pageSizeOptions: number[];
  totalPages: number;
  onChangePage: (page: number, pageSize: number) => void;
};

const NestedTable = ({
  data,
  columns,
  handleCreateNewOrder,
  handleJumpToResultsPage,
  isCreateEnabled,
}: NestedTableProps) => {
  return (
    <>
      {
        <Table bordered hover size="sm" className="align-middle">
          <thead>
            <tr>
              {columns.map((col) => (
                <th key={col.key}>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.examOrder &&
              data.examOrder.map((examOrder) => (
                <tr key={examOrder.exam?.deviceDataId}>
                  {columns.map((col) => (
                    <td key={col.key}>
                      {col.render &&
                        col.key === 'actions' &&
                        col.render(undefined, examOrder.exam)}

                      {col.render &&
                        col.key !== 'actions' &&
                        col.render(
                          examOrder.order?.data[col.key as keyof OrderData]
                            .value,
                          examOrder.exam,
                        )}

                      {!col.render &&
                        examOrder.order?.data[col.key as keyof OrderData].value}
                    </td>
                  ))}
                </tr>
              ))}

            {data.order &&
              data.order?.map((row, index) => (
                <tr key={row.data.OrderId.value}>
                  {columns.map((col) => (
                    <td key={col.key}>
                      {col.render &&
                        col.key === 'actions' &&
                        col.render(undefined, row, index)}

                      {col.render &&
                        col.key !== 'actions' &&
                        col.render(
                          row.data[col.key as keyof OrderData].value,
                          row,
                          index,
                        )}

                      {!col.render &&
                        row.data[col.key as keyof OrderData].value}
                    </td>
                  ))}
                </tr>
              ))}

            {data.examOrder.length === 0 && data.order.length === 0 && (
              <tr>
                <td colSpan={20}>No matching records found.</td>
              </tr>
            )}

            <tr>
              <td colSpan={20}>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button
                      variant="light text-optain"
                      className="border p-3"
                      onClick={handleJumpToResultsPage}
                    >
                      Search Results
                    </Button>
                    <Button
                      variant="optain"
                      className="border p-3"
                      disabled={!isCreateEnabled}
                      onClick={handleCreateNewOrder}
                    >
                      Create New order
                    </Button>
                  </ButtonGroup>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </>
  );
};

interface MainTableProps {
  isLoading: boolean;
  dataSource: GalenDataUser[];
  columns: Column[];
  nestedColumns: NestedColumnTypes<
    DeviceDataView<OrderData> | DeviceDataView<ExamData>
  >[];
  pagination: PaginationTypes;
  handleCreateNewOrder: (patientData: GalenDataUser) => void;
  isCreateEnabled: boolean;
}

const MainTable = ({
  isLoading,
  dataSource,
  columns,
  nestedColumns,
  pagination: { pageSizeOptions, totalPages, defaultPageSize, onChangePage },
  handleCreateNewOrder,
  isCreateEnabled,
}: MainTableProps) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [nestedData, setNestedData] = useState<NestedDataType>({
    examOrder: [],
    order: [],
  });

  const [isGettingNestedData, setIsGettingNestedData] = useState(false);

  const { deviceDataModelId } = useGetRetinalExamDataModelId();

  const handleExpandClick = async (userId: string) => {
    try {
      setIsGettingNestedData(true);
      setExpandedRows((prev) => ({ ...prev, [userId]: !prev[userId] }));

      if (!expandedRows[userId]) {
        const [exams, orders] = await Promise.all([
          Api.remote.get_not_hidden_exams({
            deviceDataModelId,
            users: [userId],
          }),
          Api.remote.get_exam_order({
            ownerFilter: {
              users: [userId],
            },
          }),
        ]);

        const result: NestedDataType = {
          examOrder: [],
          order: [],
        };

        if (orders.data.content)
          for (const order of orders.data.content) {
            // Find the related exam for the current order
            const relatedExam = exams.data.content?.find(
              (exam) => exam.data.OrderId?.value === order.data.OrderId?.value,
            );

            if (!relatedExam || !!relatedExam.data.CompletedReason) {
              result.order.push(order);
            } else {
              result.examOrder.push({
                exam: relatedExam,
                order,
              });
            }
          }

        result.order = _.orderBy(result.order, (order) =>
          Object.values(OrderStatusEnum).indexOf(order.data.Status.value),
        );

        setNestedData(result);
      }
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', errorMessage);
    } finally {
      setIsGettingNestedData(false);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    onChangePage(pageNumber, 10);
    setCurrentPage(pageNumber);
  };

  const handleEllipsisClick = (direction: 'start' | 'end') => {
    const delta = 2;
    if (direction === 'start') {
      setCurrentPage(Math.max(1, currentPage - (delta + 1)));
    } else {
      setCurrentPage(Math.min(totalPages, currentPage + (delta + 1)));
    }
  };

  const getPaginationItems = () => {
    const items = [];
    const delta = 2;
    const startPage = Math.max(1, currentPage - delta);
    const endPage = Math.min(totalPages, currentPage + delta);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>,
      );
      if (startPage > 2) {
        items.push(
          <Pagination.Ellipsis
            key="start-ellipsis"
            onClick={() => handleEllipsisClick('start')}
          />,
        );
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>,
      );
    }

    if (endPage < totalPages - 1) {
      items.push(
        <Pagination.Ellipsis
          key="end-ellipsis"
          onClick={() => handleEllipsisClick('end')}
        />,
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>,
      );
    }

    return items;
  };

  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);

  const handlePageSizeChange = (pageSize: number) => {
    setCurrentPageSize(pageSize);
  };

  const history = useHistory();

  return (
    <Stack className="gap-4 shadow">
      <>
        <div style={{ height: 380, overflow: 'auto' }}>
          <Table hover>
            <thead className="position-sticky top-0 start-0 end-0 z-1">
              <tr className="table-light">
                <th></th>
                {columns.map((col) => (
                  <th key={col.key}>
                    <span>{col.title}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={12}>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 400 }}
                    >
                      <Spinner variant="optain" />
                    </div>
                  </td>
                </tr>
              )}
              {!isLoading && dataSource.length > 0 && (
                <>
                  {dataSource.map((row) => (
                    <Fragment key={row.userId}>
                      <tr
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleExpandClick(row.userId);
                        }}
                      >
                        <td align="center">
                          <Button
                            variant="link"
                            className="text-align"
                            style={{ padding: 0 }}
                          >
                            <FontAwesomeIcon
                              icon={
                                expandedRows[row.userId]
                                  ? faSquareMinus
                                  : faSquarePlus
                              }
                            />
                          </Button>
                        </td>
                        {columns.map((col) => (
                          <td key={col.key}>{row[col.key]?.toString()} </td>
                        ))}
                      </tr>
                      {expandedRows[row.userId] && (
                        <tr className="nested-row">
                          <td colSpan={columns.length + 1}>
                            {isGettingNestedData ? (
                              <div
                                className="d-flex justify-content-center align-items-center border border-1 shadow-sm"
                                style={{ height: 100 }}
                              >
                                <Spinner variant="optain" />
                              </div>
                            ) : (
                              <NestedTable
                                data={nestedData}
                                columns={nestedColumns}
                                isCreateEnabled={isCreateEnabled}
                                handleCreateNewOrder={() =>
                                  handleCreateNewOrder(row)
                                }
                                handleJumpToResultsPage={() => {
                                  history.push({
                                    pathname: '/results',
                                    state: { keyword: row.patientId },
                                  });
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </>
              )}
              {!isLoading && dataSource.length === 0 && (
                <tr>
                  <td colSpan={12}>
                    <Empty
                      children={
                        <>
                          <Button
                            variant="outline-secondary"
                            onClick={() => history.push('/results')}
                          >
                            <FontAwesomeIcon icon={faSearch} className="me-2" />
                            Search for Results
                          </Button>
                          <Button
                            variant="optain"
                            type="submit"
                            data-test="session-form-submit"
                            disabled={!isCreateEnabled}
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="me-2"
                            />
                            Create New Patient
                          </Button>
                        </>
                      }
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {!isLoading && dataSource.length > 0 && (
          <div className="d-flex justify-content-center align-items-baseline gap-4">
            <Pagination>
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {getPaginationItems()}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>

            <Form.Group controlId="pageSizeSelect">
              <Form.Control
                as="select"
                style={{ height: 38 }}
                value={currentPageSize}
                onChange={({ currentTarget: { value } }) => {
                  handlePageSizeChange(+value);
                  onChangePage(currentPage, +value);
                }}
              >
                {pageSizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size} / page
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        )}
      </>
    </Stack>
  );
};

export default MainTable;
