import { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { groupBy } from 'lodash';

import useBoundStore from 'src/store/useBoundStore';

import { About, MDRegion } from 'src/models/AboutTab.model';
import { Api } from 'src/models/Api.model';
import { Application } from 'src/models/Application.model';
import { Download, whichDownload } from 'src/models/Downloads.model';
import { FileType } from 'src/models/File.model';
import { DeviceDataValue } from 'src/models/GalenData/DeviceDataValue.model';
import { Product, ProductFullName } from 'src/models/Product.model';
import { isEU, Region, whichRegion } from 'src/models/Region.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

interface IAboutTabProps {
  app: Application;
}

const AboutTab: FC<IAboutTabProps> = ({ app }) => {
  const [showOss, setShowOss] = useState(false);
  const [ossText, setOssText] = useState('');
  const [pageData, setPageData] = useState<PageData>();
  const [manuals, setManuals] = useState<DeviceDataValue<string>[]>([]);
  const [termsOfServices, setTermsOfServices] = useState<
    DeviceDataValue<string>[]
  >([]);
  const [otherFiles, setOtherFiles] = useState<DeviceDataValue<string>[]>([]);
  const [mdRegion, setMdRegion] = useState<MDRegion>('EU');
  const [images, setImages] = useState<DeviceDataValue<string>[]>([]);

  const handleCloseOss = () => setShowOss(false);
  const handleShowOss = () => {
    const OSS_URL = '/THIRD-PARTY-NOTICES.txt';
    if (ossText === '') {
      axios
        .get(OSS_URL)
        .then((res) => {
          setOssText(res.data);
          setShowOss(true);
        })
        .catch((err) => {
          setOssText(`Error: ${err.message}`);
          setShowOss(true);
        });
    } else {
      setShowOss(true);
    }
  };

  interface IfileIcons {
    pdf: IconProp;
    doc: IconProp;
    xls: IconProp;
    [key: string]: IconProp;
  }

  const fileIcons: IfileIcons = {
    pdf: faFilePdf,
    doc: faFileWord,
    xls: faFileExcel,
  };

  interface PageData {
    Disclaimer?: DeviceDataValue<string>;
    ProductVersion?: DeviceDataValue<string>;
    ManufacturerInfo?: DeviceDataValue<string>;
    ManufacturerInfo2?: DeviceDataValue<string>;
    ManufacturerText?: DeviceDataValue<string>;
    Email?: DeviceDataValue<string>;
    Address?: DeviceDataValue<string>;
    Name?: DeviceDataValue<string>;
    PhoneNumber?: DeviceDataValue<string>;
  }

  const DownloadItem = (download: DeviceDataValue<string>) => (
    <li>
      <FontAwesomeIcon
        icon={fileIcons[download?.value.split('.').pop() || ''] || faFile}
        className="me-3 text-dark"
      />
      <a
        href={download?.link}
        target="_blank"
        rel="noreferrer"
        className="text-dark"
      >
        {download?.value}
      </a>
    </li>
  );

  const [isLoading, setIsLoading] = useState(false);

  const { practiceId, product } = useBoundStore();

  const isTeleophth = product === Product.TELEOPHTH;

  useEffect(() => {
    const fetchText = async () => {
      try {
        const data = await Api.remote._get_all_device_data({
          deviceDataModelId: process.env.REACT_APP_ABOUT_PAGE_DATA_MODEL,
          pageNumber: 0,
          pageSize: 1,
          sortBy: ['data.Disclaimer.valueProvidedOn'],
          sortOrder: 'DESC',
        });

        const deviceDataList = data.data.content || [];
        return deviceDataList[0]['data'];
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        Api.alertBox('Error', errorMessage);
      }
    };

    const whichMDRegion = (country: string): MDRegion => {
      if (isEU(country)) return Region.EU;
      switch (country) {
        case Region.US: {
          return Region.US;
        }
        case Region.AU: {
          return Region.AU;
        }
        default: {
          throw new Error('Region not found');
        }
      }
    };

    const fetchRegion = async () => {
      try {
        const practice = await Api.remote.get_practice(practiceId);
        const country = practice.data.contactInfo.country;

        if (country !== undefined) {
          setMdRegion(whichMDRegion(country));
          return whichRegion(country);
        }
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        if (errorMessage) {
          Api.alertBox('Error', errorMessage);
        }
      }
    };

    const fetchFiles = async () => {
      try {
        const data = await Api.remote._get_all_device_data({
          deviceDataModelId:
            process.env.REACT_APP_ABOUT_PAGE_DOWNLOAD_DATA_MODEL ?? '',
        });
        const deviceDataList = data.data.content || [];
        return deviceDataList.map((item) => item.data.File);
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        if (errorMessage) {
          Api.alertBox('Error', errorMessage);
        }
      }
    };

    const processFilename = (item: DeviceDataValue<string>) => {
      const fileSplit = item.value.split(' ');
      const fileType = whichDownload(fileSplit[0]);
      const fileRegion = whichRegion(fileSplit[1]);

      return { ...item, fileType, fileRegion };
    };

    const fetchPage = async () => {
      try {
        const textData = await fetchText();
        if (textData !== undefined) {
          setPageData(textData);
        }

        const regionData = await fetchRegion();

        if (regionData !== undefined) {
          let filesData = await fetchFiles();
          if (filesData !== undefined) {
            const images: DeviceDataValue<string>[] = [];
            filesData = filesData.filter((item) => {
              if (item.value.split(' ')[0] === FileType.Image) {
                images.push(item);
                return false;
              }
              return true;
            });
            const imageLoadPromises = images.map((image) => {
              return new Promise((resolve) => {
                const img = new Image();
                img.src = image.link || '';
                img.addEventListener('load', resolve);
                img.addEventListener('error', resolve);
              });
            });
            await Promise.all(imageLoadPromises);
            setImages(images);
            const processedFileData = filesData.map(processFilename);

            const regionList = processedFileData.filter(
              (item) => item.fileRegion === regionData,
            );

            const groupedFiles = groupBy(regionList, 'fileType');

            const tempManuals = groupedFiles[Download.userManual] || [];
            const tempTermsOfServices =
              groupedFiles[Download.termsOfService] || [];
            const tempOtherFiles = groupedFiles[Download.other] || [];

            setManuals(tempManuals);
            setTermsOfServices(tempTermsOfServices);
            setOtherFiles(tempOtherFiles);
          }
        }
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        if (errorMessage) {
          Api.alertBox('Error', errorMessage);
        }
      }
    };

    setIsLoading(true);
    fetchPage().then(() => setIsLoading(false));
  }, [practiceId]);

  function imgSrc(images: DeviceDataValue<string>[], type: string) {
    if (type === '') return;
    return images.find((image) => image.value.includes(type))?.link;
  }

  return (
    <Container fluid className="main-content">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner className="mx-5 my-5" variant="optain" />
        </div>
      ) : (
        <div>
          <Row>
            <Col>
              <h1 className="lead title my-2">About</h1>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card className="data-container mt-2 p-0">
                <Card.Body>
                  {!isTeleophth && (
                    <>
                      <Row>
                        <Col
                          md={12}
                          style={{ display: 'flex', alignItems: 'top' }}
                        >
                          <div className="me-3">
                            <img
                              src={imgSrc(images, About.IsoMdSymbol)}
                              alt={About.IsoMdSymbol}
                              className="img-fluid"
                              style={{ width: 70, height: 'auto' }}
                            />
                          </div>
                          {mdRegion && (
                            <div className="mt-2" style={{ fontSize: 30 }}>
                              <strong>
                                <em>{About[mdRegion].MDName[product]}</em>
                              </strong>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col
                          md={12}
                          style={{ display: 'flex', alignItems: 'top' }}
                        >
                          <div className="me-3">
                            <img
                              src={imgSrc(images, About.UdiSymbol)}
                              alt={About.UdiSymbol}
                              className="img-fluid"
                              style={{ width: 60, height: 'auto' }}
                            />
                          </div>
                          {mdRegion && (
                            <div>
                              <div className="">
                                <img
                                  src={imgSrc(
                                    images,
                                    About[mdRegion].UdiQrCode || '',
                                  )}
                                  alt={About[mdRegion].UdiQrCode}
                                  className="img-fluid"
                                  style={{ width: 120, height: 'auto' }}
                                />
                              </div>
                              <div style={{ fontSize: 20 }} className="mt-2">
                                {`${About[mdRegion].UdiNumber}${About.ProductVersion}`}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {About[mdRegion].ChRepSymbol && (
                        <Row className="mt-3">
                          <Col
                            md={12}
                            style={{ display: 'flex', alignItems: 'top' }}
                          >
                            <div className="me-3">
                              <img
                                src={imgSrc(
                                  images,
                                  About[mdRegion].ChRepSymbol || '',
                                )}
                                alt={About[mdRegion].ChRepSymbol}
                                className="img-fluid"
                                style={{ width: 90, height: 'auto' }}
                              />
                            </div>
                            <div style={{ lineHeight: 1.2, fontSize: 18 }}>
                              <div>{`${About[mdRegion].ChRepInfoLine1}`}</div>
                              <div>{`${About[mdRegion].ChRepInfoLine2}`}</div>
                              <div>{`${About[mdRegion].ChRepInfoLine3}`}</div>
                              <div>{`${About[mdRegion].ChRepInfoLine4}`}</div>
                              <div>{`${About[mdRegion].ChRepInfoLine5}`}</div>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {About[mdRegion].EcRepSymbol && (
                        <Row className="mt-3">
                          <Col
                            md={12}
                            style={{ display: 'flex', alignItems: 'top' }}
                          >
                            <div className="me-3">
                              <img
                                src={imgSrc(
                                  images,
                                  About[mdRegion].EcRepSymbol || '',
                                )}
                                alt={About[mdRegion].EcRepSymbol}
                                className="img-fluid"
                                style={{ width: 92, height: 'auto' }}
                              />
                            </div>
                            <div style={{ lineHeight: 1.2, fontSize: 18 }}>
                              <div>{`${About[mdRegion].EcRepInfoLine1}`}</div>
                              <div>{`${About[mdRegion].EcRepInfoLine2}`}</div>
                              <div>{`${About[mdRegion].EcRepInfoLine3}`}</div>
                              <div>{`${About[mdRegion].EcRepInfoLine4}`}</div>
                              <div>{`${About[mdRegion].EcRepInfoLine5}`}</div>
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col
                          md={12}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div className="me-3">
                            <img
                              src={imgSrc(images, About.WhiteFactorySymbol)}
                              alt={About.WhiteFactorySymbol}
                              className="img-fluid"
                              style={{ width: 66, height: 'auto' }}
                            />
                          </div>
                          <div className="">
                            <div style={{ fontSize: 20 }} className="">
                              {About.ManufactureDate}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="mt-3">
                    <Col
                      md={12}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div className="me-3">
                        <img
                          src={imgSrc(images, About.BlackFactorySymbol)}
                          alt={About.BlackFactorySymbol}
                          className="img-fluid"
                          style={{ width: 64, height: 'auto' }}
                        />
                      </div>
                      <div className="" style={{ width: 400 }}>
                        <div style={{ fontSize: 17 }} className="">
                          <strong>{About.ManufacturerName}</strong>
                        </div>
                        <div style={{ fontSize: 17 }} className="">
                          {About.ManufacturerAddress}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="data-container mt-2 p-0">
                <Card.Body>
                  <p className="lead ">Product Version</p>
                  <p className="pb-1">{`${About[mdRegion].MDName[product]} v${About.ProductVersion}`}</p>

                  <p className="lead pt-3 border-top">Contact Support</p>
                  <ul className="list-unstyled">
                    <li>{pageData?.Name?.value}</li>
                    <li>
                      <a
                        href={`mailto:${pageData?.Email?.value}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark"
                      >
                        {pageData?.Email?.value}
                      </a>
                    </li>
                    <li>{pageData?.PhoneNumber?.value}</li>
                  </ul>
                  {pageData?.ManufacturerText?.value && (
                    <p className="pt-3 pb-1 border-top">
                      {pageData?.ManufacturerText?.value || null}
                    </p>
                  )}
                  {!isTeleophth && (
                    <Stack gap={3}>
                      <div
                        className="pt-3 border-top"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span className="lead me-2">User Manuals</span>

                        <img
                          src={imgSrc(images, About.UserManualSymbol)}
                          alt={About.UserManualSymbol}
                          className="img-fluid"
                          style={{ width: 25, height: 'auto' }}
                        />
                      </div>

                      <ul className="list-unstyled">
                        {manuals.map((manual) => (
                          <DownloadItem key={manual.reference} {...manual} />
                        ))}
                      </ul>
                    </Stack>
                  )}

                  <p className="lead pt-3 border-top">Terms of Service</p>
                  <ul className="list-unstyled">
                    {termsOfServices.map((termsOfService) => (
                      <DownloadItem
                        key={termsOfService.reference}
                        {...termsOfService}
                      />
                    ))}
                  </ul>

                  {otherFiles && (
                    <>
                      <p className="lead pt-3 border-top">Other Downloads</p>
                      <ul className="list-unstyled">
                        {otherFiles.map((otherFile) => (
                          <DownloadItem
                            key={otherFile.reference}
                            {...otherFile}
                          />
                        ))}
                      </ul>
                    </>
                  )}
                  <p className="pt-3 border-top">
                    This copy of {ProductFullName(app.product)} is registered.
                  </p>
                  <p>
                    <strong>
                      &copy; Eyetelligence {new Date().getFullYear()}. All
                      Rights Reserved.
                    </strong>

                    <a
                      href="#/about"
                      className="ms-2 text-black"
                      onClick={handleShowOss}
                    >
                      Third Party Notices
                    </a>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={showOss}
            onHide={handleCloseOss}
            backdrop="static"
            animation={false}
            keyboard={false}
            centered
            size="xl"
            scrollable={true}
            fullscreen="xl-down"
          >
            <Modal.Header closeButton>
              <Modal.Title>Third Party Notices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <pre>{ossText}</pre>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={handleCloseOss}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Container>
  );
};

export default AboutTab;
