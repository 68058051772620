import { Dispatch, SetStateAction, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  ProgressBar,
  Row,
  Stack,
} from 'react-bootstrap';

import {
  faBarcode,
  faBattery,
  faChargingStation,
  faCode,
  faLinkSlash,
  faPowerOff,
  faRefresh,
  faSearch,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useBoundStore from 'src/store/useBoundStore';

import ConnectCameraList from 'src/components/custom_components/ConnectCameraList';
import CustomCard from 'src/components/custom_components/CustomCard';
import ManualConnectCamera from 'src/components/custom_components/ManualConnectCamera';
import { VolumeControl } from 'src/components/custom_components/VolumeControl';

import { Api } from 'src/models/Api.model';
import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { CameraMode } from 'src/models/CameraMode.model';
import { Product } from 'src/models/Product.model';

const convertToByte = (text: string | undefined) => {
  if (text === undefined) {
    return;
  }

  if (text.endsWith('G')) {
    return Number.parseInt(text.slice(0, -1)) * 1024 * 1024 * 1024;
  } else if (text.endsWith('M')) {
    return Number.parseInt(text.slice(0, -1)) * 1024 * 1024;
  } else if (text.endsWith('K')) {
    return Number.parseInt(text.slice(0, -1)) * 1024;
  } else {
    return Number.parseInt(text);
  }
};

interface CameraSettingsProps {
  cameraMode: CameraMode;
  setCameraMode: Dispatch<SetStateAction<CameraMode>>;
  ipList: string[];
  setIpList: Dispatch<SetStateAction<string[]>>;
}

const CameraSettings = ({
  cameraMode,
  setCameraMode,
  ipList,
  setIpList,
}: CameraSettingsProps) => {
  const { setCameraData, cameraData, product } = useBoundStore();

  const [scanning, setScanning] = useState(false);

  const [scanTime, setScanTime] = useState(0);
  const [hasNotFoundCameras, setHasNotFoundCameras] = useState(false);
  const [hasCameraServerError, setHasCameraServerError] = useState(false);

  const reloadCameraData = () => {
    CameraApi.reload((err, res) => {
      if (err === undefined && res !== false) {
        setCameraData(res);
      }
    });
  };

  const disconnectCamera = () => {
    Api.confirmBox({
      title: 'Confirm',
      message: 'Are you sure you want to disconnect this camera?',
      callback: (yes) => {
        if (yes !== true) {
          return;
        }

        setCameraData(undefined);
      },
    });
  };

  const clearStorage = () => {
    Api.confirmBox({
      title: 'Confirm',
      message: 'Are you sure to clear storage in this camera?',
      callback: (yes) => {
        if (yes !== true) {
          return;
        }

        CameraApi.clearStorage((err, res) => {
          if (err === undefined && res !== false) {
            // ignore clear storage response

            // reload camera data
            reloadCameraData();
          }
        });
      },
    });
  };

  const powerOffCamera = () => {
    Api.confirmBox({
      title: 'Confirm',
      message: 'Are you sure to power off this camera?',
      callback: async (yes) => {
        if (yes !== true) {
          return;
        }

        setCameraData(undefined);
        setIpList([]);

        // Whenever we call this endpoint, the camera will throw an error.
        CameraApi.powerOff();
      },
    });
  };

  const scanCameras = () => {
    setIpList([]);

    setCameraData(undefined);

    setScanning(true);

    let time = -1;
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    const countTime = () => {
      time++;
      setScanTime(time);
      if (time < 256) {
        timeoutId = setTimeout(countTime, 0.15 * 1000);
      }
    };

    countTime();

    Api.getLocalIpAddress((err: any, localIP: string) => {
      if (err != null) {
        return Api.alertBox('Error', err.toString(), () => {
          setScanning(false);
          clearTimeout(timeoutId);

          setHasNotFoundCameras(false);
          setHasCameraServerError(false);
        });
      }

      CameraApi.scan(localIP, (err, res) => {
        setScanning(false);
        clearTimeout(timeoutId);

        if (!!err || res === false) {
          return setHasCameraServerError(true);
        }

        if (res.length === 0) {
          return setHasNotFoundCameras(true);
        }

        setIpList(res as string[]);
        setHasNotFoundCameras(false);
        setHasCameraServerError(false);
      });
    });
  };

  return (
    <div className="d-flex flex-column gap-4 pt-4">
      {product !== Product.TELEOPHTH && (
        <Form.Group>
          <Form.Check
            type="radio"
            name="camera-mode"
            id="camera-mode-upload"
            label="Manual File Upload"
            checked={cameraMode === CameraMode.UPLOAD}
            onChange={() => setCameraMode(CameraMode.UPLOAD)}
          />

          <Form.Check
            className="pt-3"
            type="radio"
            name="camera-mode"
            id="camera-mode-api"
            label="Camera Connection"
            checked={cameraMode === CameraMode.API}
            onChange={() => setCameraMode(CameraMode.API)}
          />
        </Form.Group>
      )}

      {cameraMode === CameraMode.API && (
        <Card body className="mt-2">
          {cameraData ? (
            <Row className="d-flex justify-content-between">
              <Col className="d-flex flex-column align-items-start border-end">
                <Badge bg="light" text="success">
                  CONNECTED
                </Badge>
                <p className="lead mt-2">
                  {cameraData.model} ({cameraData.ip})
                </p>
                <p>Optain Automated Fundus Camera</p>
                <div>
                  <Badge bg="primary" style={{ lineHeight: '32px' }}>
                    <FontAwesomeIcon
                      icon={faBarcode}
                      className="ms-1 me-2"
                    ></FontAwesomeIcon>
                    {cameraData?.sn || '-'}
                  </Badge>
                </div>

                <div className="mt-2">
                  <Badge bg="primary" style={{ lineHeight: '32px' }}>
                    <FontAwesomeIcon
                      icon={faCode}
                      className="ms-1 me-2"
                    ></FontAwesomeIcon>
                    {cameraData?.version || '-'}
                  </Badge>
                </div>

                <div className="mt-2">
                  <Badge bg="primary" style={{ lineHeight: '32px' }}>
                    <FontAwesomeIcon
                      icon={faBattery}
                      className="ms-1 me-2"
                    ></FontAwesomeIcon>
                    {cameraData && cameraData.battery
                      ? `${cameraData.battery}%`
                      : '-'}
                    {cameraData && cameraData.charging && (
                      <FontAwesomeIcon
                        icon={faChargingStation}
                        className="ms-1 me-1"
                      ></FontAwesomeIcon>
                    )}
                  </Badge>
                </div>

                <Stack direction="horizontal" className="mt-5" gap={2}>
                  <VolumeControl />

                  <Button
                    variant={cameraData ? 'dark' : 'outline-secondary'}
                    className="ms-2 shadow-lg scale-up d-flex flex-column align-items-center justify-content-center"
                    style={{
                      width: 120,
                      height: 120,
                      borderRadius: 60,
                    }}
                    onClick={reloadCameraData}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                    Reload
                  </Button>

                  {cameraData && (
                    <Button
                      variant="dark"
                      className="ms-2 shadow-lg scale-up d-flex flex-column align-items-center justify-content-center"
                      style={{
                        width: 120,
                        height: 120,
                        borderRadius: 60,
                      }}
                      onClick={disconnectCamera}
                    >
                      <FontAwesomeIcon icon={faLinkSlash} />
                      Disconnect
                    </Button>
                  )}
                </Stack>
              </Col>

              <Col className="d-flex flex-column">
                <ProgressBar
                  now={convertToByte(cameraData?.storage.used)}
                  max={convertToByte(cameraData?.storage.total)}
                  variant="primary"
                  striped
                  animated
                  style={{ height: 42, marginTop: 130 }}
                />
                <div className="text-center mt-2">
                  <p>
                    <b>Storage:</b>{' '}
                    {cameraData
                      ? `${cameraData.storage.used} / ${cameraData.storage.total}`
                      : '-'}
                  </p>
                </div>
                <div
                  className="d-flex justify-content-center gap-2"
                  style={{ marginTop: 90 }}
                >
                  <Button
                    variant={cameraData ? 'dark' : 'outline-secondary'}
                    className="ms-2 shadow-lg scale-up d-flex flex-column align-items-center justify-content-center "
                    style={{
                      width: 120,
                      height: 120,
                      borderRadius: 60,
                    }}
                    onClick={clearStorage}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    Clear
                  </Button>

                  <Button
                    variant={cameraData ? 'dark' : 'outline-secondary'}
                    className="ms-2 shadow-lg scale-up d-flex flex-column align-items-center justify-content-center "
                    style={{
                      width: 120,
                      height: 120,
                      borderRadius: 60,
                    }}
                    onClick={powerOffCamera}
                  >
                    <FontAwesomeIcon icon={faPowerOff} />
                    Power
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="d-flex flex-column">
              <p className="lead mt-2">Available cameras</p>

              <p>Scan cameras in your local network.</p>

              {hasCameraServerError && !scanning && (
                <CustomCard
                  imgSrc="/img/Camera-server-not-started.jpg"
                  cardTitle="Camera server error"
                />
              )}

              {hasNotFoundCameras && !scanning && (
                <CustomCard
                  imgSrc="/img/no-cameras-found.jpg"
                  cardTitle="No cameras found"
                />
              )}

              <ConnectCameraList ipList={ipList} />

              {scanning ? (
                <Alert variant="info" className="text-center w-50">
                  <ProgressBar
                    now={scanTime}
                    max={256}
                    variant="optain"
                    className="mb-3"
                  />
                  Scanning...
                </Alert>
              ) : (
                <div
                  className="d-flex justify-content-between gap-4 mt-3"
                  style={{ width: '30rem' }}
                >
                  <Button onClick={scanCameras} variant="optain">
                    <FontAwesomeIcon icon={faSearch} className="me-2" />
                    Automatic scan
                  </Button>
                  <ManualConnectCamera
                    variant="optain"
                    isScanning={scanning}
                    connectButtonProps={{ variant: 'optain' }}
                  />
                </div>
              )}
            </div>
          )}
        </Card>
      )}
    </div>
  );
};

export default CameraSettings;
