import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { CameraSliceTypes, createCameraSlice } from './createCameraSlice';
import createSelectors from './createSelectors';
import { AppSliceTypes, createUserSlice } from './createUserSlice';

const useBoundStoreBase = create<AppSliceTypes & CameraSliceTypes>()(
  immer(
    devtools((...a) => ({
      ...createUserSlice(...a),
      ...createCameraSlice(...a),
    })),
  ),
);

const useBoundStore = createSelectors(useBoundStoreBase);

export default useBoundStore;
