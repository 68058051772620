import { Button, Card } from 'react-bootstrap';

import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useBoundStore from 'src/store/useBoundStore';

import { CameraApi } from 'src/models/Camera/CameraApi.model';

export type ConnectCameraListProps = {
  ipList: string[];
};

export default function ConnectCameraList({ ipList }: ConnectCameraListProps) {
  const { setCameraData } = useBoundStore();
  const handleConnectCamera = (ip: string) => {
    CameraApi.connect(ip, (err, res) => {
      if (err === undefined && res !== false) {
        setCameraData(res);
      }
    });
  };

  return (
    <div className="d-flex flex-wrap gap-4">
      {ipList.map((ip) => (
        <Card
          key={ip}
          border="dark"
          className="p-3 mb-4"
          style={{ borderRadius: 20, borderWidth: 2, width: '30rem' }}
        >
          <div className="d-flex justify-content-between">
            <div className="lead">
              <p className="mt-2">OPTFC01</p>
              <p>({ip})</p>
            </div>
            <div>
              <img
                src="/img/optain-camera.png"
                style={{ width: 120 }}
                alt="camera"
              />
            </div>
          </div>

          <Button
            variant="dark"
            style={{ width: '16rem' }}
            onClick={() => {
              handleConnectCamera(ip);
            }}
          >
            <FontAwesomeIcon icon={faLink} className="me-2" />
            Connect
          </Button>
        </Card>
      ))}
    </div>
  );
}
