import { Text, View } from '@react-pdf/renderer';

import { handleNSI18Next } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';

import { cls, SCALE } from 'src/utils/reactPdfStyles';

interface INotesSectionProps {
  session: Session;
}

const NotesSection = ({ session }: INotesSectionProps) => {
  const { handleReportT } = handleNSI18Next();

  return (
    <>
      <Text style={cls('p section-text lead mt-5')}>
        {handleReportT('notesSection.title')}
      </Text>

      <View style={cls('border-top', { height: 320 * SCALE })}>
        <Text style={cls('p mt-2')}>{session.notes || ''}</Text>
      </View>
    </>
  );
};

export default NotesSection;
