import { Document, Image, Page } from '@react-pdf/renderer';

import Watermark from 'src/components/custom_components/CustomWatermark';

import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import { cls } from 'src/utils/reactPdfStyles';

import MainContent from './MainContent';

interface IOptainReportProps {
  session: Session;
  settings: Settings;
}

const OptainReport = ({ session, settings }: IOptainReportProps) => {
  return (
    <Document>
      <Page size="A4" style={cls('body')}>
        <Image src="/img/optain-bg.png" style={cls('optain_bg-report')} />

        <MainContent session={session} />

        <Watermark text={settings.watermark} />
      </Page>
    </Document>
  );
};

export default OptainReport;
