import { Image, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { Disease, DiseaseName } from 'src/models/Disease.model';
import { Grading } from 'src/models/Grading.model';
import { Session } from 'src/models/Session.model';

import getGradingResult from 'src/utils/getGradingResult';
import { cls, SCALE } from 'src/utils/reactPdfStyles';

import ImageAnalysis from './ImageAnalysis';
import {
  OverallInsufficientResultBadge,
  OverallNegativeResultBadge,
  OverallPositiveResultBadge,
} from './OverallEyeResultBadge';

interface MainContentProps {
  session: Session;
}

const separateDrResults = (session: Session) => {
  // convert single dr output to multiple mtmDR and vtDR outputs

  const newSession = structuredClone(session);

  const convertResults = (results: any) => {
    if (!results) {
      return;
    }

    const newResults = [];
    for (const result of results) {
      if (result.disease === Disease.DR) {
        const dr = result;
        const mtmDR = structuredClone(dr);
        const vtDR = structuredClone(dr);

        mtmDR.disease = Disease.MTMDR;
        vtDR.disease = Disease.VTDR;

        mtmDR.grading =
          dr.grading === Grading.MTMDR || dr.grading === Grading.VTDR
            ? Grading.POSITIVE
            : Grading.NEGATIVE;
        vtDR.grading =
          dr.grading === Grading.VTDR ? Grading.POSITIVE : Grading.NEGATIVE;

        newResults.push(mtmDR, vtDR);
      } else {
        newResults.push(result);
      }
    }

    return newResults;
  };

  newSession.leftResults = convertResults(session.leftResults);
  newSession.rightResults = convertResults(session.rightResults);

  return newSession;
};

const MainContent = ({ session }: MainContentProps) => {
  const newSession = separateDrResults(session);

  const { getPositiveResultDiseases, hasUngradableResult } =
    getGradingResult(newSession);

  const positiveResultDiseases = getPositiveResultDiseases([
    Disease.MTMDR,
    Disease.VTDR,
  ]);

  const hasMoreThanTwoDiseases = positiveResultDiseases.length >= 2;

  const hasPositiveResult = positiveResultDiseases.length > 0;

  return (
    <View style={cls('px-5 py-4 vstack gap-5')}>
      <View style={cls('text-end')}>
        <Image
          src="/icons/logo/optain.png"
          style={cls('ms-auto', { width: 142 * SCALE, height: 60 * SCALE })}
        ></Image>
      </View>

      <View
        style={cls('d-flex hstack justify-content-between align-items-center')}
      >
        <View style={cls('h2 text-optain')}>
          <Text>Diabetic Retinopathy Analysis</Text>
        </View>
        <View style={cls('hstack gap-2')}>
          {hasPositiveResult ? (
            positiveResultDiseases.map((disease) => (
              <OverallPositiveResultBadge
                children={<Text>{DiseaseName(disease)}</Text>}
                key={disease}
              />
            ))
          ) : hasUngradableResult ? (
            <OverallInsufficientResultBadge />
          ) : (
            <OverallNegativeResultBadge />
          )}
        </View>
      </View>

      <View
        style={cls(
          'py-2 border-top border-bottom d-flex hstack justify-content-between',
        )}
      >
        <View>
          <Text style={cls('text-muted p-1 bold')}>PATIENT NAME</Text>
          <Text style={cls('lead p-1')}>
            {session.lastName}, {session.firstName}
          </Text>
        </View>
        <View>
          <Text style={cls('text-muted p-1 bold')}>PATIENT MRN</Text>
          <Text style={cls('p-1')}>{session.id}</Text>
        </View>
        <View>
          <Text style={cls('text-muted p-1 bold')}>PATIENT DOB</Text>
          <Text style={cls('p-1')}>{session.dob}</Text>
        </View>
        <View>
          <Text style={cls('text-muted p-1 bold')}>DATE</Text>
          <Text style={cls('p-1')}>{session.date}</Text>
        </View>
        <View>
          <Text style={cls('text-muted p-1 bold')}>TIME</Text>
          <Text style={cls('p-1')}>
            {moment(session.time, 'HH:mm').format('HH:mm')}
          </Text>
        </View>
      </View>

      <View style={cls('gap-2')}>
        <Text style={cls('h4 text-optain')}>Next Steps</Text>

        {hasPositiveResult ? (
          <View
            style={cls('alert bg-danger text-white p-4 mb-0', {
              border: 0,
              borderLeft: '6px solid #3EC8AE',
            })}
          >
            <Text>
              {hasMoreThanTwoDiseases
                ? 'Refer to an eye care provider for evaluation (with preferential scheduling if possible).'
                : 'Refer to an eye care provider for evaluation.'}
            </Text>
          </View>
        ) : hasUngradableResult ? (
          <View
            style={cls('alert bg-secondary-700 text-white p-4 mb-0', {
              border: 0,
              borderLeft: '6px solid #3EC8AE',
            })}
          >
            <Text>
              Refer patient to eye care provider for diabetic retinopathy exam.
            </Text>
          </View>
        ) : (
          <View
            style={cls('alert bg-primary-600 text-white p-4 mb-0', {
              border: 0,
              borderLeft: '6px solid #3EC8AE',
            })}
          >
            <Text>Re-test for diabetic retinopathy in 12 months.</Text>
          </View>
        )}
      </View>

      <ImageAnalysis session={newSession} />

      <View style={cls('vstack gap-2')}>
        <Text style={cls('h4 text-optain')}>Condition Details</Text>

        <Text style={cls('p')}>
          <Text style={cls('bb')}>Diabetic retinopathy (DR)</Text> is a diabetes
          type 1 and 2 complication that affects eyes. It’s caused by damage to
          the blood vessels of the retina. It can cause vision problems and lead
          to blindness<Text style={cls('sup')}>1</Text>.
        </Text>

        <Text style={cls('p')}>
          <Text style={cls('bb')}>
            More-than-mild diabetic retinopathy (mtmDR)
          </Text>{' '}
          is defined as moderate or severe non-proliferative DR, proliferative
          DR, and/or diabetic macular edema.
        </Text>

        <Text style={cls('p')}>
          <Text style={cls('bb')}>
            Vision threatening diabetic retinopathy (vtDR)
          </Text>{' '}
          is defined as severe nonproliferative DR or proliferative DR and/or
          diabetic macular edema.
        </Text>

        <Text style={cls('p text-muted')}>
          1. “Diabetic Retinopathy.” National Eye Institute, U.S. Department of
          Health and Human Services, 8 July 2022,
          https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/diabetic-retinopathy.
        </Text>
      </View>
    </View>
  );
};

export default MainContent;
