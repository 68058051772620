import { useCallback, useState } from 'react';

import { Api } from 'src/models/Api.model';
import { EntityCriteriaOperatorEnum } from 'src/models/GalenData/EntityCriteria.model';
import { PageOfUser } from 'src/models/GalenData/PageOfUser.model';
import { UserRoleEnum } from 'src/models/GalenData/UserRole.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

export default function useGetUsers() {
  const [users, setUsers] = useState<PageOfUser>({});

  const fetchUsersByRole = async (
    role: UserRoleEnum,
    pageNumber: number,
    pageSize: number,
  ): Promise<PageOfUser> => {
    const response = await Api.remote._get_users_advanced(
      [
        {
          key: 'role',
          operator: EntityCriteriaOperatorEnum.NotEqual,
          value: role,
        },
      ],
      {
        pageNumber,
        pageSize,
        sortBy: 'createdOn',
        sortOrder: 'DESC',
      },
    );

    return response.data;
  };

  const [isGettingUsers, setIsGettingUsers] = useState(false);

  const handleGetUsers = useCallback(
    async (pageNumber: number = 0, pageSize: number = 10) => {
      try {
        setIsGettingUsers(true);
        const patientUserList = await fetchUsersByRole(
          UserRoleEnum.Patient,
          pageNumber,
          pageSize,
        );

        setUsers(patientUserList);
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        Api.alertBox('Error', `Failed to load users. ${errorMessage}`);
      } finally {
        setIsGettingUsers(false);
      }
    },
    [],
  );

  return { users, handleGetUsers, isGettingUsers };
}
