import { AxiosResponse, isAxiosError } from 'axios';
import Sentry from 'src/services/sentry';

import { getErrorMessage } from 'src/models/GalenData/helpers/getErrorMessage';

const handleHttpRequestError = (err: unknown): string => {
  if (!isAxiosError(err)) {
    // Non-Axios error
    return `${err}`;
  }

  const { response, request, message } = err;

  if (response) {
    return handleErrorResponse(response);
  }

  if (request) {
    // Request was made but no response was received
    return 'Network error: Please check your internet connection.';
  }

  Sentry.captureException(err);

  // Something happened in setting up the request that triggered an Error
  return `Error: ${message}`;
};

const handleErrorResponse = (response: AxiosResponse): string => {
  const formatErrorMessage = extractErrorMessage(response.data);
  return getErrorMessage(formatErrorMessage);
};

const extractErrorMessage = (data: string): string => {
  const errorMessageParts = data.split(' ');
  return errorMessageParts.length > 1 ? errorMessageParts[1] : data;
};

export default handleHttpRequestError;
