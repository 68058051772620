import { Alert } from 'react-bootstrap';

import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSWR from 'swr';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { SWRKeysEnum } from 'src/models/SWRKeys.model';

const CameraConnectivity = () => {
  const { error } = useSWR(
    SWRKeysEnum.GET_CAMERA_CONNECTIVITY,
    () => CameraApi.getConnectivity(),
    {
      refreshInterval: 2000,
      onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
        setTimeout(() => revalidate({ retryCount }), 2000);
      },
    },
  );

  return (
    <Alert
      variant={error ? 'danger' : 'success'}
      className="ms-3 me-0 my-0"
      style={{ padding: 11.5 }}
    >
      <b className="me-2">
        <FontAwesomeIcon
          icon={error ? faLinkSlash : faLink}
          className="me-2"
        ></FontAwesomeIcon>
      </b>
      Camera
    </Alert>
  );
};

export default CameraConnectivity;
