import { DeviceDataValue } from './GalenData/DeviceDataValue.model';
import {
  BackendStatus,
  GlobalStatus,
  ModelStatus,
} from './GalenData/DeviceDataView.model';

const defaultModelStatus: DeviceDataValue<ModelStatus> = {
  value: ModelStatus.NA,
};

export const defaultBackendStatus: BackendStatus = {
  GlobalStatus: { value: GlobalStatus.Null },
  SessionId: { value: '' },
  DrusStatus: defaultModelStatus,
  DreuStatus: defaultModelStatus,
  AmdeuStatus: defaultModelStatus,
  GlaucomaeuStatus: defaultModelStatus,
  CvdeuStatus: defaultModelStatus,
  CvdauStatus: defaultModelStatus,
  AmdeuSmallStatus: defaultModelStatus,
  DreuSmallStatus: defaultModelStatus,
  GlaucomaeuSmallStatus: defaultModelStatus,
  CvdeuSmallStatus: defaultModelStatus,
  CvdauSmallStatus: defaultModelStatus,
  VesselSegStatus: defaultModelStatus,
  GeneralQualityStatus: defaultModelStatus,
};
