import { PracticeAssociation } from './PracticeAssociation.model';
import { SupplierAssociation } from './SupplierAssociation.model';

/**
 * Represents the role of a user.
 */
export interface UserRole {
  createdBy?: string;
  createdOn?: Date;
  defaultRole?: boolean;
  practice?: PracticeAssociation;
  role?: UserRoleEnum;
  supplier?: SupplierAssociation;
}

/**
 * Enumerates the possible roles for a user.
 */
export enum UserRoleEnum {
  Guest = 'Guest',
  Patient = 'Patient',
  PracticeAdmin = 'PracticeAdmin',
  PracticeUser = 'PracticeUser',
  SupplierAdmin = 'SupplierAdmin',
  SupplierUser = 'SupplierUser',
  TenantAdmin = 'TenantAdmin',
  TenantUser = 'TenantUser',
}

export const adminRoleList = [
  UserRoleEnum.TenantAdmin,
  UserRoleEnum.PracticeAdmin,
  UserRoleEnum.SupplierAdmin,
];
