import { ReactNode } from 'react';
import { Toast, ToastContainer, ToastProps } from 'react-bootstrap';
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CustomToastProps = ToastProps & {
  children: string | ReactNode;
  containerClassName?: string;
  position?: ToastPosition;
  isShow: boolean;
  handleToggleShowToast: (isShow: boolean) => void;
};

export default function CustomToast({
  containerClassName = 'p-3',
  position = 'top-center',
  children,
  handleToggleShowToast,
  isShow,
  ...props
}: CustomToastProps) {
  return (
    <ToastContainer
      className={containerClassName}
      position={position}
      style={{ zIndex: 9999 }}
    >
      <Toast
        onClose={() => handleToggleShowToast(false)}
        show={isShow}
        delay={5000}
        autohide
        bg="warning"
        {...props}
      >
        <Toast.Body className="d-flex justify-content-between align-items-center">
          {children}
          <FontAwesomeIcon
            icon={faClose}
            onClick={() => handleToggleShowToast(false)}
            style={{ cursor: 'pointer' }}
          />
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
