import * as Sentry from '@sentry/react';
import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment';

import { Api } from 'src/models/Api.model';
import { CameraData } from 'src/models/CameraData.model';

import { GetLanguagesResponse } from './Settings/Language/GetLanguages';
import { SetLanguageParams } from './Settings/Language/SetLanguage';
import { AutoStateResponse } from './Shooting/AutoState.model';
import { GetSettingsResponse, SettingsObj } from './Shooting/GetSettings.model';
import { GetBatteryInfoResponse } from './System/GetBatteryInfo';

// Camera API
// pure frontend implementation

// disable retries for standard axios requests
axiosRetry(axios, { retries: 0 });

// enable timeout and retries for camera-specific axios requests
const MAX_RETRIES = 3; // total: 1 initial attempt + 3 retries

const REQUEST_TIMEOUT_RETRY_CONF = {
  timeout: 5000,
  withCredentials: false,
  'axios-retry': {
    retries: MAX_RETRIES,
    shouldResetTimeout: true,
    retryCondition: () => true, // retry under all error conditions
    retryDelay: () => 1000, // delay 1 sec
  },
};

const STOP_PREVIEW = false;

interface ExamData {
  id: string;
  status: string;
  leftImage: string | undefined;
  rightImage: string | undefined;
}

const CAMERA = {
  model: '',
  version: '',
  manufacture: '',
  sn: '',
  storage: {
    used: '',
    total: '',
  },
};

const CAMERA_SIMULATOR_IP = '127.0.0.1';
const CAMERA_STATIC_IP = '192.168.3.10';

let CAMERA_IP = '';
let CAMERA_STATUS = ''; // idle, shooting-od, shooting-os, finished
let CAMERA_BATTERY = 0;
let CAMERA_CHARGING = false;

let EXAM_ID = '';
let EXAM_FILENAME: string | undefined;

let URL_OD = '';
let URL_OS = '';

let BATTERY_ALERT = true;

const CAMERA_ERROR_MESSAGE =
  'Unable to send command to the camera. Please try again.';

type callbackFunction = (err?: Error, ...params: any[]) => void;

const startSseClient = () => {
  const url = `http://${CAMERA_IP}:8901/sse`;
  console.log(`Start SSE Client: ${url}`);

  const evtSource = new EventSource(url);

  evtSource.addEventListener('battery', function (event) {
    // {"battCap":71,"battCurrent":-484,"battStatus":"Discharging"}
    // {'battCap': 65, 'battCurrent': 1424, 'battStatus': 'Charging'}
    // {"code":-1,"message":"Get Faile"}
    console.log('< recv SSE message:', event.data);

    const data = JSON.parse(event.data);

    if (data.battCap !== undefined && CAMERA_BATTERY !== data.battCap) {
      Sentry.captureMessage(`Camera Battery: ${data.battCap}`);
      CAMERA_BATTERY = data.battCap;
    }

    if (data.battStatus !== undefined) {
      const charging = data.battCurrent >= 0;
      if (CAMERA_CHARGING !== charging) {
        CAMERA_CHARGING = charging;
      }

      if (!charging && BATTERY_ALERT) {
        Sentry.captureMessage('Camera is not charging.');

        BATTERY_ALERT = false;
      } else if (charging) {
        Sentry.captureMessage('Camera is charging.');
      }
    }
  });
};

const attemptIPAddress = async (ip: string) => {
  // try to get camera version
  const url = `http://${ip}:8901/getVersions`;
  await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);

  Sentry.captureMessage(`Camera IP Address found: ${ip}`);
  console.log(`[  OK  ] ${ip}`);
  return ip;
};

const scanCameras = async (ip: string) => {
  try {
    Sentry.captureMessage(`Scanning cameras from my IP: ${ip}`);
    console.log(`Scanning cameras from my IP: ${ip}`);

    const ipList = Array.from(
      { length: 254 }, // 1..254
      (_, i) => `${ip.slice(0, Math.max(0, ip.lastIndexOf('.') + 1))}${i + 1}`,
    );
    // add camera simulator IP Address
    if (!ipList.includes(CAMERA_SIMULATOR_IP)) {
      ipList.unshift(CAMERA_SIMULATOR_IP);
    }

    // add camera static IP Address in direct connection mode
    if (!ipList.includes(CAMERA_STATIC_IP)) {
      ipList.unshift(CAMERA_STATIC_IP);
    }

    // add previously connected camera IP Address
    const previousCameraIP = window.sessionStorage.getItem('cameraIP');
    if (previousCameraIP != null && !ipList.includes(previousCameraIP)) {
      ipList.unshift(previousCameraIP);
    }

    const promiseList = ipList.map(attemptIPAddress);

    const results = await Promise.allSettled(promiseList);

    const fulfilledResults = results.reduce((pre, curItem, curIndex) => {
      if (curItem.status === 'fulfilled') {
        pre.push(ipList[curIndex]);
      }
      return pre;
    }, [] as string[]);

    Sentry.captureMessage(`Scan result: ${fulfilledResults.join(', ')}`);
    return fulfilledResults;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

const getBatteryInfo = async () => {
  try {
    Sentry.captureMessage('Get battery info');
    console.log('Get battery info');

    const url = `http://${CAMERA_IP}:8901/getBatteryInfo`;
    const batteryInfo = await axios.get<GetBatteryInfoResponse>(
      url,
      REQUEST_TIMEOUT_RETRY_CONF,
    );

    CAMERA_BATTERY = batteryInfo.data.obj.battCap;
    CAMERA_CHARGING = batteryInfo.data.obj.battCurrent >= 0;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

const connectCamera = async (ip: string) => {
  try {
    CAMERA_IP = ip;
    Sentry.captureMessage(`Connecting to camera ${ip}`);
    console.log(`Connecting to camera ${ip}`);

    CAMERA.manufacture = 'Optain Health';
    CAMERA.model = 'OPTFC01';

    const versionUrl = `http://${ip}:8901/getVersions`;
    const snUrl = `http://${ip}:8901/dev/DeviceSN`;

    const versionRes = await axios.get(versionUrl, REQUEST_TIMEOUT_RETRY_CONF);
    const snRes = await axios.get(snUrl, REQUEST_TIMEOUT_RETRY_CONF);

    // {"message":"OK","obj":{"version":"...","company":"...","model":"...","logoname":"..."}}
    CAMERA.version = versionRes.data.obj.version;

    // # {"error_code":0,"msg":"OK","data":"..."}
    CAMERA.sn = snRes.data.data;

    // get storage
    await getStorage();

    // get battery info
    await getBatteryInfo();

    // close dialog if any
    // TODO: power off doesn't work if the transport dialog is closed via API
    // TODO: needs to find another solution
    // if (false) {
    //   await closeTransportDialog();
    // }

    // reset camera if not idle and not finished
    let status = await getStatus();
    if (status !== 'idle' && status !== 'finished') {
      await cancelExam();

      // console.log("waiting for idle, finished...");
      status = await getStatus();

      while (status !== 'idle') {
        // delay 1 second
        await new Promise((resolve) => setTimeout(resolve, 1000));
        status = await getStatus();
      }
    }

    // sync time
    // await setSystemDateTime();

    if (STOP_PREVIEW) {
      // disable preview record
      await disablePreviewRecord();
    }

    // listen to server events
    startSseClient();

    // remember IP Address
    window.sessionStorage.setItem('cameraIP', CAMERA_IP);

    const data = {
      ...CAMERA,
      ip,
      battery: CAMERA_BATTERY,
      charging: CAMERA_CHARGING,
    };

    Sentry.captureMessage(`Camera connected: ${JSON.stringify(data)}`);

    return data;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

// ---- operations ----
// const closeTransportDialog = async () => {
//   Sentry.captureMessage('Close transport dialog');
//   console.log('Close transport dialog');
//
//   try {
//     const url = `http://${CAMERA_IP}:8901/dev/ErrorInfo?cn=true`;
//     const res = await axios.get(url);
//     console.log('>', res.data);
//     console.log();
//   } catch (err) {
//     Sentry.captureException(err);
//     Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
//     throw err;
//   }
//
//   return true;
// };

const startExam = async () => {
  try {
    Sentry.captureMessage('Start exam');
    console.log('Start exam');

    const filename = 'Photo_' + moment().format('YYYYMMDDHHmmss'); // default: Photo_YYYYMMDDHHmmss
    const url = `http://${CAMERA_IP}:8901/dev/AutoTakePhoto?filename=${filename}`;

    const res = await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);

    EXAM_FILENAME = filename;

    Sentry.captureMessage(`Exam filename: ${EXAM_FILENAME}`);

    console.log('res.data:', res.data);
    console.log('EXAM_FILENAME:', EXAM_FILENAME);
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const skipEye = async () => {
  try {
    Sentry.captureMessage(`Skip eye`);
    console.log('Skip eye');

    const url = `http://${CAMERA_IP}:8901/dev/ignore?ignore=false`;
    const res = await axios.get(url, {
      ...REQUEST_TIMEOUT_RETRY_CONF,
      timeout: 10_000,
    });
    console.log('>', res.data);
    console.log();
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const cancelExam = async () => {
  try {
    Sentry.captureMessage('Cancel exam');
    console.log('Cancel exam');

    const url = `http://${CAMERA_IP}:8901/dev/ResetCamera`;
    const res = await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);
    console.log('>', res.data);
    console.log();
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const getBlobUrl = async (url: string) => {
  const res = await axios.get(url, {
    responseType: 'blob', // Set the response type to blob
  });
  return URL.createObjectURL(res.data);
};

const tryFileExistence = async (filename: string) => {
  const od = `http://${CAMERA_IP}:8901/media/${filename}_OD.jpg`;
  const os = `http://${CAMERA_IP}:8901/media/${filename}_OS.jpg`;

  // TODO: need to confirm which way is the best way to handle the 404 error?
  // 1.console.clear();
  // 2.like the camera system, after capture all images, then display the images
  // 3.leave this error in console
  // https://stackoverflow.com/questions/4500741/suppress-chrome-failed-to-load-resource-messages-in-console/57649496#57649496

  try {
    const versionUrl = `http://${CAMERA_IP}:8901/getVersions`;
    await axios.get(versionUrl, REQUEST_TIMEOUT_RETRY_CONF);
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }

  try {
    URL_OD = '';
    await axios.head(od, REQUEST_TIMEOUT_RETRY_CONF);

    URL_OD = await getBlobUrl(od);
  } catch (err) {
    if (
      axios.isAxiosError(err) &&
      err.response &&
      err.response.status === 404
    ) {
      // pass
    } else {
      Sentry.captureException(err);
      Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
      throw err;
    }
  }

  try {
    URL_OS = '';
    await axios.head(os, REQUEST_TIMEOUT_RETRY_CONF);

    URL_OS = await getBlobUrl(os);
  } catch (err) {
    if (
      axios.isAxiosError(err) &&
      err.response &&
      err.response.status === 404
    ) {
      // pass
    } else {
      Sentry.captureException(err);
      Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
      throw err;
    }
  }
};

const getStatus = async () => {
  // too many logs, comment out:
  // Sentry.captureMessage('Get status');
  // console.log('Get status');

  // get status according to file existence

  // filename is not updated until all captures are done
  // test file existence with url
  if (EXAM_ID !== '' && EXAM_FILENAME !== undefined) {
    // if the image time has been determined, check directly
    await tryFileExistence(EXAM_FILENAME);
  }

  if (URL_OD !== '' && URL_OS !== '') {
    CAMERA_STATUS = 'finished';
  } else if (URL_OS === '') {
    CAMERA_STATUS = EXAM_ID === '' ? 'idle' : 'shooting-os';
  } else {
    CAMERA_STATUS = 'shooting-od';
  }

  Sentry.captureMessage(`Camera status: ${CAMERA_STATUS}`);
  console.log(`> CAMERA_STATUS=${CAMERA_STATUS}`);
  console.log();

  return CAMERA_STATUS;
};

const handlePowerOff = async () => {
  try {
    Sentry.captureMessage('Power off');
    console.log('Power off');

    const url = `http://${CAMERA_IP}:8901/shutdown`;
    await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const setVolume = async (vol: number) => {
  try {
    Sentry.captureMessage(`Set volume ${vol}`);
    console.log(`Set volume ${vol}`);

    const url = `http://${CAMERA_IP}:8901/setin?Num=${vol}`;
    const res = await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);

    console.log('>', res.data);
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const currentCaptureStatus = async () => {
  try {
    const url = `http://${CAMERA_IP}:8901/dev/AutoState`;

    return await axios.get<AutoStateResponse>(url, REQUEST_TIMEOUT_RETRY_CONF);
  } catch (err) {
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const continueCaptureProcess = async () => {
  try {
    const url = `http://${CAMERA_IP}:8901/dev/continueCapture`;

    return await axios.get<void>(url, REQUEST_TIMEOUT_RETRY_CONF);
  } catch (err) {
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const getLanguages = async () => {
  try {
    const url = `http://${CAMERA_IP}:8901/getLanguages`;

    return await axios.get<GetLanguagesResponse>(
      url,
      REQUEST_TIMEOUT_RETRY_CONF,
    );
  } catch (err) {
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};
const setLanguage = async (params: SetLanguageParams) => {
  try {
    const url = `http://${CAMERA_IP}:8901/setLanguage`;

    return await axios.get<GetLanguagesResponse>(url, {
      params,
      ...REQUEST_TIMEOUT_RETRY_CONF,
    });
  } catch (err) {
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const getSettings = async () => {
  try {
    const url = `http://${CAMERA_IP}:8901/getSettings`;

    return await axios.get<GetSettingsResponse>(
      url,
      REQUEST_TIMEOUT_RETRY_CONF,
    );
  } catch (err) {
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const setSettings = async (data: SettingsObj) => {
  try {
    const url = `http://${CAMERA_IP}:8901/setSettings`;

    return await axios.post<void, AxiosResponse<void>, SettingsObj>(
      url,
      data,
      REQUEST_TIMEOUT_RETRY_CONF,
    );
  } catch (err) {
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const say = async (message: string) => {
  try {
    Sentry.captureMessage(`Say ${message}`);
    console.log(`Say ${message}`);

    const encodedMessage = encodeURIComponent(message);
    const url = `http://${CAMERA_IP}:8901/dev/TTS?txt=${encodedMessage}&loop=0&delay=0`;

    const res = await axios.get(url, {
      ...REQUEST_TIMEOUT_RETRY_CONF,
      timeout: 10_000,
    });
    console.log('>textToSpeech', res.data);
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

// const setSystemDateTime = async () => {
//   try {
//     const url = `http://${CAMERA_IP}:8901/setSysDateTime`;

//     // {"time":"20230310 20:56"}
//     // camera will sync to Shanghai timezone from Internet in the background,
//     // we need to follow the same timezone
//     const timestamp = moment().tz('Asia/Shanghai').format('YYYYMMDD HH:mm:ss');

//     const data = { time: timestamp };
//     console.log(`set system dateTime ${timestamp}`);

//     const res = await axios.post(url, data);
//     console.log('>systemTime', res.data);
//   } catch (err) {
//     Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
//     throw err;
//   }
// };

const disablePreviewRecord = async () => {
  try {
    Sentry.captureMessage('Disable preview record');
    console.log('Disable preview record');

    const url = `http://${CAMERA_IP}:8901/dev/PreviewRecordSet?en=0`;
    const res = await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);

    console.log('>', res.data);
    console.log();
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const getStorage = async () => {
  try {
    Sentry.captureMessage('Get storage');
    console.log('Get storage');

    const url = `http://${CAMERA_IP}:8901/getStorage`;
    const res = await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);

    // {"message":"OK","obj":{"usage":"898M / 13G"}}
    const usage = res.data.obj.usage;
    const parts = usage.split(' / ');

    CAMERA.storage = {
      used: parts[0],
      total: parts[1],
    };

    Sentry.captureMessage(`Camera storage: ${JSON.stringify(CAMERA.storage)}`);
    console.log(`> CAMERA.storage=${JSON.stringify(CAMERA.storage)}`);
    console.log();
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const clearStorage = async () => {
  try {
    Sentry.captureMessage('Clear storage');
    console.log('Clear storage');

    const url = `http://${CAMERA_IP}:8901/DeviceFormatting`;
    const res = await axios.get(url, REQUEST_TIMEOUT_RETRY_CONF);

    console.log('>', res.data);
    console.log();
  } catch (err) {
    Sentry.captureException(err);
    Api.alertBox('Error', CAMERA_ERROR_MESSAGE);
    throw err;
  }
};

const deleteExam = async () => {
  try {
    Sentry.captureMessage('Delete exam');
    console.log('Delete exam');

    EXAM_ID = '';
    EXAM_FILENAME = undefined;
    URL_OD = '';
    URL_OS = '';

    await cancelExam();
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

const getExam = async (examId: string): Promise<ExamData> => {
  try {
    if (examId !== EXAM_ID) {
      throw new Error('Exam ID not found.');
    }

    const exam: ExamData = {
      id: EXAM_ID,
      status: await getStatus(),
      leftImage: URL_OS || undefined,
      rightImage: URL_OD || undefined,
    };

    console.log(`Get exam ${JSON.stringify(exam)}`);
    return exam;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

const createExam = async (): Promise<ExamData> => {
  try {
    Sentry.captureMessage('Create exam');
    console.log('Create exam');

    // TODO: retake left eye will report "Invalid status" error here,
    // comment out for now.

    // const status = await getStatus();
    // if (status !== "idle" && status !== "finished") {
    //  throw new Error(`Invalid status ${CAMERA_STATUS}`);
    // }

    await startExam();

    EXAM_ID = '' + Date.now();

    const exam: ExamData = {
      id: EXAM_ID,
      status: 'shooting-os',
      leftImage: undefined,
      rightImage: undefined,
    };

    return exam;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

const connectivity = async () => {
  const url = `http://${CAMERA_IP}:8901/dev/AutoState`;
  return await axios.get<AutoStateResponse>(url, { timeout: 500 });
};

const CameraApi = {
  getCurrentCaptureStatus: async () => {
    return await currentCaptureStatus();
  },

  setContinueCaptureProcess: async () => {
    return await continueCaptureProcess();
  },

  getLanguages: async () => {
    return await getLanguages();
  },

  setLanguage: async (setLanguageParams: SetLanguageParams) => {
    return await setLanguage(setLanguageParams);
  },

  getSettings: async () => {
    return await getSettings();
  },

  setSettings: async (data: SettingsObj) => {
    return await setSettings(data);
  },

  scan: (ip: string, callback: callbackFunction) => {
    scanCameras(ip)
      .then((ipList) => callback(undefined, ipList))
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  connect: (ip: string, callback: callbackFunction) => {
    connectCamera(ip)
      .then((res) => {
        // notify user
        say('Camera successfully connected.');
        callback(undefined, res);
      })
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  reload: async (callback: callbackFunction) => {
    try {
      // get battery info
      await getBatteryInfo();

      const data: CameraData = {
        ip: CAMERA_IP,
        manufacture: CAMERA.manufacture,
        model: CAMERA.model,
        sn: CAMERA.sn,
        version: CAMERA.version,
        battery: CAMERA_BATTERY,
        charging: CAMERA_CHARGING,
        storage: {
          used: CAMERA.storage.used,
          total: CAMERA.storage.total,
        },
      };

      callback(undefined, data);
    } catch (err) {
      Sentry.captureException(err);
      callback(err as Error);
    }
  },

  mute: (callback: callbackFunction) => {
    setVolume(0)
      .then(() => callback())
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  unmute: (callback: callbackFunction) => {
    setVolume(100)
      .then(() => callback())
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  setVolume: (volume: number, callback: callbackFunction) => {
    setVolume(volume)
      .then(() => callback())
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  say: (message: string, callback: callbackFunction) => {
    say(message)
      .then(() => callback())
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  reset: (callback: callbackFunction) => {
    deleteExam()
      .then(() => callback())
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  clearStorage: (callback: callbackFunction) => {
    clearStorage().finally(() => {
      getStorage().finally(() => {
        callback(undefined, CAMERA.storage);
      });
    });
  },

  powerOff: async () => {
    await handlePowerOff();

    CAMERA_IP = '';
    CAMERA_STATUS = '';
    CAMERA_BATTERY = 0;
    CAMERA_CHARGING = false;

    EXAM_ID = '';
    EXAM_FILENAME = undefined;

    URL_OD = '';
    URL_OS = '';
  },

  captureBothEyes: (
    leftCallback: callbackFunction,
    rightCallback: callbackFunction,
  ) => {
    Sentry.captureMessage('Capture both eyes');

    let examId = '';
    let left = '';
    let leftFileType = '';
    let right = '';
    let rightFileType = '';

    const checkResult = () => {
      getExam(examId)
        .then(async (res) => {
          if (res.leftImage !== undefined && left === '') {
            left = res.leftImage;
            const response = await fetch(left);
            const blob = await response.blob();
            leftFileType = '.' + blob.type.split('/').pop() || '';
            leftCallback(undefined, left, leftFileType);
          }

          if (res.rightImage !== undefined && right === '') {
            right = res.rightImage;
            const response = await fetch(right);
            const blob = await response.blob();
            rightFileType = '.' + blob.type.split('/').pop() || '';
            rightCallback(undefined, right, rightFileType);
          }

          // TODO: In the current implementation, if the user click 'reset' in the camera's screen directly,  getExam(examId) cannot catch the exception.
          // The reason is that the examId has not been clear.
          if (left === '' || right === '') {
            // retry
            setTimeout(checkResult, 1000);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          leftCallback(err);
          rightCallback(err);
        });
    };

    createExam()
      .then((res: ExamData) => {
        const { id } = res;
        examId = id;
        checkResult();
      })
      .catch((err) => {
        Sentry.captureException(err);
        leftCallback(err);
        rightCallback(err);
      });
  },

  captureLeftEye: (callback: callbackFunction) => {
    Sentry.captureMessage('Capture left eye');

    let examId = '';
    let image = '';

    const checkResult = () => {
      getExam(examId)
        .then(async (res: ExamData) => {
          if (res.leftImage !== undefined && image === '') {
            // notify user
            await say(
              'Skip over the right eye. Imaging complete. You may remove your head.',
            );
            image = res.leftImage;
            callback(undefined, image);

            // reset camera after 6s
            setTimeout(async () => {
              await deleteExam();
            }, 6000);

            return;
          }

          if (image === '') {
            // retry
            setTimeout(checkResult, 1000);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          callback(err);
        });
    };

    createExam()
      .then((res: ExamData) => {
        const { id } = res;
        examId = id;
        checkResult();
      })
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  captureRightEye: (callback: callbackFunction) => {
    Sentry.captureMessage('Capture right eye');

    let examId = '';
    let image = '';

    const checkResult = () => {
      getExam(examId)
        .then((res: ExamData) => {
          if (res.rightImage !== undefined && image === '') {
            image = res.rightImage;
            callback(undefined, image);

            return;
          }

          if (image === '') {
            // retry
            setTimeout(checkResult, 1000);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          callback(err);
        });
    };

    createExam()
      .then((res: ExamData) => {
        const { id } = res;
        examId = id;

        // skip eye after 8 sec
        setTimeout(async () => {
          await skipEye();
          checkResult();
        }, 8000);
      })
      .catch((err) => {
        Sentry.captureException(err);
        callback(err);
      });
  },

  getConnectivity: async () => {
    return await connectivity();
  },
};

export { CameraApi };
