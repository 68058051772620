interface ServerErrors {
  [key: string]: string;
}
const serverErrors: ServerErrors = {
  'server-error': 'Something went wrong. Please try again later.',
  'unknown-error': 'An unknown error occured. Please try again later.',

  'user-does-not-exists': 'The given user is not registered.',
  'email-address-or-password-incorrect':
    'Email address or password is incorrect.',
  'user-not-found': 'User not found for this email address.',
  'user-not-active':
    'This account is not active. Please contact your system administrator.',
  'user-is-locked-out':
    'This user account is locked. Please contact your system administrator.',

  'password-is-required': 'Current password is required.',
  'new-password-is-required': 'New password is required.',
  'new-confirm-password-is-required': 'Confirm new password is required.',
  'password-and-confirm-passwords-do-not-match':
    'New Password and Confirm New Password are not the same.',
  'password-not-long-enough': 'Password is not long enough.',
  'password-not-enough-uppercase-letters':
    "Password doens't have enough uppercase letters.",
  'password-not-enough-lowercase-letters':
    "Password doens't have enough lower letters.",
  'password-not-enough-numbers': "Password doens't have enough numbers.",
  'password-not-enough-special-characters':
    "Password doens't have enough special numbers.",
  'password-was-reused-previously':
    'Password has been used previously. Please give a different password.',

  'not-authorized': 'You are not authorized to perform this action.',

  'device-property-set-id-is-required': 'Device property set id is required.',
  'device-data-is-required': 'Device data is required.',
  'device-data-model-id-is-required': 'Device data model id is required.',
  'device-id-is-required': 'Device id is required.',
  'device-data-map-is-required': 'Device data map is required.',
  'device-not-found': 'Device not found.',
  'device-set-does-not-accept-data-from-external-source':
    'Property set does not accept data from external source.',
  'could-not-save-device-data': 'Could not save device data.',
  'could-not-get-device-data': 'Could not get device data.',
  'file-size-too-large': 'Media data file is too large.',
  'device-media-is-required': 'Device media is required.',
  'device-property-not-found': 'Device property not found.',
  'not-a-media-property': 'Not a media property.',
  'device-data-not-found': 'Device data not found.',
  'could-not-save-device-data-media': 'could not save device media data.',
  'could-not-get-device-data-media': 'Could not get device media data.',

  'none-found': 'No data found.',

  // TODO: below messages has not been mentioned in manual script

  'practice-is-required': 'Practice is required.',
  'practice-does-not-exist': 'Practice does not exist.',
  'practice-not-active': 'Practice is not active.',
  'practice-id-is-required': 'Practice id is required.',

  'user-id-already-exists': 'User id already exists',
  'user-is-required': 'User is required',

  'user-id-is-required': 'Profile user id is required.',

  'user-email-address-already-exists-for-this-tenant':
    'A user already exists with this email address.',
  'could-not-update-user-profile':
    'Could not update profile, please try again later.',
  'current-password-is-incorrect': 'Current password is incorrect.',
  'could-not-update-user-password':
    'Could not update password, please try again later.',
  'could-not-create-user':
    'Unknown error occurred while creating user. Please try again later.',
  'user-access-not-allowed-by-tenant':
    'You are not allowed to login to this site. Please contact {{value}} for more information.',
  'guest-role-not-allowed-for-user-create':
    'Guest role is not allowed while creating a new user.',
  'could-not-register-user':
    'Unknown error while user registration. Please try again later.',
  'could-not-activate-user':
    'Unknown error while activating user. Please try again later.',
  'could-not-send-otp-code':
    'Unknown error while sending confirmation/activation code. Please try again later.',
  'not-allowed-to-login-to-this-app':
    'You are not allowed to login to this application.',
  'could-not-update-user-terms-of-agreement-for-tenant':
    'Unknown error while updating terms agreement. Please try again later.',
  'could-not-reset-user-agreement-for-tenant-terms-of-use':
    'Unknown error reseting the agreement for all users. Please try again later.',
  'current-role-and-target-role-cannot-be-same':
    'Curent role and target switch role cannot be same.',
  'user-contact-info-not-found': 'Contact information for the user not found.',
  'user-primary-phone-not-found': 'No primary phone number set for the user.',

  'contact-label-already-exists-for-this-user':
    'The contact label already exists for this user. Please enter a unique label.',
  'could-not-create-user-contact':
    'Unknown error while creating contact for this user. Please try again later.',
  'could-not-update-user-contact':
    'Unknown error while updating contact for this user. Please try again later.',
  'could-not-delete-user-contact':
    'Unknown error while deleting contact for this user. Please try again later.',

  'layout-name-already-exists-for-owner': 'Layout name already exists',
  'could-not-create-layout':
    'Unknown error while creating dashboard. Please try again later.',
  'layout-not-found': 'Given dashboard does not exists',
  'widget-not-found': 'Given widget does not exists',
  'could-not-create-layout-widget':
    'Unknown error while creating widget for the given dashboard. Please try again later',
  'could-not-get-layouts-for-user':
    'Unknown error while getting all the dashboards. Please try again later.',
  'could-not-update-layout':
    'Unknown error while updating the dashboard. Please try again later.',
  'could-not-update-layout-widget':
    'Unknown error while updating widget. Please try again later.',
  'layout-widget-not-found': 'Widget not found.',
  'could-not-delete-layout-widget':
    'Error deleting widget. Please try again later.',
  'layout-widget-user-filter-is-required':
    'No users (Patients) selected for widget data filter. Please select at least one user (Patient)',
  'layout-name-is-required': 'Name is required',

  'practice-id-already-exists': 'Practice id is already exist.',
  'practice-name-is-required': 'Practice name is required.',
  'practice-name-already-exists': 'Practice name is already exist.',
  'could-not-create-practice':
    'Unknown error occurred while creating practice. Please try again later.',
  'could-not-update-practice':
    'Unknown error occurred while updating practice. Please try again later.',
  'could-not-delete-practice':
    'Unknown error occurred while deleting practice. Please try again later.',

  'supplier-not-enabled-for-tenant':
    "Partner feature is not enabled. Please enable it from your company's preferences.",
  'supplier-is-required': 'Partner is required.',
  'supplier-name-is-required': 'Partner name is required.',
  'supplier-id-already-exists': 'Given Partner id already exists.',
  'supplier-name-already-exists': 'Given Partner name already exists.',
  'could-not-create-supplier':
    'Unknown error while creating Partner. Please try again later.',
  'supplier-not-found': 'Partner not found.',
  'could-not-update-supplier':
    'Unknown error while updating Partner. Please try again later.',
  'supplier-id-is-required': 'Partner id is missing.',
  'could-not-get-supplier-by-id':
    'Unknown error while getting Partner for given id. Please try again later.',
  'could-not-delete-supplier':
    'Unknown error while deleting the Partner for given id. Please try again later.',
  'could-not-get-all-suppliers-for-tenant-and-filters':
    'Unknown error while getting Partner with given filters.',
  'supplier-not-active': 'Partner is not active.',

  'role-is-required': 'Role is required',
  'tenant-is-required': 'Manufacturer is required',
  'first-name-is-required': 'First name is required',
  'last-name-is-required': 'Last name is required',
  'email-address-is-required': 'Email address is required',
  'street-address-1-is-required': 'Address 1 is required',
  'city-is-required': 'City is required',
  'state-is-required': 'State is required',
  'country-is-required': 'Country is required',
  'zipcode-is-required': 'Zipcode is required',
  'primary-phone-is-required': 'Primary phone is required',
  'roles-are-required': 'Missing role or required role information',

  'invalid-otp-code': 'Invalid OTP code.',
  'expired-otp-code': 'OTP Code expired.',
  'error-resend-code':
    'An error occured while resending the OTP code. Please try again.',
  'invalid-captcha': 'Invalid Captcha',

  'could-not-get-audit-logs':
    'Unknown error getting audit logs. Please try again later.',
  'audit-criteria-is-required': 'Audit criteria is missing.',

  'token-expiration-in-the-past': 'Token expiration date is in the past.',
  'token-already-exists':
    'Token for given user (owner), expiration date and application already exists.',
  'could-not-create-app-token':
    'Unknown  error while creating application token. Please try again later.',
  'could-not-get-app-tokens':
    'Unknown error getting application token. Please try again later.',
  'could-not-create-master-token':
    'Unknown error while creating master token. Please try again later.',
  'could-not-update-master-token-status':
    'Unknown error while updating master token. Please try again later.',
  'could-not-get-master-tokens':
    'Unknown error getting master tokens. Please try again later.',
  'could-not-get-master-token':
    'Unknown error getting master token. Please try again later.',

  'user-not-allowed-to-create-observer':
    'Current user not allowed to invite observer.',
  'observer-access-valid-until-in-past':
    'Observer access validity is in the past.',
  'could-not-create-observer':
    'Unknown error creating the request. Please try again later.',
  'user-not-patient': 'The user for given email address is not a Patient',
  'user-observer-not-found': 'Observer request/invite not found.',
  'could-not-update-observer-status':
    'Unknown error updating the request. Please try again later.',
  'observer-already-exists': 'A similar user-observer relation already exists.',
  'user-observer-cannot-be-same': 'You cannot add yourself as an observer.',
  'could-not-delete-user-observer':
    'Unknown error while deleting user observer. Please try again later.',
  'could-not-update-user-invite-status':
    'Unknown error while updating invite status. Please try again later.',
  'could-not-get-user-observees-for-device':
    'Unknown error while fetching observee users for given device',
  'date-of-birth-cannot-be-in-the-future':
    'Date of birth cannot be in the future.',
  'user-invite-already-exists': 'User invite already exists.',

  'could-not-get-all-devices-by-filter':
    'Unknown error while getting devices by filter',
  'could-not-get-all-devices-by-tenant-filter-and-allowed-to-specify-data':
    'Unknown error while getting devices by filter and allowed to specify data',

  'tenant-theme-template-file-not-found':
    'Error creating custom style file on the server. Please try again later.',
  'could-not-read-tenant-theme-template-file':
    'Error creating custom style file on the server. Please try again later.',
  'tenant-theme-configuration-is-required':
    'Appearance customization configuration is required.',
  'tenant-theme-configuraiton-is-invalid':
    'Appearance customizaton configuration is invalid.',
  'could-not-save-tenant-theme-customisation':
    'Unknown error saving appearance customization. Please try again later.',
  'tenant-preference-value-is-required':
    'Value for preference {{value}} is required',
  'tenant-does-not-exist': 'This Manufacturer does not exist',
  'tenant-name-already-exists': 'Manufacturer name already exists',
  'could-not-update-tenant':
    'Could not update Manufacturer. Please try again later.',
  'tenant-emailaddress-is-required': 'Manufacturer email address is required',
  'could-not-update-tenant-terms-of-use':
    'Unknown error while updating terms of use. Please try again later.',
  'tenant-asset-file-is-required': 'File is required',
  'could-not-save-tenant-asset-file':
    'Unknown error while saving the given file. Please try again later.',
  'could-not-get-tenant-asset-file':
    'Uknown error while fetching the file. Please try again later.',
  'tenant-asset-file-identifier-is-required':
    'The unique file identifier is required to fetch the file.',
  'could-not-set-tenant-logo-image':
    'Unknown error saving the Manufacturer logo. Please try again later.',
  'unable-to-read-tenant-logo-file-from-request':
    'Invalid logo image provided.',
  'tenant-preference-value-is-invalid':
    'Value for preference {{value}} is invalid',
  'unable-to-parse-web-access-roles-preference-value':
    'Invalid value for web access role preference.',

  'event-does-not-support-multiple-notifications':
    'Event does not support multiple actions.',
  'event-does-not-support-notification-channel':
    'Event does not support this action.',
  'could-not-get-user-events': 'Could not get events.',
  'event-rule-name-already-exists': 'Event name already exists.',
  'could-not-create-user-event-rule': 'Could not create event.',
  'could-not-update-user-event-rule': 'Could not update event.',
  'could-not-delete-user-event-rule': 'Could not delete event.',
  'could-not-get-user-event-rule': 'Could not get event.',
  'could-not-get-user-event-rules-for-user-filter': 'Could not get events.',

  'fresca-pdf-report-not-found':
    'No report found for given filter criteria. Please change the filters and try again.',
  'fresca-could-not-get-pdf-from-server':
    'Error retrieving PDF from the server. Please try again later.',

  'device-property-set-not-found': 'Device property set not found.',
  'device-property-code-is-required': 'Device property code is required.',
  'device-property-predefined-values-required':
    'Predefined values are required for the selected device property data type.',
  'device-property-predefined-values-invalid':
    'Predefined values set are invalid for the selected device property data type',

  'could-not-delete-device-data-for-given-ids-and-filters':
    'Unknown error while deleting selected device data. Please try again later',

  'custom-field-name-is-required': 'Custom field name is required',
  'custom-field-type-is-required': 'Custom field type is required',
  'custom-field-options-are-required': 'Custom field options are required',
  'custom-field-name-already-exists': 'Custom field name already exists',
  'custom-field-does-not-exists': 'Custom field does not exists',
  'could-not-create-custom-field-for-user-profile':
    'Unknown error creating custom field. Please try again later.',
  'could-not-update-custom-field-for-user-profile':
    'Unknown error updating custom field. Please try again later.',
  'could-not-delete-custom-field-for-user-profile':
    'Unknown error deleting custom field. Please try again later.',

  'file-name-required': 'File name is required',
  'mfa-mode-required':
    'Multi-Factor Authentication (MFA) delivery method (modes) are required',
  'could-not-set-user-mfa-mode':
    'Unknonw error while saving Multi-Factor Authentication (MFA) delivery method (modes). Please try again later.',
  'could-not-set-user-mfa-setting':
    'Unknown error while saving Multi-Factor Authentication setting. Please try again later',
  'mfa-disable-not-allowed-by-tenant':
    'Your Manufacturer has made Multi-Factor Authentication (MFA) mandatory, hence it cannot be disabled.',
  'cannot-change-verified-primary-phone-with-sms-mfa':
    'Primary phone number cannot be changed when SMS delivery mode for Multi-Factor Authentication (MFA) is enabled',
  'user-mfa-modes-not-found':
    'Multi-Factor Authentication (MFA) delivery method not configured.',

  'firmware-hash-do-not-match': 'Firmware hash value is incorrect',
  'firmware-name-already-exists':
    'Firmware name already exists for this device',
  'firmware-display-version-already-exists':
    'Firmware display version already exists',
  'could-not-create-firmware':
    'An error occured creating firmware. Please try again later.',
  'could-not-update-firmware':
    'An error occured updating firmware. Please try again later.',
  'could-not-delete-firmware':
    'An error occured deleting firmware. Please try again later.',

  'phone-number-required': 'Phone number is required',
  'could-not-send-phone-verification-code':
    'Unknown error while sending verification code. Please try again later.',
  'could-not-verify-phone-verification-code':
    'Unknown error while verifying the OTP code for phone verificaiton. Please try again later.',
  'phone-number-not-supported-for-verification':
    'The given phone number type currently does not verification.',

  'could-not-get-event-logs':
    'Unknown error while getting event logs. Please try again later.',

  'mti-could-not-get-report-from-server': 'Results could not be generated',

  'external-service-info-label-already-exists': 'Label already exists',
  'external-service-info-type-already-exists':
    'External service already exists for the selected type. Please update the existing external service.',
  'could-not-create-external-service-info':
    'An error occured creating external service. Please try again later.',
  'could-not-update-external-service-info':
    'An error occured updating external service. Please try again later.',
  'could-not-get-external-service-info':
    'An error occured retrieving external service. Please try again later.',

  'custom-data-value-is-required': 'Custom data value is required.',
  'custom-data-value-is-invalid': 'Custom data value is invalid.',

  'totp-verification-error': 'Verification code is invalid or expired.',

  'could-not-find-default-email-templates':
    'An error occurred retrieving default email templates. Please try again later.',
  'could-not-find-default-email-template':
    'An error occurred retrieving default email template. Please try again later.',
  'could-not-find-custom-email-template':
    'An error occurred retrieving custom email template. Please try again later.',
  'could-not-find-custom-email-template-name':
    'Custom email template name does not exists.',
  'could-not-find-email-templates':
    'An error occurred retrieving email templates. Please try again later.',
  'could-not-create-email-template':
    'An error occurred creating email template. Please try again later.',
  'could-not-update-email-template':
    'An error occurred saving email template. Please try again later.',
  'could-not-delete-email-template':
    'An error occurred deleting email template. Please try again later.',
  'could-not-find-email-template-preferences':
    'An error occurred retrieving email template preferences. Please try again later.',
  'could-not-update-email-template-preferences':
    'An error occurred saving email template preferences. Please try again later.',
  'could-not-delete-email-template-related-data':
    "An error occurred deleting tenant's email template related data. Please try again later.",
  'invalid-email-template-preferences': 'Invalid email template preferences.',
  'email-template-name-already-exists':
    'Email template name already exists. Please use different template name.',
  'email-template-id-is-required': 'Email template id is required.',
  'email-template-preference-map-is-required':
    'Email template preferences is required.',
  'email-template-styles-file-not-found':
    'Email template styles file not found.',
  'email-template-file-not-found': 'Email template file not found.',
  'could-not-read-email-template-file':
    'An error occurred reading email template file.',
  'could-not-build-email-message': 'An error occurred building email message.',
  'event-type-is-required': 'Event Type is required.',
  'could-not-build-email-header-footer':
    "An error occurred building email template's header and footer.",
  'could-not-find-email-header-footer-template':
    "An error occurred finding email template's header and footer.",
  'could-not-find-email-event-is-disabled':
    'An error occurred finding email template event is disabled or not.',
  'multiple-default-email-templates-found':
    'Multiple default email templates found.',

  'could-not-find-device-data-forms':
    'An error occurred retrieving forms. Please try again later.',
  'could-not-find-device-data-form':
    'An error occurred retrieving form. Please try again later.',
  'device-data-form-id-is-required': 'Form id is required.',
  'device-data-form-name-already-exists': 'Form name already exists.',
  'could-not-find-device-data-form-name': 'Form name does not exists.',
  'could-not-create-device-data-form':
    'An error occurred creating form. Please try again later.',
  'could-not-delete-device-data-form':
    'An error occurred deleting form. Please try again later.',
  'could-not-update-device-data-form':
    'An error occurred saving form. Please try again later.',
  'configure-fields-required': 'Configure form fields is required.',
  'form-cannot-save-in-preview-mode': 'Form cannot be save in preview mode.',
  'device-data-form-not-found': 'Form not found.',
  'device-data-form-is-inactive': 'Form is inactive.',
  'device-data-form-does-not-support-collection-method':
    'Form does not support collection method.',
  'device-data-form-does-not-support-model-scope':
    'Form does not support model scope.',

  'could-not-find-device-data-form-draft-instances':
    'An error occurred retrieving draft forms. Please try again later.',
  'could-not-find-device-data-form-completed-instances':
    'An error occurred retrieving completed forms. Please try again later.',
  'could-not-find-all-device-data-form-instances':
    'An error occurred retrieving draft and completed forms. Please try again later.',
  'could-not-find-device-data-form-instances-by-ids':
    'An error occurred retrieving form for the given ids. Please try again later.',
  'could-not-find-device-data-form-instances':
    'An error occurred retrieving forms. Please try again later.',
  'could-not-find-device-data-form-instance':
    'An error occurred retrieving form. Please try again later.',
  'could-not-create-device-data-form-instance':
    'An error occurred creating form. Please try again later.',
  'could-not-update-device-data-form-instance':
    'An error occurred updating form. Please try again later.',
  'could-not-delete-device-data-form-instance':
    'An error occurred deleting form. Please try again later.',
  'could-not-delete-device-data-form-draft-instances':
    'An error occurred deleting draft form. Please try again later.',
  'could-not-set-user-to-null-for-completed-instances':
    'An error occurred removing dataEnteredBy user for the completed form. Please try again later.',
  'not-authorized-to-update-completed-device-data-form-instance':
    'Not authorized to update completed form.',
  'restricted-to-create-device-data-form-instance':
    'Not authorized to use form at this time.',
  'data-enter-by-user-not-found': 'Data entered by user not found.',
  'owner-not-found': 'Form owner not found.',
  'device-data-form-instance-not-found': 'Form record not found.',
  'device-data-form-instances-not-found': 'Form records not found.',
  'device-data-form-instance-is-required': 'Form record is required.',
  'device-data-form-instance-id-is-required': 'Form record id is required',
  'device-data-not-compatible-with-form':
    'Device data is not compatible with form.',
  'not-authorized-to-save-form-for-selected-owner':
    'Not authorized to save form for the selected owner.',
  'device-is-inactive': 'Device is inactive.',
  'grouping-property-value-already-exists-in-another-device-data':
    'This form contains a value that must be unique. The value you supplied is used in another record. Please enter a different value.',

  'tenant-does-not-allow-self-registration':
    'Users are not allowed to register an account on this application.',
  'external-service-info-not-found':
    'Login via social website is setup in external services. Please configure login setup in external services.',
  'login-type-is-not-supported': 'Login type is not supported.',
  'code-is-required': 'OAuth2 Code is required.',
  'login-type-is-required': 'Login type is required.',
  'redirect-uri-is-required': 'Redirect URI is required.',
  'oauth2-service-token-not-found':
    'OAuth2 service could not find the token. Please try again later.',
  'oauth2-service-token-request-failed':
    'OAuth2 token request failed. Please try again later.',
  'error-occurred-while-fetching-oauth2-token':
    'Error occurred while fetching OAuth2 token. Please try again later.',
  'could-not-login-user-using-oauth2':
    'Could not login user using social website. Please try again later.',
  'could-not-register-user-using-oauth2':
    'Could not register user using social website. Please try again later.',
  'could-not-find-user-to-login-using-oauth2':
    'Could not find user to login using social website.',
  'oauth2-service-api-unable-to-find-email-address':
    'Could not find email address.',
  'oauth2-service-api-unable-to-find-first-name': 'Could not find first name.',
  'oauth2-service-api-unable-to-find-last-name': 'Could not find last name.',
  'oauth2-client-secret-is-required':
    'OAuth2 client secret is required. Please update the login setup configuration in external services.',
  'device-instance-identifier-is-required':
    'Device instance identifier is required.',
  'device-instance-identifier-already-exists':
    'Device instance with the provided identifier already exist.',
  'max-active-token-already-exist':
    'You already have the max allowed active tokens per device instance.',
  'invalid-mfa-auth-request':
    'Request for multi-factor authentication is not valid.',
  'email-address-is-invalid': 'Email address is invalid.',
  'status-is-required': 'Status is required',

  'external-service-oauth2-client-id-is-required':
    'Oauth2 client id is required',
  'external-service-oauth2-client-secret-is-required':
    'Oauth2 client secret is required',
  'external-service-oauth2-client-domain-is-required':
    'Oauth2 client domain is required',
  'tenant-id-is-required': 'Manufacturer id is required',
};

export function getErrorMessage(key: string): string {
  return serverErrors[key] || key;
}
