import { Text, View } from '@react-pdf/renderer';

import { handleNSI18Next } from 'src/models/Language.model';
import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

import SegmentationImageSection from './SegmentationImageSection';

interface ISegmentationSectionProps {
  session: Session;
  type: ReportType;
}

const SegmentationSection = ({ session, type }: ISegmentationSectionProps) => {
  const { handleReportT } = handleNSI18Next();

  return (
    <>
      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td px-0 border-0 w-50')}>
          <Text>{handleReportT(`imageSection.OD`)}</Text>
        </View>

        <View style={cls('td px-3 py-0 border-0')}></View>

        <View style={cls('td px-0 border-0 w-50')}>
          <Text>{handleReportT(`imageSection.OS`)}</Text>
        </View>
      </View>

      <SegmentationImageSection session={session} />

      <Text style={cls('p mt-3')}>
        {handleReportT(`imageSection.clinical.retinalImagesParagraph`)}
      </Text>

      <Text style={cls('p')}>
        {handleReportT(`imageSection.${type}.thumbnailWarningText`)}
      </Text>
    </>
  );
};

export default SegmentationSection;
