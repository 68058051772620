import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Image, Text, View } from '@react-pdf/renderer';

import FontAwesomeIcon from 'src/components/custom_components/PdfFontAwesomeIcon';

import { Disease, DiseaseFullName } from 'src/models/Disease.model';
import {
  FindResult,
  Grading,
  GradingIsPositive,
  GradingName,
} from 'src/models/Grading.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface IHeatmapSectionProps {
  session: Session;
}

const heatmapTr = (session: Session, disease: Disease) => {
  const left = FindResult(session.leftResults, disease);
  const right = FindResult(session.rightResults, disease);

  const leftIsPositive = left !== undefined && GradingIsPositive(left.grading);
  const rightIsPositive =
    right !== undefined && GradingIsPositive(right.grading);

  if (leftIsPositive || rightIsPositive) {
    const leftHeatmapUrl = left?.heatmapFile || '/img/noimage.gif';
    const rightHeatmapUrl = right?.heatmapFile || '/img/noimage.gif';

    const { handleReportT } = handleNSI18Next();

    return (
      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td w-50')}>
          {rightIsPositive ? (
            <Image src={rightHeatmapUrl} style={cls('rounded')} />
          ) : (
            <View style={cls('text-center align-middle')}>
              <Text style={cls('p text-muted')}>
                {handleReportT('heatmapSection.notAvailable')}
              </Text>
            </View>
          )}
        </View>

        <View style={cls('px-3 p-0 border-0')}></View>

        <View style={cls('td w-50')}>
          {leftIsPositive ? (
            <Image src={leftHeatmapUrl} style={cls('rounded')} />
          ) : (
            <View style={cls('text-center align-middle')}>
              <Text style={cls('p text-muted')}>
                {handleReportT('heatmapSection.notAvailable')}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  } else {
    return <></>;
  }
};

const diseaseTr = (session: Session, disease: Disease) => {
  const left = FindResult(session.leftResults, disease);
  const right = FindResult(session.rightResults, disease);

  const { handleReportT } = handleNSI18Next();

  const leftIsPositive =
    left !== undefined &&
    GradingIsPositive(left.grading) &&
    left.heatmapFile !== undefined;
  const rightIsPositive =
    right !== undefined &&
    GradingIsPositive(right.grading) &&
    right.heatmapFile !== undefined;

  return leftIsPositive || rightIsPositive ? (
    <View style={cls('d-flex hstack justify-content-between')}>
      <View style={cls('td w-30 text-muted')}>
        {rightIsPositive && (
          <Text>{handleReportT(`resultSection.diseaseType`)}</Text>
        )}
      </View>
      <View style={cls('td w-20 text-muted')}>
        {rightIsPositive && (
          <Text>{handleReportT(`resultSection.status`)}</Text>
        )}
      </View>

      <View style={cls('px-3 p-0 border-0')}></View>

      <View style={cls('td w-30 text-muted')}>
        {leftIsPositive && (
          <Text>{handleReportT(`resultSection.diseaseType`)}</Text>
        )}
      </View>
      <View style={cls('td w-20 text-muted')}>
        {leftIsPositive && <Text>{handleReportT(`resultSection.status`)}</Text>}
      </View>
    </View>
  ) : (
    <></>
  );
};

const resultTr = (session: Session, disease: Disease) => {
  const left = FindResult(session.leftResults, disease);
  const right = FindResult(session.rightResults, disease);

  const { handleCommonT } = handleNSI18Next();

  const leftIsPositive =
    left !== undefined &&
    GradingIsPositive(left.grading) &&
    left.heatmapFile !== undefined;
  const rightIsPositive =
    right !== undefined &&
    GradingIsPositive(right.grading) &&
    right.heatmapFile !== undefined;

  if (leftIsPositive || rightIsPositive) {
    const leftIsSevere =
      left?.grading === Grading.HIGH_RISK ||
      left?.grading === Grading.PROLIFERATIVE_DR ||
      left?.grading === Grading.LATE_WET ||
      left?.grading === Grading.POSITIVE ||
      left?.grading === Grading.SEVERE_ATHEROSCLEROSIS;

    const rightIsSevere =
      right?.grading === Grading.HIGH_RISK ||
      right?.grading === Grading.PROLIFERATIVE_DR ||
      right?.grading === Grading.LATE_WET ||
      right?.grading === Grading.POSITIVE ||
      right?.grading === Grading.SEVERE_ATHEROSCLEROSIS;

    return (
      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td w-30')}>
          {rightIsPositive && (
            <Text>
              {handleCommonT(`diseaseFullName.${DiseaseFullName(disease)}`)}:
            </Text>
          )}
        </View>
        <View style={cls('td w-20')}>
          {rightIsPositive && (
            <View
              style={cls(
                `me-1 badge rounded-pill ${rightIsSevere ? 'bg-danger' : 'bg-warning'}`,
              )}
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={cls('me-1 text-white')}
              ></FontAwesomeIcon>

              <Text>
                {handleCommonT(`gradingName.${GradingName(right.grading)}`)}
              </Text>
            </View>
          )}
        </View>

        <View style={cls('px-3 p-0 border-0')}></View>

        <View style={cls('td w-30')}>
          {leftIsPositive && (
            <Text>
              {handleCommonT(`diseaseFullName.${DiseaseFullName(disease)}`)}:
            </Text>
          )}
        </View>
        <View style={cls('td w-20')}>
          {leftIsPositive && (
            <View
              style={cls(
                `me-1 badge rounded-pill ${leftIsSevere ? 'bg-danger' : 'bg-warning'}`,
              )}
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={cls('me-1 text-white')}
              ></FontAwesomeIcon>

              <Text>
                {handleCommonT(`gradingName.${GradingName(left.grading)}`)}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  } else {
    return <></>;
  }
};

const HeatmapSection = ({ session }: IHeatmapSectionProps) => {
  const { handleReportT } = handleNSI18Next();

  return (
    <>
      <View style={cls('flex')}>
        <Text style={cls('h2 report-header-no-logo')}>
          {handleReportT(`imageSection.heatmapImageTitle`)}
        </Text>
      </View>

      <Text style={cls('p mb-4')}>
        {handleReportT(`imageSection.heatmapDisclaimerParagraph`)}
      </Text>

      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td w-50 border-0 b')}>
          <Text>{handleReportT(`imageSection.OD`)}</Text>
        </View>

        <View style={cls('px-3 p-0 border-0')}></View>

        <View style={cls('td w-50 border-0 b')}>
          <Text>{handleReportT(`imageSection.OS`)}</Text>
        </View>
      </View>

      {heatmapTr(session, Disease.GLAUCOMA)}
      {diseaseTr(session, Disease.GLAUCOMA)}
      {resultTr(session, Disease.GLAUCOMA)}

      {heatmapTr(session, Disease.DR)}
      {diseaseTr(session, Disease.DR)}
      {resultTr(session, Disease.DR)}

      {heatmapTr(session, Disease.AMD)}
      {diseaseTr(session, Disease.AMD)}
      {resultTr(session, Disease.AMD)}
    </>
  );
};

export default HeatmapSection;
