import { ReactNode } from 'react';

import { faMinus, faPlus, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Text } from '@react-pdf/renderer';
import cx from 'classnames';

import CustomBadge from 'src/components/custom_components/CustomBadge';
import FontAwesomeIcon from 'src/components/custom_components/PdfFontAwesomeIcon';

import { cls, REM, SCALE } from 'src/utils/reactPdfStyles';

type SingleEyeResultBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
  children: string | ReactNode;
};
export function SingleEyeResultBadge({
  className,
  style,
  ...props
}: SingleEyeResultBadgeProps) {
  return (
    <CustomBadge
      className={cx('px-3 py-1 mt-0 mb-2', className, 'badge-font')}
      {...props}
    />
  );
}

export function SingleEyePositiveResultBadge({
  children,
}: {
  children: string | ReactNode;
}) {
  return (
    <SingleEyeResultBadge
      children={children}
      className="bg-danger-200 text-danger"
    />
  );
}

export function SingleEyeNegativeResultBadge() {
  return (
    <SingleEyeResultBadge
      children={<Text>Negative</Text>}
      className="text-primary-600 bg-primary-200"
    />
  );
}

export function SingleEyeInsufficientResultBadge() {
  return (
    <SingleEyeResultBadge
      children={<Text>Insufficient Exam</Text>}
      className="bg-secondary-200 text-secondary-700"
    />
  );
}

export function PositiveResultIconBadge() {
  return (
    <SingleEyeResultBadge className="bg-danger-200 text-danger">
      <FontAwesomeIcon
        icon={faPlus}
        style={cls('text-danger', { width: 1 * REM * SCALE })}
      />
    </SingleEyeResultBadge>
  );
}

export function NegativeResultIconBadge() {
  return (
    <SingleEyeResultBadge className="bg-primary-200 text-primary-600">
      <FontAwesomeIcon
        icon={faMinus}
        style={cls('text-primary-600', { width: 1 * REM * SCALE })}
      />
    </SingleEyeResultBadge>
  );
}

export function InsufficientResultIconBadge() {
  return (
    <SingleEyeResultBadge className="bg-secondary-200 text-secondary-700">
      <FontAwesomeIcon
        icon={faQuestion}
        style={cls('text-secondary-700', { width: 0.6 * REM * SCALE })}
      />
    </SingleEyeResultBadge>
  );
}
