export const namePattern = /^[\p{L}\p{M}\p{Zs}.'’-]+$/u;

export const emailPattern = /\S[^\s@]*@\S+\.\S+/;

export const MRNPattern = /^\d{1,20}$/;

export const datePattern = /^\d{4}-\d{2}-\d{2}$/;

export const validateSearchValue = (value: string): boolean => {
  if (value === '') {
    return true;
  }

  return (
    namePattern.test(value) || MRNPattern.test(value) || datePattern.test(value)
  );
};

export function validateDatePattern(date: string): boolean {
  return datePattern.test(date);
}

export function validateNamePattern(name: string): boolean {
  return namePattern.test(name);
}

export function validateNameLength(name: string): boolean {
  return name.length <= 100;
}

export function validateMRN(mrn: string): boolean {
  return MRNPattern.test(mrn);
}

export function validateMRNLength(mrn: string): boolean {
  return mrn.length <= 20;
}

export const validatePassword = {
  minLength: (value: string) =>
    value.length >= 8 || 'Password should be of minimum 8 characters.',
  hasUpperCase: (value: string) =>
    /[A-Z]/.test(value) ||
    'Password should contain at least 1 upper case letter.',
  hasNumber: (value: string) =>
    /\d/.test(value) || 'Password should contain at least 1 number.',
  hasSpecialChar: (value: string) =>
    /[!@#$%^&*()_+\-={}[\]:";'<>,./?]/.test(value) ||
    'Password should contain at least 1 special character from !@#$%^&*()_+-={}[]:";\'<>,./?.',
};
