import React, { ReactNode } from 'react';
import { Stack, StackProps } from 'react-bootstrap';

import { faSadTear } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface EmptyProps extends StackProps {
  message?: string | ReactNode;
  children: ReactNode;
}
export default function Empty({
  message = 'No matching records found.',
  children,
  ...props
}: EmptyProps) {
  return (
    <Stack className="d-flex align-items-center gap-5 mb-5" {...props}>
      <div className="text-center mt-5">
        <h1>
          <FontAwesomeIcon icon={faSadTear} className="me-2" />
          {message}
        </h1>
      </div>
      <div className="d-flex gap-4">{children}</div>
    </Stack>
  );
}
