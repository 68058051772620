import useSWR from 'swr';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { SWRKeysEnum } from 'src/models/SWRKeys.model';

export function useGetCameraLanguages(shouldGetCameraSettings: boolean = true) {
  const { data, error, isLoading } = useSWR(
    shouldGetCameraSettings ? SWRKeysEnum.GET_CAMERA_LANGUAGES : null,
    () => CameraApi.getLanguages(),
  );

  return {
    languages: data?.data,
    isLoading,
    isError: error,
  };
}
