import { ListGroup, Modal } from 'react-bootstrap';

import { BackendStatus } from 'src/models/GalenData/DeviceDataView.model';
import { ModelEnum } from 'src/models/Model.model';
import { Session } from 'src/models/Session.model';

import GlobalStack from './GlobalStack';
import ModelStack from './ModelStack';

const modelStatusMapping: Record<
  ModelEnum,
  keyof Omit<BackendStatus, 'GlobalStatus' | 'SessionId'>
> = {
  [ModelEnum.DR_US]: 'DrusStatus',
  [ModelEnum.DR_EU]: 'DreuStatus',
  [ModelEnum.AMD_EU]: 'AmdeuStatus',
  [ModelEnum.GLAUCOMA_EU]: 'GlaucomaeuStatus',
  [ModelEnum.CVD_EU]: 'CvdeuStatus',
  [ModelEnum.CVD_AU]: 'CvdauStatus',
  [ModelEnum.DR_EU_SMALL]: 'DreuSmallStatus',
  [ModelEnum.AMD_EU_SMALL]: 'AmdeuSmallStatus',
  [ModelEnum.GLAUCOMA_EU_SMALL]: 'GlaucomaeuSmallStatus',
  [ModelEnum.CVD_EU_SMALL]: 'CvdeuSmallStatus',
  [ModelEnum.CVD_AU_SMALL]: 'CvdauSmallStatus',
  [ModelEnum.VESSEL_SEGMENTATION]: 'VesselSegStatus',
  [ModelEnum.GENERAL_QUALITY]: 'GeneralQualityStatus',
};

interface ProgressModalBodyProps {
  session: Session;
  shouldShowDilationRecommendation: boolean;
  shouldShowReachMaxRecommendedAttempts: boolean;
  status: BackendStatus;
  models: ModelEnum[];
}

const ProgressModalBody = ({
  session,
  shouldShowDilationRecommendation,
  shouldShowReachMaxRecommendedAttempts,
  status,
  models,
}: ProgressModalBodyProps) => {
  return (
    <Modal.Body className="p-4">
      <ListGroup>
        <ListGroup.Item className="p-4">
          <GlobalStack session={session} status={status} />
        </ListGroup.Item>

        {models.map(
          (model, index) =>
            status[modelStatusMapping[model]]?.value && (
              <ListGroup.Item key={index} className="p-4 ps-5">
                <ModelStack name={modelStatusMapping[model]} status={status} />
              </ListGroup.Item>
            ),
        )}
      </ListGroup>

      {shouldShowDilationRecommendation && (
        <p className="text-optain my-2">
          <i>Dilation is recommended for continued imaging</i>
        </p>
      )}
      {shouldShowReachMaxRecommendedAttempts && (
        <p className="text-danger my-2">
          <i>Reached maximum recommended imaging attempts</i>
        </p>
      )}
    </Modal.Body>
  );
};

export default ProgressModalBody;
