import useSWR from 'swr';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { SWRKeysEnum } from 'src/models/SWRKeys.model';

export function useGetCurrentCaptureStatus(
  shouldGetCurrentCaptureStatus: boolean = true,
) {
  const { data, error, isLoading } = useSWR(
    shouldGetCurrentCaptureStatus
      ? SWRKeysEnum.GET_CURRENT_CAPTURE_STATUS
      : undefined,
    () => CameraApi.getCurrentCaptureStatus(),
    {
      refreshInterval: 1000,
    },
  );

  return {
    statusResponse: data?.data,
    isLoading,
    isError: error,
  };
}
