import { CriteriaGroupXGroupElementsInner } from './CriteriaGroupXGroupElementsInner.model';
import { DateTimeRangeValue } from './DateTimeRangeValue.model';

export interface CriteriaGroupGroupElementsInner {
  /**
   * The array of Criteria Group or Criteria objects.
   */
  groupElements?: Array<CriteriaGroupXGroupElementsInner>;
  /**
   * The owner of this group.
   */
  groupOwner?: string;
  /**
   * Whether this group is an OR group (true) or AND group (false).
   */
  isOr?: boolean;
  /**
   * The key for the criteria.
   */
  key: string;
  /**
   * The operator for the comparison.
   */
  operator: CriteriaGroupGroupElementsInnerOperatorEnum;
  /**
   * The value (for single valued comparison objects).
   */
  value?: any;
  /**
   * The start of the range (for comparison operators that take a range).
   */
  valueFrom?: any;
  /**
   * The end of the range (for comparison operators that take a range).
   */
  valueTo?: any;
  /**
   * The value for relative date range option.
   */
  dateTimeRangeValue?: DateTimeRangeValue;
}

export enum CriteriaGroupGroupElementsInnerOperatorEnum {
  Between = 'Between',
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  Like = 'Like',
  UnLike = 'UnLike',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  IsSet = 'IsSet',
  NotSet = 'NotSet',
}
