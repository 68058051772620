import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal, Row, Stack } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { useHistory, useLocation } from 'react-router-dom';

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { CurrentUserStateTypes } from 'src/store/createUserSlice';
import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { Application } from 'src/models/Application.model';
import { CustomPracticeProfileFieldEnum } from 'src/models/GalenData/CustomField.model';
import { AxiosResponseWithRefreshToken } from 'src/models/GalenData/Request.model';
import { GalenDataUser } from 'src/models/GalenData/User.model';
import { UserRoleEnum } from 'src/models/GalenData/UserRole.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';
import { emailPattern } from 'src/utils/validationRules';

type LoginFormFieldTypes = {
  email: string;
  password: string;
};

interface ILoginFormProps {
  app: Application;
}

const LoginForm = ({ app }: ILoginFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<LoginFormFieldTypes> = (data) => {
    handleLogin(data);
  };

  const [shouldShowOTPInput, setShouldShowOTPInput] = useState<boolean>(false);

  const postLogin = async (user: CurrentUserStateTypes) => {
    setCurrentUser(user);

    // get settings
    try {
      const obj = await Api.remote.load_settings(
        user.username,
        user.userId,
        user.practiceId,
      );

      const settings = obj;

      setSettings(obj);

      if (!settings.logoFile) return;
      const data = await Api.remote._get_device_data({
        deviceDataModelId:
          process.env.REACT_APP_LOGO_DEVICE_DATA_MODEL_ID ?? '',
        deviceDataId: settings.logoFile,
      });

      const link = data.data.data.Logo.link;

      const response = await fetch(link);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result || '';
        setLogo({ logo: base64data as string });
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', `Unable to get settings: ${errorMessage}.`);
    }
  };

  const { setSettings, setLogo, setAuth, setCurrentUser } = useBoundStore();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loggingError, setLoggingError] = useState('');

  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const [xRequestToken, setXRequestToken] = useState('');

  const handleLogin = async ({ email, password }: LoginFormFieldTypes) => {
    setIsLoggingIn(true);
    setLoggingError('');

    try {
      const res = await Api.remote.login(email, password);

      const getXRequestToken = res.headers['x-request-token'];
      setXRequestToken(getXRequestToken);

      if (!res.data) {
        setShouldShowOTPInput(true);
        setCountdownSeconds(30);
        return;
      }

      const userRole = res.data?.currentRole?.role;
      if (
        userRole &&
        ![UserRoleEnum.PracticeUser, UserRoleEnum.PracticeAdmin].includes(
          userRole,
        )
      ) {
        setLoggingError(
          `Access Denied: ${userRole} does not have permission to log in.`,
        );
        setIsLoggingIn(false);
        return;
      }

      await setCurrentUserInfo(res);
    } catch (err) {
      const errorMessage = handleHttpRequestError(err);

      setLoggingError(errorMessage);
    } finally {
      setIsLoggingIn(false);
    }
  };

  async function setCurrentUserInfo(
    res: AxiosResponseWithRefreshToken<GalenDataUser>,
  ) {
    const authToken = res.headers.authorization;

    const refreshToken = res.headers['x-refresh-token'];

    setAuth({
      authToken,
      refreshToken,
    });

    const data = res.data;

    const userType = data?.currentRole?.role;
    const country = data.contactInfo?.country || '';
    const organization = data.currentRole?.practice?.name;
    const practiceId = data.currentRole?.practice?.practiceId;
    const supplierId = data.currentRole?.supplier?.supplierId;
    let lastLogin = null;
    let product = null;
    let camera = null;

    if (data.lastLoginOn) {
      lastLogin = data.lastLoginOn.slice(0, 26) + 'Z';
    }

    if (practiceId) {
      const res =
        await Api.remote._get_custom_field_data<CustomPracticeProfileFieldEnum>(
          practiceId,
        );

      product = res.data.find(
        (field) => field.field.name === CustomPracticeProfileFieldEnum.product,
      )?.fieldData;

      camera = res.data.find(
        (field) => field.field.name === CustomPracticeProfileFieldEnum.camera,
      )?.fieldData;
    }

    const user: CurrentUserStateTypes = {
      username: data.emailAddress ?? '',
      fullName: data.fullName ?? '',
      lastLogin: lastLogin ?? '',
      userId: data.userId ?? '',
      userType,
      country,
      organization: organization ?? '',
      practiceId: practiceId ?? '',
      supplierId: supplierId ?? '',
      product,
      camera,
    };

    console.log(
      `User ${user.username} logged in with product ${user.product} and camera ${user.camera}`,
    );

    await postLogin(user);

    const { from } = location.state || {
      from: {
        pathname: '/session-form',
      },
    };

    history.replace(from);
  }

  const [isShowPassword, setIsShowPassword] = useState(false);

  const [otp, setOtp] = useState('');

  const handleVerifyOTP = async () => {
    try {
      const res = await Api.remote.verifyMFACode(otp, xRequestToken);
      await setCurrentUserInfo(res);
    } catch (err) {
      const errorMessage = handleHttpRequestError(err);

      setLoggingError(errorMessage);
    }
  };

  const [countdownSeconds, setCountdownSeconds] = useState(0);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    if (countdownSeconds > 0) {
      const intervalId = setInterval(() => {
        setIsChanging(true);

        setTimeout(() => {
          setCountdownSeconds((prevSeconds) => prevSeconds - 1);
          setIsChanging(false);
        }, 500);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [countdownSeconds]);

  const handleResendCode = async () => {
    try {
      await Api.remote.sendMFACode(xRequestToken);
      setCountdownSeconds(30);
    } catch (err) {
      const errorMessage = handleHttpRequestError(err);
      setLoggingError(errorMessage);
    }
  };
  return (
    <Modal
      show
      backdrop="static"
      backdropClassName="bg-solid d-print-none"
      animation={false}
      keyboard={false}
      centered
      scrollable={false}
      className="bg-solid d-print-none"
    >
      <Modal.Header closeButton={false} className="d-print-none">
        <Modal.Title
          onClick={() => {
            history.push('/session-form');
          }}
          role="button"
        >
          <img
            src={`/icons/logo-b/${app.product}.png`}
            className="sidebar-icon"
            alt="logo"
          />
          <span className="ms-2">System Login</span>
        </Modal.Title>
      </Modal.Header>
      {shouldShowOTPInput ? (
        <Stack className="justify-content-center p-4" gap={4}>
          <Stack className="text-center" gap={2}>
            <h2>Verify Account</h2>
            <p>
              Please enter the OTP code sent to your registered delivery method
              (MFA delivery mode).
            </p>
          </Stack>
          <Stack gap={4}>
            <OtpInput
              inputType="tel"
              containerStyle={{ display: 'flex', justifyContent: 'center' }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span className="mx-2">-</span>}
              inputStyle={{
                width: '3rem',
                height: '3rem',
                borderRadius: '0.25rem',
                border: '1px solid #ced4da',
                textAlign: 'center',
                fontSize: '1.5rem',
              }}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
            />
            <div>
              {isLoggingIn && (
                <p className="alert alert-info">Logging in, please wait...</p>
              )}

              {loggingError && (
                <p className="alert alert-danger">{loggingError}</p>
              )}
            </div>
            <Row>
              <Button onClick={handleVerifyOTP} disabled={otp.length < 6}>
                Confirm
              </Button>
            </Row>
            <Stack className="justify-content-center">
              {countdownSeconds > 0 ? (
                <p className="text-center">
                  Resend code in{' '}
                  <span
                    className={classNames('d-inline-block', {
                      'slide-out': isChanging,
                    })}
                  >
                    {countdownSeconds}
                  </span>{' '}
                  seconds
                </p>
              ) : (
                <Stack
                  direction="horizontal"
                  className="justify-content-center"
                >
                  <span>Didn't receive the code?</span>
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={handleResendCode}
                  >
                    Resend code.
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)} data-test="login-submit">
          <Modal.Body className="p-5">
            <Form.Group className="mb-3">
              <Form.Label>
                <strong>Email</strong>
              </Form.Label>
              <Form.Control
                id="email"
                type="email"
                placeholder="Type email address"
                autoComplete="off"
                {...register('email', {
                  required: 'Your email address is required.',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email address.',
                  },
                  maxLength: {
                    value: 254,
                    message: 'Email address must be less than 254 characters.',
                  },
                })}
              ></Form.Control>

              {errors.email?.message && (
                <p className="text-danger">{errors.email?.message}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <strong>Password</strong>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id="password"
                  type={isShowPassword ? 'text' : 'password'}
                  placeholder="Type password"
                  autoComplete="off"
                  {...register('password', {
                    required: 'Password is required.',
                    minLength: {
                      value: 8,
                      message: 'Password should be of minimum 8 characters.',
                    },
                    maxLength: {
                      value: 128,
                      message: 'Password must be less than 128 characters.',
                    },
                  })}
                />

                <InputGroup.Text
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  className="pointer"
                >
                  <FontAwesomeIcon icon={isShowPassword ? faEye : faEyeSlash} />
                </InputGroup.Text>
              </InputGroup>
              {errors.password?.message && (
                <p className="text-danger">{errors.password?.message}</p>
              )}
            </Form.Group>

            <div className="mb-3">
              {isLoggingIn && (
                <p className="alert alert-info">Logging in, please wait...</p>
              )}

              {loggingError && (
                <p className="alert alert-danger">{loggingError}</p>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Row>
              <Button
                className="w-200px"
                disabled={isLoggingIn}
                type="submit"
                variant="optain"
              >
                {isLoggingIn ? 'Logging in...' : 'Login'}
              </Button>
            </Row>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default LoginForm;
