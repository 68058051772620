import { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-circular-progressbar/dist/styles.css';
import '@fontsource/archivo-black';
import '@fontsource/dm-sans';
import AboutTab from './components/AboutTab';
import CustomIdleTimer from './components/custom_components/CustomIdleTimer';
import SubUserTab from './components/custom_components/SubUserTab/SubuserTab';
import LoginForm from './components/LoginForm';
import NotFound from './components/NotFound';
import SessionForm from './components/SessionForm/SessionForm';
import SessionHint from './components/SessionHint';
import SessionPreview from './components/SessionPreview/SessionPreview';
import SessionUpload from './components/SessionUpload/SessionUpload';
import SettingsTab from './components/SettingsTab/SettingsTab';
import useWatchDeployment from './hooks/useWatchDeployment';
import { Application } from './models/Application.model';
import { Camera } from './models/Camera.model';
import { CameraMode } from './models/CameraMode.model';
import { initData } from './models/InitialData.model';
import { Product, ProductFullName } from './models/Product.model';
import { Session } from './models/Session.model';
import OptainResults from './products/optain/pages/OptainResults/OptainResults';
import DiabetesData from './products/teleophth/pages/DiabetesData/DiabetesData';
import NewPatientExam from './products/teleophth/pages/NewPatientExam/NewPatientExam';
import TeleophthResults from './products/teleophth/pages/TeleophthResults/TeleophthResults';
import { PrivateRoute } from './routes';
import Sentry from './services/sentry';
import useBoundStore from './store/useBoundStore';

const CLIENT_VERSION = '1.0.0';

if ('$' in window) {
  window.$(() => {
    // dom ready
    window.bootbox.setDefaults({
      closeButton: false,
      animate: false,
    });
  });
}

// client support
const urlParams = new URLSearchParams(window.location.search);
const platform = urlParams.get('platform');
const clientVersion = urlParams.get('client');

if (platform && clientVersion) {
  console.log(`Client platform: ${platform} version: ${clientVersion}`);
}

// client version check
if (clientVersion && clientVersion !== CLIENT_VERSION) {
  console.log(
    `Client version mismatch: current=${clientVersion} required=${CLIENT_VERSION}`,
  );

  alert(`Warning:

Please use the latest version of the client (v${CLIENT_VERSION}).

For additional details, visit https://download.optainhealth.com`);
}

function App() {
  const [init] = useState<boolean>(initData.init);
  const [app] = useState<Application>(initData.app);

  // TODO: Convert to Zustand
  const [tempSession, setTempSession] = useState<Session>(initData.tempSession);

  const [cameraMode, setCameraMode] = useState<CameraMode>(CameraMode.API);
  const [ipList, setIpList] = useState<string[]>([]);

  if (window.appData === undefined) {
    window.appData = {
      setWaiting: (
        waitingResult: boolean,
        waitingId?: string,
        waitingCallback?: any,
      ) => {},
    };
  }

  window.appData.variant = initData.variant;

  const { language, username, authToken, product } = useBoundStore();
  const { startDeploymentWatcher, stopDeploymentWatcher } =
    useWatchDeployment();

  useEffect(() => {
    if (document.title === ProductFullName(app.product)) {
      return;
    }

    // app icon
    const iconLink = document.createElement('link');
    iconLink.rel = 'icon';
    document.head.append(iconLink);
    iconLink.href =
      app.product === Product.OPTAIN
        ? '/icons/app-optain.ico'
        : '/icons/app.ico';

    const appleLink = document.createElement('link');
    appleLink.rel = 'apple-touch-icon';
    document.head.append(appleLink);
    appleLink.href =
      app.product === Product.OPTAIN
        ? '/icons/app-optain.png'
        : '/icons/app.png';

    // app name
    document.title = ProductFullName(app.product);

    // override styles
    document.body.className = `${app.product}-override`;

    setCameraMode(
      app.camera === Camera.SMALL ? CameraMode.API : CameraMode.UPLOAD,
    );

    startDeploymentWatcher();

    return () => {
      stopDeploymentWatcher();
    };
  }, [
    app.product,
    app.camera,
    init,
    language,
    startDeploymentWatcher,
    stopDeploymentWatcher,
  ]);

  Sentry.setTags({
    product: app.product,
    camera: app.camera,
    username,
  });

  return (
    <div className="App">
      <HashRouter>
        <Suspense fallback={null}>
          <Switch>
            <Route path="/login" strict exact>
              <LoginForm app={app}></LoginForm>
            </Route>

            <PrivateRoute path="/" strict exact app={app}>
              {product === Product.TELEOPHTH ? (
                <NewPatientExam
                  session={tempSession}
                  cameraMode={cameraMode}
                  setSession={setTempSession}
                />
              ) : (
                <SessionForm
                  session={tempSession}
                  cameraMode={cameraMode}
                  setSession={setTempSession}
                />
              )}
            </PrivateRoute>

            <PrivateRoute path="/session-form" strict exact app={app}>
              {product === Product.TELEOPHTH ? (
                <NewPatientExam
                  session={tempSession}
                  cameraMode={cameraMode}
                  setSession={setTempSession}
                />
              ) : (
                <SessionForm
                  session={tempSession}
                  cameraMode={cameraMode}
                  setSession={setTempSession}
                />
              )}
            </PrivateRoute>

            <PrivateRoute path="/session-hint" strict exact app={app}>
              <SessionHint session={tempSession} />
            </PrivateRoute>

            <PrivateRoute path="/session-preview" strict exact app={app}>
              <SessionPreview
                session={tempSession}
                setSession={setTempSession}
              />
            </PrivateRoute>

            <PrivateRoute path="/session-upload" strict exact app={app}>
              <SessionUpload
                session={tempSession}
                setSession={setTempSession}
              />
            </PrivateRoute>

            <PrivateRoute path="/diabetes-data" strict exact app={app}>
              <DiabetesData session={tempSession} setSession={setTempSession} />
            </PrivateRoute>

            <PrivateRoute path="/results" strict exact app={app}>
              {product === Product.TELEOPHTH ? (
                <TeleophthResults
                  cameraMode={cameraMode}
                  setSession={setTempSession}
                />
              ) : (
                <OptainResults
                  cameraMode={cameraMode}
                  setSession={setTempSession}
                />
              )}
            </PrivateRoute>

            <PrivateRoute path="/settings" strict exact app={app}>
              <SettingsTab
                cameraMode={cameraMode}
                setCameraMode={setCameraMode}
                ipList={ipList}
                setIpList={setIpList}
              />
            </PrivateRoute>

            <PrivateRoute path="/about" strict exact app={app}>
              <AboutTab app={app} />
            </PrivateRoute>

            <PrivateRoute path="/subuser" strict exact app={app}>
              <SubUserTab />
            </PrivateRoute>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>

        {authToken && <CustomIdleTimer />}
      </HashRouter>
    </div>
  );
}

export default App;
