import { Gender } from 'src/models/Gender.model';
import { ModelEnum } from 'src/models/Model.model';
import { CompletedReasonEnum } from 'src/models/Session.model';

import { DeviceDataValue } from './DeviceDataValue.model';
import { GalenDataUser } from './User.model';

interface ResultJson {
  models: {
    dr_mtm: ModelResult;
    dr_vt: ModelResult;
  };
  results: GradingResults;
}

export interface GradingDetail {
  grading: string;
  conf: number;
}

export interface OptainModels {
  [ModelEnum.DR_US]?: GradingDetail;
}

export interface AssurePlusLargeModels {
  [ModelEnum.DR_EU]?: GradingDetail;
  [ModelEnum.AMD_EU]?: GradingDetail;
  [ModelEnum.GLAUCOMA_EU]?: GradingDetail;
}

export interface AssurePlusSmallModels {
  [ModelEnum.DR_EU_SMALL]?: GradingDetail;
  [ModelEnum.AMD_EU_SMALL]?: GradingDetail;
  [ModelEnum.GLAUCOMA_EU_SMALL]?: GradingDetail;
}

export interface GradingResults
  extends OptainModels,
    AssurePlusLargeModels,
    AssurePlusSmallModels {}

interface ModelResult {
  id: string;
  eye: string;
  output: number[];
  labels: string[];
  failed: boolean;
}

interface Metadata {
  models: string[];
}

export interface ExamData {
  // When submit the session, the images have not been submitted successfully, the status is undefined.
  Status?: DeviceDataValue<string>;
  IsHidden: DeviceDataValue<boolean>;
  ExamSubmissionDatetime: DeviceDataValue<string>;
  LeftEyeResultJson: DeviceDataValue<ResultJson>;
  RightEyeResultJson: DeviceDataValue<ResultJson>;
  UploadStatusComplete: DeviceDataValue<boolean>;
  ReportDatetime: DeviceDataValue<string>;
  LeftImage: DeviceDataValue<string>;
  PracticeUserId: DeviceDataValue<string>;
  SessionId: DeviceDataValue<string>;
  RightImage: DeviceDataValue<string>;
  ExamSubmissionMetadata: DeviceDataValue<Metadata>;
  CompletedTime?: DeviceDataValue<Date>;
  CompletedReason?: DeviceDataValue<CompletedReasonEnum>;
  OrderId: DeviceDataValue<string>;

  // heatmaps
  LeftEyeAmdHeatmap?: DeviceDataValue<string>;
  LeftEyeDrHeatmap?: DeviceDataValue<string>;
  LeftEyeGlaucomaHeatmap?: DeviceDataValue<string>;
  RightEyeAmdHeatmap?: DeviceDataValue<string>;
  RightEyeDrHeatmap?: DeviceDataValue<string>;
  RightEyeGlaucomaHeatmap?: DeviceDataValue<string>;

  // segmentations
  LeftEyeVesselSeg?: DeviceDataValue<string>;
  RightEyeVesselSeg?: DeviceDataValue<string>;

  // dilation time
  LeftEyeDilatedTime?: DeviceDataValue<Date>;
  RightEyeDilatedTime?: DeviceDataValue<Date>;

  Gender: DeviceDataValue<Gender>;
}

export interface DeviceDataView<T> {
  /**
   * The identifier of the device data record.
   */
  deviceDataId?: string;
  /**
   * The identifier of the device data model.
   */
  deviceDataModelId?: string;
  /**
   * The identifier of the owner of this record.
   */
  ownerId?: string;
  /**
   * The name of the owner of this record.
   */
  ownerName?: string;
  /**
   * The full owner object, if requested.
   */
  owner?: GalenDataUser;
  /**
   * The data dictionary with property code as the key.
   */
  // data?: { [key: string]: DeviceDataValue };

  data: T;
  /**
   * The date/time when this record was first created.
   */
  maxValueProvidedOn?: Date;
  /**
   * The date/time when this record was last updated.
   */
  minValueProvidedOn?: Date;
  /**
   * The scope of the datamodel to which this data belongs.
   */
  scope?: DeviceDataViewScopeEnum;
}

export enum DeviceDataViewScopeEnum {
  Patient = 'Patient',
  Practice = 'Practice',
  Supplier = 'Supplier',
  Tenant = 'Tenant',
}

export enum GlobalStatus {
  Null = 'Null',
  Failed = 'Failed',
  InitializerStarted = 'InitializerStarted',
  InitializerEnded = 'InitializerEnded',
  ModelsStarted = 'ModelsStarted',
  ReportStarted = 'ReportStarted',
  ReportEnd = 'ReportEnd',
}

export enum ModelStatus {
  NA = 'NA',
  Triggered = 'Triggered',
  Running = 'Running',
  Complete = 'Complete',
  Failed = 'Failed',
}

export interface BackendStatus {
  GlobalStatus: DeviceDataValue<GlobalStatus>;
  SessionId: DeviceDataValue<string>;
  DrusStatus: DeviceDataValue<ModelStatus>;
  DreuStatus: DeviceDataValue<ModelStatus>;
  AmdeuStatus: DeviceDataValue<ModelStatus>;
  GlaucomaeuStatus: DeviceDataValue<ModelStatus>;
  CvdeuStatus: DeviceDataValue<ModelStatus>;
  CvdauStatus: DeviceDataValue<ModelStatus>;
  AmdeuSmallStatus: DeviceDataValue<ModelStatus>;
  DreuSmallStatus: DeviceDataValue<ModelStatus>;
  GlaucomaeuSmallStatus: DeviceDataValue<ModelStatus>;
  CvdeuSmallStatus: DeviceDataValue<ModelStatus>;
  CvdauSmallStatus: DeviceDataValue<ModelStatus>;
  VesselSegStatus: DeviceDataValue<ModelStatus>;
  GeneralQualityStatus: DeviceDataValue<ModelStatus>;
}
