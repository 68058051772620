import * as Sentry from '@sentry/react';
import axios from 'axios';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';

// 1m
const timeout = 1000 * 60;

export default function useCheckDeployment() {
  const { logOut } = useBoundStore();

  let previousFileContent: string | null = null;

  const checkIndexFileChange = async () => {
    try {
      const response = await axios.get(`/?ts=${Date.now().toString()}`, {
        headers: {
          'Cache-Control': 'no-cache', // Bypass cache
        },
      });

      const currentFileContent = response.data;

      if (previousFileContent && previousFileContent !== currentFileContent) {
        Sentry.captureMessage('New deployment detected');
        Api.alertBox(
          'Warning',
          'A new deployment has been detected. You will be logged out now to apply the latest updates.',
          () => {
            logOut();
          },
        );
      }

      previousFileContent = currentFileContent;
    } catch (err) {
      Sentry.captureException(err);
      console.error('Error checking index file', err);
    }
  };

  let interval: number | null = null;

  const startDeploymentWatcher = () => {
    // already started?
    if (interval) {
      return;
    }

    // initial check
    checkIndexFileChange();

    // start a new interval
    interval = window.setInterval(() => {
      checkIndexFileChange();
    }, timeout);

    console.log('Deployment watcher started');
  };

  const stopDeploymentWatcher = () => {
    // not started?
    if (!interval) {
      return;
    }

    // stop the interval
    window.clearInterval(interval);
    interval = null;

    console.log('Deployment watcher stopped');
  };

  return { startDeploymentWatcher, stopDeploymentWatcher };
}
