enum ReportType {
  CLINICAL = 'clinical',
  PATIENT = 'patient',
}

const ReportTypeName = (type: ReportType): string => {
  switch (type) {
    case ReportType.CLINICAL: {
      return 'Clinical Report';
    }
    case ReportType.PATIENT: {
      return 'Patient Report';
    }
    default: {
      return `Unknown: ${type}`;
    }
  }
};

export { ReportType, ReportTypeName };
