export enum SetLanguageType {
  Voice = 'voice',
  Text = 'text',
}

export interface SetLanguageParams {
  /**
   * language, such as en_US/ko_KR/zh_CN...
   */
  lang: string;
  /**
   * voice or text
   */
  type: string;
}
