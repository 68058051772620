import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { EyesPosition, Session } from 'src/models/Session.model';

import getGradingResult from 'src/utils/getGradingResult';

interface RetakeActionButtonsProps {
  handleUnsetSavedEyePosition: (position: EyesPosition) => void;
  session: Session;
  leftSaved: boolean;
  rightSaved: boolean;
  totalOfImagingEyes: number;
  shouldShowDilationRecommendation: boolean;
  shouldShowReachMaxRecommendedAttempts: boolean;
  handleCloseAnalyzingProgressModal: () => void;
  totalLeftImageAttempt: number;
  totalRightImageAttempt: number;
}

const RetakeActionButtons = ({
  handleUnsetSavedEyePosition,
  session,
  leftSaved,
  rightSaved,
  totalOfImagingEyes,
  shouldShowDilationRecommendation,
  shouldShowReachMaxRecommendedAttempts,
  handleCloseAnalyzingProgressModal,
  totalLeftImageAttempt,
  totalRightImageAttempt,
}: RetakeActionButtonsProps) => {
  const location = useLocation<{
    isResume: boolean;
  }>();

  const { isLeftEyeGradable, isRightEyeGradable, totalGradableEyes } =
    getGradingResult(session);

  return (
    <>
      {totalGradableEyes === 0 && totalOfImagingEyes === 2 && (
        <Button
          className="w-250px"
          variant={
            shouldShowDilationRecommendation ||
            totalLeftImageAttempt === 6 ||
            totalRightImageAttempt === 6 ||
            shouldShowReachMaxRecommendedAttempts
              ? 'outline-info'
              : 'optain'
          }
          onClick={() => {
            handleUnsetSavedEyePosition(EyesPosition.BOTH);
            handleCloseAnalyzingProgressModal();
          }}
          disabled={!session.graded}
        >
          Retake
        </Button>
      )}
      {session.graded &&
        !isLeftEyeGradable &&
        (location.state?.isResume ? session.leftImageFile : leftSaved) &&
        ((totalOfImagingEyes === 2 &&
          !isLeftEyeGradable &&
          isRightEyeGradable) ||
          (totalOfImagingEyes === 1 && !isLeftEyeGradable)) && (
          <Button
            variant={
              shouldShowDilationRecommendation ||
              shouldShowReachMaxRecommendedAttempts
                ? 'outline-info'
                : 'optain'
            }
            className="w-250px"
            onClick={() => {
              handleUnsetSavedEyePosition(EyesPosition.LEFT);
              handleCloseAnalyzingProgressModal();
            }}
            disabled={!session.graded}
          >
            Retake Left Eye
          </Button>
        )}

      {session.graded &&
        (location.state?.isResume ? session.rightImageFile : rightSaved) &&
        ((totalOfImagingEyes === 2 &&
          !isRightEyeGradable &&
          isLeftEyeGradable) ||
          (totalOfImagingEyes === 1 && !isRightEyeGradable)) && (
          <Button
            variant={
              shouldShowDilationRecommendation ||
              shouldShowReachMaxRecommendedAttempts
                ? 'outline-info'
                : 'optain'
            }
            className="w-250px"
            onClick={() => {
              handleUnsetSavedEyePosition(EyesPosition.RIGHT);
              handleCloseAnalyzingProgressModal();
            }}
            disabled={!session.graded}
          >
            Retake Right Eye
          </Button>
        )}
    </>
  );
};

export default RetakeActionButtons;
