export interface CustomField<T> {
  /**
   * The identifier of the custom field. Its auto generated on create if not provided.
   */
  fieldId?: string;
  /**
   * The identifier of the tenant to which this field belongs.
   */
  tenantId: string;
  /**
   * The name of the custom field.
   */
  name: T;
  /**
   * The are of the custom field.
   */
  area: CustomFieldAreaEnum;
  /**
   * The data type of the custom field.
   */
  type: CustomFieldTypeEnum;
  /**
   * The optio values. Only applicable for types that require picking from a list of values.
   */
  optionValues?: Array<string>;
  /**
   * Whether the field is required or not.
   */
  required?: boolean;
  /**
   * The Regex for validation of the field data.
   */
  validationExpression?: string;
  /**
   * The display order.
   */
  displayOrder?: number;
  /**
   * The identifier of the user who created this custom field.
   */
  createdBy?: string;
  /**
   * The date/time at which this custom field was created.
   */
  createdOn?: Date;
  /**
   * The identifier of the user who last updated this custom field.
   */
  lastUpdatedBy?: string;
  /**
   * The date/time at which this custom field was last updated.
   */
  lastUpdatedOn?: Date;
}

export enum CustomFieldAreaEnum {
  PracticeProfile = 'PracticeProfile',
  SupplierProfile = 'SupplierProfile',
  TenantProfile = 'TenantProfile',
  UserProfile = 'UserProfile',
  DeviceInstanceProfile = 'DeviceInstanceProfile',
  FirmwareProfile = 'FirmwareProfile',
}
export enum CustomFieldTypeEnum {
  Date = 'Date',
  DateTime = 'DateTime',
  MultiLineText = 'MultiLineText',
  MultiSelect = 'MultiSelect',
  SingleLineText = 'SingleLineText',
  SingleSelect = 'SingleSelect',
}

export enum CustomPracticeProfileFieldEnum {
  language = 'language',
  reportGender = 'reportGender',
  reportHeatmap = 'reportHeatmap',
  reportLogo = 'reportLogo',
  logoFile = 'logoFile',
  billingCode = 'billingCode',
  watermark = 'watermark',
  broadcast = 'broadcast',
  product = 'product',
  camera = 'camera',
}

export enum CustomUserProfileFieldEnum {
  folder = 'folder',
  userPermission = 'userPermission',
}
