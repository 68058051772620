import { Disease } from './Disease.model';
import { handleNSI18Next } from './Language.model';
import { Result } from './Result.model';
import { Session } from './Session.model';

enum Grading {
  // empty
  EMPTY = '',
  UNGRADEABLE = 'ungradeable',
  ERROR = 'error',

  // binary
  NEGATIVE = 'negative',
  POSITIVE = 'positive',

  // glaucoma
  LOW_RISK = 'low risk',
  MODERATE_RISK = 'moderate risk',
  HIGH_RISK = 'high risk',

  // amd
  NEGATIVE_OR_EARLY = 'negative or early amd',
  INTERMEDIATE = 'intermediate amd',
  LATE_DRY = 'late dry amd',
  LATE_WET = 'late wet amd',

  // dr
  NO_DR = 'no dr',
  MILD_NPDR = 'mild npdr',
  MODERATE_NPDR = 'moderate npdr',
  SEVERE_NPDR = 'severe npdr',
  PROLIFERATIVE_DR = 'proliferative dr',
  MTMDR = 'mtmDR',
  VTDR = 'vtDR',

  // atherosclerosis
  NO_ATHEROSCLEROSIS = 'no atherosclerosis',
  ATHEROSCLEROSIS = 'atherosclerosis',
  MILD_ATHEROSCLEROSIS = 'mild atherosclerosis',
  MODERATE_ATHEROSCLEROSIS = 'moderate atherosclerosis',
  SEVERE_ATHEROSCLEROSIS = 'severe atherosclerosis',

  // common, common_borderline
  NORMAL = 'normal',
  MYOPIC_MACULOPATHY = 'myopic maculopathy',
  FIBROVASCULAR_PROLIFERATION = 'fibrovascular proliferation',
  MACULAR_HOLE = 'macular hole',
  EPIRETINAL_MEMBRANE = 'epiretinal membrane',
  RETINAL_DETACHMENT = 'retinal detachment',
  RETINAL_VEIN_OCCLUSION = 'retinal vein occlusion',
  SUBRETINAL_HAEMORRHAGE = 'subretinal haemorrhage',
  OPTIC_DISC_ATROPHY = 'optic disc atrophy',
  OPTIC_DISC_EDEMA = 'optic disc edema',

  // fundus
  GOOD = 'good',
  USABLE = 'usable',
  REJECT = 'reject',
}

const gradingNames: { [key in Grading]?: string } = {
  // empty: invalid
  // [Grading.EMPTY]: '',

  // binary
  [Grading.NEGATIVE]: 'Negative',
  [Grading.POSITIVE]: 'Positive',

  // glaucoma
  [Grading.LOW_RISK]: 'Low risk',
  [Grading.MODERATE_RISK]: 'Moderate risk',
  [Grading.HIGH_RISK]: 'High risk',

  // amd
  [Grading.NEGATIVE_OR_EARLY]: 'Negative or early',
  [Grading.INTERMEDIATE]: 'Intermediate',
  [Grading.LATE_DRY]: 'Late dry',
  [Grading.LATE_WET]: 'Late wet',

  // dr
  [Grading.NO_DR]: 'No DR',
  [Grading.MILD_NPDR]: 'Mild NPDR',
  [Grading.MODERATE_NPDR]: 'Moderate NPDR',
  [Grading.SEVERE_NPDR]: 'Severe NPDR',
  [Grading.PROLIFERATIVE_DR]: 'Proliferative DR',

  // atherosclerosis
  [Grading.NO_ATHEROSCLEROSIS]: 'No atherosclerosis',
  [Grading.ATHEROSCLEROSIS]: 'Atherosclerosis',
  [Grading.MILD_ATHEROSCLEROSIS]: 'Mild atherosclerosis',
  [Grading.MODERATE_ATHEROSCLEROSIS]: 'Moderate atherosclerosis',
  [Grading.SEVERE_ATHEROSCLEROSIS]: 'Severe atherosclerosis',

  // advanced vision
  [Grading.MYOPIC_MACULOPATHY]: 'Myopic maculopathy',
  [Grading.FIBROVASCULAR_PROLIFERATION]: 'Fibrovascular proliferation',
  [Grading.MACULAR_HOLE]: 'Macular hole',
  [Grading.EPIRETINAL_MEMBRANE]: 'Epiretinal membrane',
  [Grading.RETINAL_DETACHMENT]: 'Retinal detachment',
  [Grading.RETINAL_VEIN_OCCLUSION]: 'Retinal vein occlusion',
  [Grading.SUBRETINAL_HAEMORRHAGE]: 'Subretinal haemorrhage',
  [Grading.OPTIC_DISC_ATROPHY]: 'Optic disc atrophy',
  [Grading.OPTIC_DISC_EDEMA]: 'Optic disc edema',

  // fundus
  [Grading.GOOD]: 'Good',
  [Grading.USABLE]: 'Usable',
  [Grading.REJECT]: 'Reject',
};

const GradingName = (grading: Grading | number | Grading[]): string => {
  // number -> string
  if (typeof grading === 'number') {
    return `${grading}`;
  }

  if (Array.isArray(grading)) {
    return grading.map(GradingName).join(',');
  }

  return gradingNames[grading] || `Unknown: ${grading}`;
};

const GradingIsNumber = (grading: Grading | number | Grading[]): boolean => {
  return typeof grading === 'number';
};

const GradingIsNegative = (grading: Grading | number | Grading[]): boolean => {
  // number grading results are always negative
  if (GradingIsNumber(grading)) {
    return true;
  }

  if (Array.isArray(grading)) {
    // advanced vision
    return grading.length === 0;
  }

  return (
    grading === Grading.NEGATIVE ||
    grading === Grading.LOW_RISK ||
    grading === Grading.NO_DR ||
    grading === Grading.NO_ATHEROSCLEROSIS ||
    grading === Grading.NEGATIVE_OR_EARLY ||
    grading === Grading.GOOD ||
    grading === Grading.USABLE
  );
};

const GradingIsPositive = (grading: Grading | number | Grading[]): boolean => {
  return !GradingIsNegative(grading);
};

interface GradingTextObjet {
  title: string;
  description: string;
  precaution: string;
  suggestion: string;
  tips: string[];
}

const HasPositiveHeatmap = (session: Session) => {
  if (session.leftResults !== undefined) {
    for (let i = 0; i < session.leftResults?.length; i++) {
      if (GradingIsPositive(session.leftResults[i].grading)) {
        return true;
      }
    }
  }

  if (session.rightResults !== undefined) {
    for (let i = 0; i < session.rightResults?.length; i++) {
      if (GradingIsPositive(session.rightResults[i].grading)) {
        return true;
      }
    }
  }

  return false;
};

const GradingText = (grading: Grading): GradingTextObjet => {
  switch (grading) {
    // dr
    case Grading.MILD_NPDR: {
      return {
        title: 'Mild diabetic retinopathy',
        description:
          'Signs of mild diabetic retinal changes include minor haemorrhages.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of diabetic eye changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment. ',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Annual eye checks are recommended for people who are living with diabetes.',
        ],
      };
    }
    case Grading.MODERATE_NPDR: {
      return {
        title: 'Moderate diabetic retinopathy',
        description:
          'Signs of moderate diabetic retinal changes include haemorrhages and fluid leakage from blood vessels.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of diabetic eye changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment. ',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Annual eye checks are recommended for people who are living with diabetes.',
        ],
      };
    }
    case Grading.SEVERE_NPDR: {
      return {
        title: 'Severe diabetic retinopathy',
        description:
          'Signs of severe diabetic retinal changes include haemorrhages and fluid leakage from blood vessels.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of diabetic eye changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment. ',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Annual eye checks are recommended for people who are living with diabetes.',
        ],
      };
    }
    case Grading.PROLIFERATIVE_DR: {
      return {
        title: 'Proliferative diabetic retinopathy',
        description:
          'Signs of proliferative diabetic retinopathy include haemorrhages, fluid leakage from blood vessels and growth of new vessels.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of diabetic eye changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment. ',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Annual eye checks are recommended for people who are living with diabetes.',
        ],
      };
    }

    // amd
    case Grading.INTERMEDIATE: {
      return {
        title: 'Intermediate ARMD',
        description:
          'Small or medium-size of yellow, fatty deposits (called drusen) or pigment changes on the macula may be observed.',
        precaution:
          'Macular changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of macular changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment.',
        tips: [
          'Age-related macular degeneration can cause changes to your central vision.',
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }
    case Grading.LATE_DRY: {
      return {
        title: 'Advanced Dry ARMD',
        description:
          'More serious type of ARMD, characterised by regions of atrophy (breakdown of cells) that resembles a map.  This may lead to loss of central vision.',
        precaution:
          'Macular changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of macular changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment.',
        tips: [
          'Age-related macular degeneration can cause changes to your central vision.',
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }
    case Grading.LATE_WET: {
      return {
        title: 'Advanced Wet (neovascular) ARMD',
        description:
          'Most aggressive type of ARMD, typically with abnormal new blood vessel growth which may haemorrhage and leak fluid.  Often a rapid loss of vision is experienced.',
        precaution:
          'Macular changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of macular changes, and intervention if required, may help to prevent progression of the underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment.',
        tips: [
          'Age-related macular degeneration can cause changes to your central vision.',
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    // glaucoma
    case Grading.MODERATE_RISK: {
      return {
        title: 'Glaucoma moderate risk',
        description:
          'Characteristic changes to the optic nerve are observed that suggest a moderate risk of having glaucoma.',
        precaution:
          'Changes suggestive of glaucoma are present in the retina, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of an optic nerve condition, and intervention if required, may help to prevent progression of an underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment. ',
        tips: [
          'Regular health checks are recommended as part of a healthy lifestyle, including regular eye tests to help ensure good vision for life.',
        ],
      };
    }
    case Grading.HIGH_RISK: {
      return {
        title: 'Glaucoma high risk',
        description:
          'Characteristic changes to the optic nerve are observed that suggest a high risk of having glaucoma.',
        precaution:
          'Changes suggestive of glaucoma are present in the retina, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Early detection of an optic nerve condition, and intervention if required, may help to prevent progression of an underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment. ',
        tips: [
          'Regular health checks are recommended as part of a healthy lifestyle, including regular eye tests to help ensure good vision for life.',
        ],
      };
    }

    // atherosclerosis
    case Grading.MILD_ATHEROSCLEROSIS: {
      return {
        title: 'Mild atherosclerosis',
        description:
          'The light reflex from the retinal small arteries is increased, and/or other distinctive signs of atherosclerosis.',
        precaution:
          'Retinal blood vessel changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Changes observed in blood vessels in the retina of your eye mirror changes that may be taking place in other parts of the body.  Early detection and intervention may help to prevent progression of an underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment.',
        tips: [
          'Regular exercise, healthy weight and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for cardiovascular disease.',
        ],
      };
    }
    case Grading.MODERATE_ATHEROSCLEROSIS: {
      return {
        title: 'Moderate atherosclerosis',
        description:
          'The light reflex from the retinal small arteries is increased, and/or other distinctive signs of atherosclerosis.',
        precaution:
          'Retinal blood vessel changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Changes observed in blood vessels in the retina of your eye mirror changes that may be taking place in other parts of the body.  Early detection and intervention may help to prevent progression of an underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment.',
        tips: [
          'Regular exercise, healthy weight and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for cardiovascular disease.',
        ],
      };
    }
    case Grading.SEVERE_ATHEROSCLEROSIS: {
      return {
        title: 'Severe atherosclerosis',
        description:
          'The light reflex from the retinal small arteries is increased, and/or other distinctive signs of atherosclerosis.',
        precaution:
          'Retinal blood vessel changes are present, which indicates further investigation may be required.  Please discuss with your optometrist.',
        suggestion:
          'Changes observed in blood vessels in the retina of your eye mirror changes that may be taking place in other parts of the body.  Early detection and intervention may help to prevent progression of an underlying condition.  Discuss with your optometrist about seeing a doctor for further assessment.',
        tips: [
          'Regular exercise, healthy weight and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for cardiovascular disease.',
        ],
      };
    }

    // advanced vision
    case Grading.MYOPIC_MACULOPATHY: {
      return {
        title: 'Myopic maculopathy',
        description:
          'Degeneration and damage to the macular region of the retina is observed, which is characteristic of changes due to high myopia.',
        precaution:
          'Myopia is often a progressive disorder.  Macular changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of macular and other retinal changes, and intervention if required, may help to prevent progression. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    case Grading.FIBROVASCULAR_PROLIFERATION: {
      return {
        title: 'Fibrovascular proliferation',
        description:
          'Signs of fibrovascular proliferation include haemorrhages, fluid leakage from blood vessels and growth of new vessels.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Regular eye checks are recommended to help ensure good vision for life.',
        ],
      };
    }

    case Grading.MACULAR_HOLE: {
      return {
        title: 'Macular hole',
        description: 'A small break, or hole, in the macula is observed.',
        precaution:
          'Macular changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of macular changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    case Grading.EPIRETINAL_MEMBRANE: {
      return {
        title: 'Epiretinal membrane',
        description:
          'Fibrous tissue on the surface of the macular area of the retina has been oberved.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    case Grading.RETINAL_DETACHMENT: {
      return {
        title: 'Retinal detachment',
        description:
          'A large tear, crack or break in the retina is observed that may be pulling away from the underlying tissue.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    case Grading.RETINAL_VEIN_OCCLUSION: {
      return {
        title: 'Retinal vein occlusion',
        description:
          'Signs of a blockage of a retinal vein are observed which may indicate an underlying vascular disorder.',
        precaution:
          'Retinal blood vessel changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Regular eye checks are recommended to help ensure good vision for life.',
        ],
      };
    }

    case Grading.SUBRETINAL_HAEMORRHAGE: {
      return {
        title: 'Subretinal haemorrhage',
        description:
          'Leakage of blood is observed under the retinal layer of the eye.',
        precaution:
          'Retinal changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular exercise and a balanced diet are recommended as part of a healthy lifestyle.',
          'Avoid smoking and alcohol which are risk factors for retinal disease.',
          'Regular eye checks are recommended to help ensure good vision for life.',
        ],
      };
    }

    case Grading.OPTIC_DISC_ATROPHY: {
      return {
        title: 'Optic disc atrophy',
        description:
          'The optic disc is pale which may indicate degeneration of, or damage to, the optic nerve.',
        precaution:
          'Optic nerve changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    case Grading.OPTIC_DISC_EDEMA: {
      return {
        title: 'Optic disc oedema',
        description:
          'Signs of swelling and damage to the optic nerve are observed.',
        precaution:
          'Optic nerve changes are present, which indicates further investigation may be required. Please discuss with your optometrist.',
        suggestion:
          'Early detection of retinal changes, and intervention if required, may help to prevent progression of any underlying condition. Your optometrist will advise if further consultation is recommended.',
        tips: [
          'Regular eye checks are recommended to help ensure good vision for life.',
          'Seek advice from your optometrist if vision changes occur.',
        ],
      };
    }

    default: {
      return {
        title: `Unknown grading: ${grading}`,
        description: `Unknown grading: ${grading}`,
        precaution: `Unknown grading: ${grading}`,
        suggestion: `Unknown grading: ${grading}`,
        tips: [`Unknown grading: ${grading}`],
      };
    }
  }
};

const GradingSummaryText = (session: Session): string[] => {
  const { handleCommonT } = handleNSI18Next();

  const text: any = {};

  text[Disease.GLAUCOMA] = {};
  text[Disease.AMD] = {};
  text[Disease.DR] = {};
  text[Disease.VTDR] = {};

  text[Disease.GLAUCOMA][Grading.LOW_RISK] = {
    od: handleCommonT('gradingSummaryText.GLAUCOMA.lowRisk.OD'),
    os: handleCommonT('gradingSummaryText.GLAUCOMA.lowRisk.OS'),
    ou: handleCommonT('gradingSummaryText.GLAUCOMA.lowRisk.OU'),
  };

  text[Disease.GLAUCOMA][Grading.MODERATE_RISK] = {
    od: handleCommonT('gradingSummaryText.GLAUCOMA.mediumRisk.OD'),
    os: handleCommonT('gradingSummaryText.GLAUCOMA.mediumRisk.OS'),
    ou: handleCommonT('gradingSummaryText.GLAUCOMA.mediumRisk.OU'),
  };

  text[Disease.GLAUCOMA][Grading.HIGH_RISK] = {
    od: handleCommonT('gradingSummaryText.GLAUCOMA.highRisk.OD'),
    os: handleCommonT('gradingSummaryText.GLAUCOMA.highRisk.OS'),
    ou: handleCommonT('gradingSummaryText.GLAUCOMA.highRisk.OU'),
  };

  text[Disease.GLAUCOMA]['ungradable'] = {
    od: handleCommonT('gradingSummaryText.GLAUCOMA.ungradable.OD'),
    os: handleCommonT('gradingSummaryText.GLAUCOMA.ungradable.OS'),
    ou: handleCommonT('gradingSummaryText.GLAUCOMA.ungradable.OU'),
  };

  text[Disease.AMD][Grading.NEGATIVE_OR_EARLY] = {
    od: handleCommonT('gradingSummaryText.AMD.negativeOrEarly.OD'),
    os: handleCommonT('gradingSummaryText.AMD.negativeOrEarly.OS'),
    ou: handleCommonT('gradingSummaryText.AMD.negativeOrEarly.OU'),
  };

  text[Disease.AMD][Grading.INTERMEDIATE] = {
    od: handleCommonT('gradingSummaryText.AMD.intermediate.OD'),
    os: handleCommonT('gradingSummaryText.AMD.intermediate.OS'),
    ou: handleCommonT('gradingSummaryText.AMD.intermediate.OU'),
  };

  text[Disease.AMD][Grading.LATE_DRY] = {
    od: handleCommonT('gradingSummaryText.AMD.lateDry.OD'),
    os: handleCommonT('gradingSummaryText.AMD.lateDry.OS'),
    ou: handleCommonT('gradingSummaryText.AMD.lateDry.OU'),
  };

  text[Disease.AMD][Grading.LATE_WET] = {
    od: handleCommonT('gradingSummaryText.AMD.lateWet.OD'),
    os: handleCommonT('gradingSummaryText.AMD.lateWet.OS'),
    ou: handleCommonT('gradingSummaryText.AMD.lateWet.OU'),
  };

  text[Disease.AMD][Grading.POSITIVE] = {
    od: handleCommonT('gradingSummaryText.AMD.positive.OD'),
    os: handleCommonT('gradingSummaryText.AMD.positive.OS'),
    ou: handleCommonT('gradingSummaryText.AMD.positive.OU'),
  };

  text[Disease.AMD]['ungradable'] = {
    od: handleCommonT('gradingSummaryText.AMD.ungradable.OD'),
    os: handleCommonT('gradingSummaryText.AMD.ungradable.OS'),
    ou: handleCommonT('gradingSummaryText.AMD.ungradable.OU'),
  };

  text[Disease.DR][Grading.NEGATIVE] = text[Disease.VTDR][Grading.NEGATIVE] = {
    od: handleCommonT('gradingSummaryText.DROrREFDR.negative.OD'),
    os: handleCommonT('gradingSummaryText.DROrREFDR.negative.OS'),
    ou: handleCommonT('gradingSummaryText.DROrREFDR.negative.OU'),
  };

  text[Disease.DR][Grading.POSITIVE] = text[Disease.VTDR][Grading.POSITIVE] = {
    od: handleCommonT('gradingSummaryText.DROrREFDR.positive.OD'),
    os: handleCommonT('gradingSummaryText.DROrREFDR.positive.OS'),
    ou: handleCommonT('gradingSummaryText.DROrREFDR.positive.OU'),
  };

  text[Disease.DR]['ungradable'] = text[Disease.VTDR]['ungradable'] = {
    od: handleCommonT('gradingSummaryText.DROrREFDR.ungradable.OD'),
    os: handleCommonT('gradingSummaryText.DROrREFDR.ungradable.OS'),
    ou: handleCommonT('gradingSummaryText.DROrREFDR.ungradable.OU'),
  };

  const diseases = [Disease.GLAUCOMA, Disease.DR, Disease.AMD];

  return diseases.map((disease) => {
    let odGrading = FindGrading(session.rightResults, disease) || 'ungradable';
    let osGrading = FindGrading(session.leftResults, disease) || 'ungradable';

    if (disease === Disease.DR && odGrading !== 'ungradable') {
      odGrading = GradingIsPositive(odGrading as Grading)
        ? Grading.POSITIVE
        : Grading.NEGATIVE;
    }

    if (disease === Disease.DR && osGrading !== 'ungradable') {
      osGrading = GradingIsPositive(osGrading as Grading)
        ? Grading.POSITIVE
        : Grading.NEGATIVE;
    }

    if (odGrading === osGrading) {
      return text[disease][osGrading as Grading]['ou'];
    } else {
      const odGradingText = text[disease][odGrading as Grading]['od'];
      const osGradingText = text[disease][osGrading as Grading]['os'];

      return [odGradingText, osGradingText].join(' ');
    }
  });
};

const FindResult = (results: Result[] | undefined, disease: Disease) => {
  const result = results?.find((result) => result.disease === disease);

  if (
    result === undefined ||
    result.error !== undefined ||
    result.gradable === false
  ) {
    return;
  }

  return result;
};

const FindGrading = (results: Result[] | undefined, disease: Disease) => {
  const result = FindResult(results, disease);

  if (
    result === undefined ||
    result.error !== undefined ||
    result.gradable === false
  ) {
    return;
  }

  return result.grading;
};

export {
  FindGrading,
  FindResult,
  Grading,
  GradingIsNegative,
  GradingIsNumber,
  GradingIsPositive,
  GradingName,
  GradingSummaryText,
  GradingText,
  HasPositiveHeatmap,
};
