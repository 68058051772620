import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'English',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['common', 'report'],
    lng: 'English',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
