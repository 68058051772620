import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';

import useBoundStore from 'src/store/useBoundStore';

// 60m
const timeout = 1000 * 60 * 60;
// 30s
const promptBeforeIdle = 1000 * 30;

export default function CustomIdleTimer() {
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const { logOut } = useBoundStore();

  const onIdle = () => {
    setOpen(false);
    logOut();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <Modal show={open}>
      <Modal.Header closeButton>
        <Modal.Title>Session Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your session will automatically log out in {remaining} seconds.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleStillHere}>
          I'm here, keep me logged in.
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
