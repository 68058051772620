export enum SWRKeysEnum {
  GET_CAMERA_SETTINGS = 'get-camera-settings',
  GET_CURRENT_CAPTURE_STATUS = 'get-current-capture-status',
  GET_CURRENT_USER = 'get-current-user',
  GET_CAMERA_LANGUAGES = 'get-camera-languages',
  CONTINUE_CAPTURE_PROCESS = 'continue-capture-process',
  GET_CAMERA_CONNECTIVITY = 'get-camera-connectivity',
  GET_BACKEND_STATE = 'get-backend-state',
  LOAD_SESSION = 'load-session',
}
