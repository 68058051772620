// each report template is fixed, list all posibilities
enum ReportTemplate {
  ASSURE_LARGE_EYE = 'assure_large_eye', // clinical and patient in one report
  ASSURE_SMALL_EYE = 'assure_small_eye', // clinical and patient in one report

  ASSUREPLUS_LARGE_EYE_CVD = 'assureplus_large_eye_cvd', // clinical and patient in one report
  ASSUREPLUS_SMALL_EYE_CVD = 'assureplus_small_eye_cvd', // clinical and patient in one report

  ASSUREPLUS_LARGE_EYE_CVD_AUSCVD = 'assureplus_large_eye_cvd_auscvd', // clinical and patient in one report
  ASSUREPLUS_SMALL_EYE_CVD_AUSCVD = 'assureplus_small_eye_cvd_auscvd', // clinical and patient in one report

  OPTAIN_SMALL_DR = 'optain_small_dr',

  TELEOPHTH = 'teleophth_small',
}

enum ReportTemplateNameEnum {
  EYE_DISEASES = 'Eye diseases',
  EYE_DISEASES_CVD = 'Eye diseases + Cardiovascular health',
  DIABETIC_RETINOPATHY = 'Diabetic Retinopathy',
  TELEOPHTH = 'Teleophthalmology',
}

const ReportTemplateName = (template: ReportTemplate): string => {
  switch (template) {
    case ReportTemplate.ASSURE_LARGE_EYE:
    case ReportTemplate.ASSURE_SMALL_EYE: {
      return ReportTemplateNameEnum.EYE_DISEASES;
    }

    case ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD:
    case ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD:
    case ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD_AUSCVD:
    case ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD_AUSCVD: {
      return ReportTemplateNameEnum.EYE_DISEASES_CVD;
    }

    case ReportTemplate.OPTAIN_SMALL_DR: {
      return ReportTemplateNameEnum.DIABETIC_RETINOPATHY;
    }

    case ReportTemplate.TELEOPHTH: {
      return ReportTemplateNameEnum.TELEOPHTH;
    }

    default: {
      return `Unknown template: ${template}`;
    }
  }
};

export { ReportTemplate, ReportTemplateName };
