import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import Sentry from 'src/services/sentry';
import useSWR from 'swr';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { GlobalStatus } from 'src/models/GalenData/DeviceDataView.model';
import { defaultBackendStatus } from 'src/models/InitialBackendStatus';
import { ModelEnum } from 'src/models/Model.model';
import { Product } from 'src/models/Product.model';
import { EyesPosition, Session } from 'src/models/Session.model';
import { SWRKeysEnum } from 'src/models/SWRKeys.model';

import useActionAnalyzeModel from 'src/hooks/useActionAnalyzeModel';
import useGetSessions from 'src/hooks/useGetSessions';

import getGradingResult from 'src/utils/getGradingResult';

import ProgressModalBody from './ProgressModalBody';
import ProgressModalFooter from './ProgressModalFooter';

type AnalyzingProgressModalProps = {
  leftSaved: boolean;
  rightSaved: boolean;

  handleUnsetSavedEyePosition: (position: EyesPosition) => void;

  isShowAnalyzingProgressModal: boolean;
  handleCloseAnalyzingProgressModal: () => void;
  session: Session;

  handleResetSession: () => void;

  handleSetLeftUrl: (leftUrl: string) => void;
  handleSetRightUrl: (rightUrl: string) => void;

  sessionId: string;
  deviceDataId: string;
  models: ModelEnum[];

  setSession: React.Dispatch<React.SetStateAction<Session>>;
  resubmitExistingSession: (iid: string) => void;
};
export default function AnalyzingProgressModal({
  leftSaved,
  rightSaved,
  handleUnsetSavedEyePosition,
  isShowAnalyzingProgressModal,
  handleCloseAnalyzingProgressModal,
  session,
  handleResetSession,
  sessionId,
  deviceDataId,
  models,
  setSession,
  resubmitExistingSession,
}: AnalyzingProgressModalProps) {
  const location = useLocation<{
    isResume: boolean;
  }>();

  const { product } = useBoundStore();

  const [refreshCount, setRefreshCount] = useState(0);
  const refreshTime = 500;
  const maxTime = 120;

  const fetcher = async (fetcherSessionId: string) => {
    setRefreshCount(refreshCount + 1);

    if (refreshCount * refreshTime === maxTime * 1000) {
      Api.confirmBox({
        title: 'Error',
        message:
          'The server failed to return a result for the exam submission within the expected time. Would you like to resubmit?',
        buttons: {
          confirm: {
            label: 'Resubmit',
            className: 'btn-primary',
          },
          cancel: {
            label: 'Continue Waiting',
            className: 'btn-secondary',
          },
        },
        callback: (result: boolean) => {
          if (result) {
            resubmitExistingSession(deviceDataId);
          }
        },
      });
    }

    try {
      return await Api.remote.getBackendState(fetcherSessionId);
    } catch (error) {
      Api.alertBox(
        'Error',
        'Something is wrong with the server. Please try again later.',
      );
      Sentry.captureException(error);
    }
  };

  const backendState = useSWR(
    [SWRKeysEnum.GET_BACKEND_STATE, sessionId],
    () => fetcher(sessionId),
    {
      revalidateOnFocus: false,
      refreshInterval: !session.graded && sessionId ? refreshTime : 0,
      dedupingInterval: refreshTime,
    },
  );

  const isBackendDone =
    backendState.data?.data?.content?.[0]?.data?.GlobalStatus.value ===
    GlobalStatus.ReportEnd;

  const updatedSession = useSWR(
    isBackendDone ? SWRKeysEnum.LOAD_SESSION : null,
    () => Api.remote.load_session({ deviceDataId, product }),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (updatedSession.data) {
      setSession(updatedSession.data);
    }
  }, [updatedSession.data, setSession]);

  const totalOfImagingEyes = useMemo(
    () =>
      location.state?.isResume
        ? (session.leftImageFile ? 1 : 0) + (session.rightImageFile ? 1 : 0)
        : (leftSaved ? 1 : 0) + (rightSaved ? 1 : 0),
    [
      leftSaved,
      location.state?.isResume,
      rightSaved,
      session.leftImageFile,
      session.rightImageFile,
    ],
  );

  const { totalGradableEyes } = getGradingResult(session);

  const hasCompletedExam = totalOfImagingEyes === totalGradableEyes;

  const { getSessions, totalLeftImageAttempt, totalRightImageAttempt } =
    useGetSessions(session);

  const [isFindingRetakeSessions, setIsFindingRetakeSessions] = useState(false);

  const handleSetIsFindingRetakeSessions = (
    isFindingRetakeSessions: boolean,
  ) => {
    setIsFindingRetakeSessions(isFindingRetakeSessions);
  };

  useEffect(() => {
    getSessions(handleSetIsFindingRetakeSessions);
  }, [getSessions]);

  const shouldShowDilationRecommendation =
    (totalLeftImageAttempt >= 3 || totalRightImageAttempt >= 3) &&
    totalLeftImageAttempt < 6 &&
    totalRightImageAttempt < 6 &&
    !hasCompletedExam;

  const shouldShowReachMaxRecommendedAttempts =
    totalLeftImageAttempt >= 6 || totalRightImageAttempt >= 6;

  const { handleCompleteExam, isUpdating } = useActionAnalyzeModel(
    handleCloseAnalyzingProgressModal,
    handleResetSession,
    session,
  );

  return (
    <>
      <Modal
        backdrop="static"
        show={isShowAnalyzingProgressModal}
        onHide={handleCloseAnalyzingProgressModal}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Stack>
            <Modal.Title>Analyzing Fundus Images</Modal.Title>
            <p className="mt-2 mb-0">
              Imaging Attempts: Left Eye {totalLeftImageAttempt} | Right Eye{' '}
              {totalRightImageAttempt}
            </p>
          </Stack>

          {product === Product.TELEOPHTH &&
            session.graded &&
            !isFindingRetakeSessions &&
            !hasCompletedExam && (
              <Button
                variant="outline-warning"
                disabled={isUpdating}
                onClick={handleCompleteExam}
              >
                Submit as Ungradable
              </Button>
            )}
        </Modal.Header>
        <ProgressModalBody
          session={session}
          shouldShowDilationRecommendation={shouldShowDilationRecommendation}
          shouldShowReachMaxRecommendedAttempts={
            shouldShowReachMaxRecommendedAttempts
          }
          status={
            backendState.data?.data?.content?.[0]?.data ?? defaultBackendStatus
          }
          models={models}
        />
        <ProgressModalFooter
          handleUnsetSavedEyePosition={handleUnsetSavedEyePosition}
          session={session}
          leftSaved={leftSaved}
          rightSaved={rightSaved}
          totalOfImagingEyes={totalOfImagingEyes}
          hasCompletedExam={hasCompletedExam}
          shouldShowDilationRecommendation={shouldShowDilationRecommendation}
          shouldShowReachMaxRecommendedAttempts={
            shouldShowReachMaxRecommendedAttempts
          }
          handleCloseAnalyzingProgressModal={handleCloseAnalyzingProgressModal}
          handleResetSession={handleResetSession}
          totalLeftImageAttempt={totalLeftImageAttempt}
          totalRightImageAttempt={totalRightImageAttempt}
          isFindingRetakeSessions={isFindingRetakeSessions}
        />
      </Modal>
    </>
  );
}
