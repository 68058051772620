import {
  faCheckCircle,
  faCircle,
  faCircleQuestion,
  faCircleXmark,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { Text, View } from '@react-pdf/renderer';

import FontAwesomeIcon from 'src/components/custom_components/PdfFontAwesomeIcon';

import { Disease, DiseaseFullName } from 'src/models/Disease.model';
import {
  Grading,
  GradingIsNegative,
  GradingName,
} from 'src/models/Grading.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { ReportType } from 'src/models/ReportType.model';
import { Result } from 'src/models/Result.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface IResultSectionProps {
  session: Session;
  showNotes: boolean;
  type: ReportType;
}

const resultBadge = (type: ReportType, result: Result) => {
  const { handleCommonT } = handleNSI18Next();

  // error?
  if (result.error) {
    return type === ReportType.CLINICAL ? (
      <View style={cls('badge rounded-pill bg-secondary')}>
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={cls('me-2 text-white')}
        ></FontAwesomeIcon>

        <Text>{handleCommonT('exception.error')}</Text>
      </View>
    ) : (
      <View style={cls('badge rounded-pill text-black')}>
        <FontAwesomeIcon
          icon={faCircle}
          style={cls('me-2 text-secondary')}
        ></FontAwesomeIcon>

        <Text>{handleCommonT('exception.error')}</Text>
      </View>
    );
  }

  // ungradable?
  if (result.gradable === false) {
    return type === ReportType.CLINICAL ? (
      <View style={cls('badge rounded-pill bg-secondary')}>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          style={cls('me-2 text-white')}
        ></FontAwesomeIcon>

        <Text>{handleCommonT('exception.ungradable')}</Text>
      </View>
    ) : (
      <View style={cls('badge rounded-pill text-black')}>
        <FontAwesomeIcon
          icon={faCircle}
          style={cls('me-2 text-secondary')}
        ></FontAwesomeIcon>

        <Text>{handleCommonT('exception.ungradable')}</Text>
      </View>
    );
  }

  // negative?
  if (GradingIsNegative(result.grading)) {
    return type === ReportType.CLINICAL ? (
      <View style={cls('badge rounded-pill bg-success')}>
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={cls('me-2 text-white')}
        ></FontAwesomeIcon>

        <Text>
          {handleCommonT(`gradingName.${GradingName(result.grading)}`)}
        </Text>
      </View>
    ) : (
      <View style={cls('badge rounded-pill text-black')}>
        <FontAwesomeIcon
          icon={faCircle}
          style={cls('me-2 text-success')}
        ></FontAwesomeIcon>

        <Text>
          {handleCommonT(`gradingName.${GradingName(result.grading)}`)}
        </Text>
      </View>
    );
  }

  // positive
  const severe =
    result.grading === Grading.HIGH_RISK ||
    result.grading === Grading.PROLIFERATIVE_DR ||
    result.grading === Grading.LATE_WET ||
    result.grading === Grading.POSITIVE ||
    result.grading === Grading.SEVERE_ATHEROSCLEROSIS;

  return type === ReportType.CLINICAL ? (
    <View
      style={cls(`badge rounded-pill ${severe ? 'bg-danger' : 'bg-warning'}`)}
    >
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        style={cls('me-2 text-white')}
      ></FontAwesomeIcon>

      <Text>{handleCommonT(`gradingName.${GradingName(result.grading)}`)}</Text>
    </View>
  ) : (
    <View style={cls('badge rounded-pill text-black')}>
      <FontAwesomeIcon
        icon={faCircle}
        style={cls(`me-2 ${severe ? 'text-danger' : 'text-warning'}`)}
      ></FontAwesomeIcon>

      <Text>{handleCommonT(`gradingName.${GradingName(result.grading)}`)}</Text>
    </View>
  );
};

const diseaseResultTr = (
  type: ReportType,
  session: Session,
  disease: Disease,
) => {
  const rightResult = session.rightResults?.find(
    (result) => result.disease === disease,
  );
  const leftResult = session.leftResults?.find(
    (result) => result.disease === disease,
  );

  const { handleCommonT } = handleNSI18Next();

  return type === ReportType.CLINICAL ? (
    <View style={cls('d-flex hstack justify-content-between')}>
      <View style={cls('td w-30')}>
        <Text>
          {handleCommonT(`diseaseFullName.${DiseaseFullName(disease)}`)}:
        </Text>
      </View>
      <View style={cls('td w-20')}>
        {rightResult === undefined ? (
          <Text>{handleCommonT('exception.NA')}</Text>
        ) : (
          resultBadge(type, rightResult)
        )}
      </View>

      <View style={cls('p-0 border-0')}></View>

      <View style={cls('td w-30')}>
        <Text>
          {handleCommonT(`diseaseFullName.${DiseaseFullName(disease)}`)}:
        </Text>
      </View>
      <View style={cls('td w-20')}>
        {leftResult === undefined ? (
          <Text>{handleCommonT('exception.NA')}</Text>
        ) : (
          resultBadge(type, leftResult)
        )}
      </View>
    </View>
  ) : (
    <View style={cls('d-flex hstack justify-content-between')}>
      <View style={cls('td w-20 border-top-0')}>
        <Text>
          {handleCommonT(`diseaseFullName.${DiseaseFullName(disease)}`)}
        </Text>
      </View>
      <View style={cls('td w-40 border-top-0 dashed')}>
        {rightResult === undefined ? (
          <Text>{handleCommonT('exception.NA')}</Text>
        ) : (
          resultBadge(type, rightResult)
        )}
      </View>
      <View style={cls('td w-40 border-top-0 dashed')}>
        {leftResult === undefined ? (
          <Text>{handleCommonT('exception.NA')}</Text>
        ) : (
          resultBadge(type, leftResult)
        )}
      </View>
    </View>
  );
};

const ResultSection: React.FC<IResultSectionProps> = ({
  session,
  showNotes,
  type,
}) => {
  const { handleReportT } = handleNSI18Next();

  return type === ReportType.CLINICAL ? (
    <>
      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td w-30 text-muted')}>
          <Text>{handleReportT(`resultSection.diseaseType`)}</Text>
        </View>
        <View style={cls('td w-20 text-muted')}>
          <Text>{handleReportT(`resultSection.status`)}</Text>
        </View>

        <View style={cls('td p-0 border-0')}></View>

        <View style={cls('td w-30 text-muted')}>
          <Text>{handleReportT(`resultSection.diseaseType`)}</Text>
        </View>
        <View style={cls('td w-20 text-muted')}>
          <Text>{handleReportT(`resultSection.status`)}</Text>
        </View>
      </View>

      {diseaseResultTr(type, session, Disease.GLAUCOMA)}

      {diseaseResultTr(type, session, Disease.DR)}

      {diseaseResultTr(type, session, Disease.AMD)}

      {showNotes && (
        <View style={cls('report-text my-4')}>
          <View style={cls('p-4 pb-2')}>
            <Text style={cls('p')}>
              <Text>
                {handleReportT(`resultSection.${type}.referToTheEndText`)}
              </Text>
            </Text>

            <Text style={cls('p')}>
              <Text>
                {handleReportT(`resultSection.${type}.artifactWarningText`)}
              </Text>
            </Text>

            <Text style={cls('p')}>
              <Text>
                {handleReportT(`resultSection.${type}.thumbnailWarningText`)}
              </Text>
            </Text>
          </View>
        </View>
      )}
    </>
  ) : (
    <>
      <View style={cls('d-flex hstack justify-content-between')}>
        <View
          style={cls('td w-20 border-top-0', {
            backgroundColor: 'rgb(245, 243, 241)',
          })}
        >
          <Text style={cls('b')}>
            {handleReportT(`resultSection.diseaseType`)}
          </Text>
        </View>
        <View
          style={cls('td w-40 border-top-0 dashed', {
            backgroundColor: 'rgb(245, 243, 241)',
          })}
        >
          <Text style={cls('b')}>
            {handleReportT(`resultSection.${type}.rightEye`)}
          </Text>
        </View>
        <View
          style={cls('td w-40 border-top-0 dashed', {
            backgroundColor: 'rgb(245, 243, 241)',
          })}
        >
          <Text style={cls('b')}>
            {handleReportT(`resultSection.${type}.leftEye`)}
          </Text>
        </View>
      </View>

      {diseaseResultTr(type, session, Disease.GLAUCOMA)}

      {diseaseResultTr(type, session, Disease.DR)}

      {diseaseResultTr(type, session, Disease.AMD)}

      {showNotes && (
        <View style={cls('report-text p-4 pb-2 my-4')}>
          <Text style={cls('p')}>
            {handleReportT(`resultSection.${type}.eyeContactText`)}
          </Text>

          <Text style={cls('p')}>
            {handleReportT(`resultSection.${type}.artifactWarningText`)}
          </Text>

          <Text style={cls('p')}>
            {handleReportT(`resultSection.${type}.thumbnailWarningText`)}
          </Text>
        </View>
      )}
    </>
  );
};

export default ResultSection;
