import useSWRMutation from 'swr/mutation';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { SWRKeysEnum } from 'src/models/SWRKeys.model';

export function useContinueCaptureProcess() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    SWRKeysEnum.CONTINUE_CAPTURE_PROCESS,
    () => CameraApi.setContinueCaptureProcess(),
  );

  return {
    continueResponse: data?.data,
    isMutating,
    isError: error,
    continueCaptureProcess: trigger,
  };
}
