import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';

import { faRefresh, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { Practice } from 'src/models/GalenData/Practice.model';
import { Supplier } from 'src/models/GalenData/Supplier.Model';
import { GalenDataUser, UserStatusEnum } from 'src/models/GalenData/User.model';
import {
  adminRoleList,
  UserRoleEnum,
} from 'src/models/GalenData/UserRole.model';
import { initSettings } from 'src/models/InitialSettings.model';
import { Settings } from 'src/models/Settings.model';

import useGetUsers from 'src/hooks/useGetUsers';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

import CustomTable, { Column } from '../CustomTable/CustomTable';
import UserFormModal, { subUserFormDataTypes } from './UserFormModal';

const SubUserTab = () => {
  const { userType, practiceId } = useBoundStore();

  const { isGettingUsers, handleGetUsers, users } = useGetUsers();

  useEffect(() => {
    handleGetUsers();
  }, [handleGetUsers]);

  const [showUserFormModal, setShowUserFormModal] = useState(false);
  const [showSetPermissionDialog, setShowSetPermissionDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState<GalenDataUser>();
  const [currentSettings, setCurrentSettings] = useState<Settings>();

  const [permissionFormValues, setPermissionFormValues] = useState(
    initSettings.userPermission,
  );

  const [supplierValue, setSupplierValue] = useState<Supplier[]>([]);
  const [practiceValue, setPracticeValue] = useState<Practice[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const loadOrg = async () => {
      if (userType === UserRoleEnum.PracticeAdmin) {
        return;
      }

      try {
        const practiceList = await Api.remote.search_practice();
        if (practiceList.data.content) {
          setPracticeValue(practiceList.data.content);
        }
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);

        Api.alertBox(
          'Error',
          `Failed to load available practices. ${errorMessage}`,
        );
      }

      if (userType !== UserRoleEnum.TenantAdmin) {
        return;
      }

      try {
        const supplierList = await Api.remote.search_supplier();
        if (supplierList.data.content) {
          setSupplierValue(supplierList.data.content);
        }
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);

        Api.alertBox(
          'Error',
          `Failed to load available partners. ${errorMessage}`,
        );
      }
    };

    loadOrg();
  }, [userType]);

  const handlePermissionFormChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { id } = ev.target;
    const newFormValues = Object.assign({}, permissionFormValues);

    switch (id) {
      case 'create':
      case 'list':
      case 'read':
      case 'update':
      case 'delete':
      case 'print': {
        newFormValues[id] = (ev.target as any).checked as boolean;
        break;
      }

      default: {
        console.log(`Unknown id: ${id}`);
      }
    }

    // print -> read
    if (id === 'print' && newFormValues[id]) {
      newFormValues['read'] = true;
    }

    // no read -> no print
    if (id === 'read' && !newFormValues[id]) {
      newFormValues['print'] = false;
    }

    setPermissionFormValues(newFormValues);
  };

  const handleOpenSetPermissionDialog = async (user: GalenDataUser) => {
    try {
      if (!user.emailAddress) {
        throw new Error('User email is not set');
      }

      const settings = await Api.remote.load_settings(
        user.emailAddress,
        user.userId,
        practiceId,
      );
      setCurrentUser(user);
      setCurrentSettings(settings);
      setPermissionFormValues(Object.assign({}, settings.userPermission));

      setShowSetPermissionDialog(true);
    } catch {
      Api.alertBox('Error', 'Failed to load permissions.');
    }
  };

  const handleCloseSetPermissionDialog = () => {
    setShowSetPermissionDialog(false);
  };

  const handleShowUserFormModal = () => {
    setShowUserFormModal(true);
  };

  const handleShowAddUserModal = () => {
    setCurrentUser(undefined);
    handleShowUserFormModal();
  };

  const handleCloseUserFormModal = () => {
    setShowUserFormModal(false);
  };

  const handleOpenEditUserDialog = (user: GalenDataUser) => {
    setCurrentUser(user);
    handleShowUserFormModal();
  };

  const handleCloseEditUserFormModal = () => {
    handleCloseUserFormModal();
  };

  const handleSetPermission = async () => {
    try {
      const newSettings = Object.assign({}, currentSettings);
      newSettings.userPermission = {
        create: permissionFormValues.create,
        list: permissionFormValues.list,
        read: permissionFormValues.read,
        update: permissionFormValues.update,
        delete: permissionFormValues.delete,
        print: permissionFormValues.print,
      };

      if (!currentUser?.emailAddress) {
        throw new Error('User email is not set');
      }

      await Api.remote.save_settings(currentUser?.emailAddress, newSettings);

      setShowSetPermissionDialog(false);

      Api.alertBox('Information', 'Permission saved.');
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);

      Api.alertBox('Error', errorMessage);
    }
  };

  const [isSavingUser, setIsSavingUser] = useState(false);
  const handleAddUser = async (data: subUserFormDataTypes) => {
    try {
      const { firstName, lastName, emailAddress, password, newPracticeId } =
        data;

      const workingPracticeId = newPracticeId
        ? { practiceId: newPracticeId }
        : { practiceId };

      const customData = await Api.remote.setting_custom_data(
        'UserProfile',
        initSettings,
      );

      setIsSavingUser(true);
      await Api.remote.create_user({
        user: {
          userId: uuidv4(),
          firstName,
          lastName,
          emailAddress,
          tenant: { tenantId: process.env.REACT_APP_TENANT_ID },
          roles: [
            {
              defaultRole: true,
              practice: workingPracticeId,
              role: UserRoleEnum.PracticeUser,
            },
          ],
          status: UserStatusEnum.Active,
          loggableAccount: true,
        },
        password,
        forcePasswordReset: true,
        customData,
      });

      goInitialPage();
      Api.alertBox(
        'Information',
        `User <strong>${emailAddress}</strong> (Full Name: <strong>${firstName} ${lastName}</strong>) has been added.`,
      );
      handleCloseUserFormModal();
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', errorMessage);
    } finally {
      setIsSavingUser(false);
    }
  };

  const handleEditUser = async (data: subUserFormDataTypes) => {
    try {
      if (!currentUser) {
        throw new Error('Current user is not set');
      }

      const { firstName, lastName, emailAddress } = data;
      setIsSavingUser(true);

      await Api.remote.update_user({
        ...currentUser,
        ...data,
      });

      handleGetUsers(currentPage - 1);
      Api.alertBox(
        'Information',
        `User <strong>${emailAddress}</strong> (Full Name: <strong>${firstName} ${lastName}</strong>) has been updated.`,
      );
      handleCloseEditUserFormModal();
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', errorMessage);
    } finally {
      setIsSavingUser(false);
    }
  };

  const handleResetPassword = (user: GalenDataUser) => {
    Api.confirmBox({
      title: 'Confirmation',
      message: `Are you sure to reset password for User <strong>${user.emailAddress}</strong> (Full Name: <strong>${user.fullName}</strong>)?`,
      callback: async (yes) => {
        if (!yes) {
          return;
        }

        try {
          if (!user.emailAddress) {
            throw new Error('User email is not set');
          }

          await Api.remote.reset_password(user.emailAddress);

          Api.alertBox(
            'Information',
            `A password reset code has been sent to the registered email address for user <strong>${user.emailAddress}</strong> (Full Name: <strong>${user.fullName}</strong>). Please check the email.`,
          );
        } catch (error) {
          const errorMessage = handleHttpRequestError(error);
          Api.alertBox('Error', errorMessage);
        }
      },
    });
  };

  const handleDeleteUser = (user: GalenDataUser) => {
    Api.confirmBox({
      title: 'Confirmation',
      message: `Are you sure to delete User <strong>${user.emailAddress}</strong> (Full Name: <strong>${user.fullName}</strong>)?`,
      callback: async (yes) => {
        if (!yes) {
          return;
        }

        try {
          await Api.remote.delete_user(user.userId);

          goInitialPage();

          Api.alertBox(
            'Information',
            `User <strong>${user.emailAddress}</strong> (Full Name: <strong>${user.fullName}</strong>) has been deleted.`,
          );
        } catch (error) {
          const errorMessage = handleHttpRequestError(error);
          Api.alertBox('Error', errorMessage);
        }
      },
    });
  };

  const goInitialPage = () => {
    setCurrentPage(1);
    handleGetUsers();
  };

  const columns: Column<GalenDataUser>[] = [
    { title: 'Login Username', key: 'emailAddress' },
    { title: 'Full Name', key: 'fullName' },
    {
      title: 'User Type',
      dataIndex: 'userType',
      render: (record) => (
        <div>
          {record.roles.map((role, index) => (
            <ul key={`${role.role}${index}`}>
              <li>
                {role.role} {role.practice?.name && `(${role.practice.name})`}
              </li>
            </ul>
          ))}
        </div>
      ),
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      render: (record) => <div>{record.currentRole?.practice?.name}</div>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (record) => {
        return (
          <ButtonGroup>
            {userType && adminRoleList.includes(userType) && (
              <Button
                variant="outline-optain"
                style={{ borderRight: 0 }}
                onClick={() => {
                  handleOpenEditUserDialog(record);
                }}
              >
                Edit
              </Button>
            )}
            <Button
              variant="outline-optain"
              style={{ borderRight: 0 }}
              onClick={() => {
                handleOpenSetPermissionDialog(record);
              }}
              disabled={userType && !adminRoleList.includes(userType)}
            >
              Permissions
            </Button>
            <Button
              variant="outline-optain"
              style={{ borderRight: 0 }}
              onClick={() => {
                handleResetPassword(record);
              }}
            >
              Reset password
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                handleDeleteUser(record);
              }}
              disabled={userType && !adminRoleList.includes(userType)}
            >
              Delete
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <>
      <Container fluid className="main-content">
        <Row>
          <Col xl={4}>
            <h2>Users</h2>
          </Col>
          <Col xl={8} className="d-xl-flex justify-content-end mb-4">
            <Button
              variant="optain"
              className="d-inline-block ms-3"
              onClick={handleShowAddUserModal}
            >
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-2"
              ></FontAwesomeIcon>
              Add
            </Button>

            <Button
              variant="outline-optain"
              className="d-inline-block ms-3"
              onClick={goInitialPage}
            >
              <FontAwesomeIcon
                icon={faRefresh}
                className="me-2"
              ></FontAwesomeIcon>
              Reload
            </Button>
          </Col>
        </Row>

        <Row>
          <CustomTable
            isLoading={isGettingUsers}
            dataSource={users.content ?? []}
            rowKey="userId"
            columns={columns}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [10],
              totalPages: users.totalPages ?? 1,
              currentPage,
              onChangeCurrentPage: (page) => {
                setCurrentPage(page);
              },
              onChangePage: (pageNumber, pageSize) => {
                handleGetUsers(pageNumber - 1, pageSize);
              },
            }}
            empty={{
              buttonText: 'Add New User',
              onButtonClick: handleShowAddUserModal,
            }}
          />
        </Row>
      </Container>

      <UserFormModal
        show={showUserFormModal}
        handleClose={
          currentUser ? handleCloseEditUserFormModal : handleCloseUserFormModal
        }
        handleSave={currentUser ? handleEditUser : handleAddUser}
        isSavingUser={isSavingUser}
        user={currentUser}
        practiceList={practiceValue}
        supplierList={supplierValue}
      />
      <Modal
        show={showSetPermissionDialog}
        onHide={handleCloseSetPermissionDialog}
        backdrop="static"
        animation={false}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set User Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>
              <strong>Login Username:</strong> {currentUser?.emailAddress}
            </p>
            <p>
              <strong>Full Name:</strong> {currentUser?.fullName}
            </p>
            <hr />

            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                id="create"
                label="Create Sessions"
                checked={permissionFormValues.create}
                onChange={handlePermissionFormChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="list">
              <Form.Check
                type="switch"
                id="list"
                label="List Sessions"
                checked={permissionFormValues.list}
                onChange={handlePermissionFormChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="read">
              <Form.Check
                type="switch"
                id="read"
                label="View Reports"
                checked={permissionFormValues.read}
                onChange={handlePermissionFormChange}
                disabled={
                  currentUser?.currentRole?.role === UserRoleEnum.PracticeUser
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="print">
              <Form.Check
                type="switch"
                id="print"
                label="Print/Download Reports"
                checked={permissionFormValues.print}
                onChange={handlePermissionFormChange}
                disabled={
                  currentUser?.currentRole?.role === UserRoleEnum.PracticeUser
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleCloseSetPermissionDialog}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSetPermission}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubUserTab;
