import moment from 'moment';
import Sentry from 'src/services/sentry';
import { v4 as uuidv4 } from 'uuid';

import { Api } from 'src/models/Api.model';
import { CameraData } from 'src/models/CameraData.model';
import { AddUserRequestBody } from 'src/models/GalenData/AddUserRequestBody.model';
import {
  OrderStatusEnum,
  OrderSubmissionData,
} from 'src/models/GalenData/Order.model';
import { UserStatusEnum } from 'src/models/GalenData/User.model';
import { UserRoleEnum } from 'src/models/GalenData/UserRole.model';
import { ModelEnum } from 'src/models/Model.model';
import { Product } from 'src/models/Product.model';
import { Session } from 'src/models/Session.model';

import {
  ExamSubmissionData,
  RetinalExamKeyFiledEnum,
} from '../CustomDeviceDataModel.model';
import { EntityCriteriaOperatorEnum } from '../EntityCriteria.model';
import { IGetUsersRequestBody } from '../Request.model';
import { SortOrderEnum } from '../SortOrder.model';
import getRetinalExamDataModelConfig from './getRetinalExamDataModelConfig';

const createPatientUser = async (
  session: Session,
  practiceId: string,
): Promise<string> => {
  const patientUserId = uuidv4();

  const user: AddUserRequestBody = {
    user: {
      userId: patientUserId,
      patientId: session.id,
      firstName: session.firstName,
      lastName: session.lastName,
      tenant: { tenantId: process.env.REACT_APP_TENANT_ID },
      roles: [
        {
          defaultRole: true,
          practice: { practiceId },
          role: UserRoleEnum.Patient,
        },
      ],
      dateOfBirth: session.dob,
      status: UserStatusEnum.Inactive,
      canViewSensitiveData: false,
      loggableAccount: false,
    },
  };

  await Api.remote.create_user(user);
  return patientUserId;
};

const createExamOrder = async ({
  session,
  patientUserId,
}: {
  session: Session;
  patientUserId: string;
}): Promise<string> => {
  const orderId = uuidv4();
  await Api.remote._save_device_data_record<OrderSubmissionData>({
    deviceDataModelId: process.env.REACT_APP_EXAM_ORDER_MODEL_ID,
    devicePropertySetId:
      process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_ORDER,
    ownerId: patientUserId,
    data: {
      OrderId: orderId,
      Status: OrderStatusEnum.OPEN,
      OrderStatusDatetime: moment(session.createdAt).toISOString(),
      OrderType: session.template,
    },
  });

  return orderId;
};

const updateRetinalExamData = async ({
  deviceDataModelId,
  devicePropertySetId,
  examSubmissionData,
  patientUserId,
}: {
  deviceDataModelId: string;
  devicePropertySetId: string;
  examSubmissionData: ExamSubmissionData;
  patientUserId: string;
}): Promise<string> => {
  const res = await Api.remote._save_device_data_record<ExamSubmissionData>({
    deviceDataModelId,
    devicePropertySetId,
    ownerId: patientUserId,
    data: examSubmissionData,
  });

  return res.data;
};

const uploadMediaFile = async ({
  deviceDataModelId,
  deviceDataId,
  propertyCode,
  imageUrl,
  imageFileName,
}: {
  deviceDataModelId: string;
  deviceDataId: string;
  propertyCode: string;
  imageUrl: string;
  imageFileName: string;
}) => {
  await Api.remote.upload_media_file({
    deviceDataModelId,
    deviceDataId,
    propertyCode,
    imageUrl,
    imageFileName,
  });
};

const uploadImages = async ({
  deviceDataModelId,
  deviceDataId,
  session,
}: {
  deviceDataModelId: string;
  deviceDataId: string;
  session: Session;
}): Promise<void> => {
  if (session.leftImageFile) {
    await uploadMediaFile({
      deviceDataModelId,
      deviceDataId,
      propertyCode: RetinalExamKeyFiledEnum.LeftImage,
      imageUrl: session.leftImageFile,
      imageFileName: session.leftImageFileName,
    });
  }

  if (session.rightImageFile) {
    await uploadMediaFile({
      deviceDataModelId,
      deviceDataId,
      propertyCode: RetinalExamKeyFiledEnum.RightImage,
      imageUrl: session.rightImageFile,
      imageFileName: session.rightImageFileName,
    });
  }
};

const setUploadStatus = async ({
  deviceDataModelId,
  devicePropertySetId,
  deviceDataId,
  patientUserId,
}: {
  deviceDataModelId: string;
  devicePropertySetId: string;
  deviceDataId: string;
  patientUserId: string;
}): Promise<string> => {
  const res = await Api.remote._save_device_data_record<{
    UploadStatusComplete: boolean;
  }>({
    deviceDataModelId,
    devicePropertySetId,
    ownerId: patientUserId,
    deviceDataId,
    data: {
      UploadStatusComplete: true,
    },
  });

  return res.data;
};

const getPatientUserId = async (session: Session, practiceId: string) => {
  const pureFilters: {
    key: keyof IGetUsersRequestBody;
    value: string | undefined;
  }[] = [
    {
      key: 'patientId',
      value: session.id,
    },
    {
      key: 'dateOfBirth',
      value: session.dob,
    },
    {
      key: 'firstName',
      value: session.firstName,
    },
    {
      key: 'lastName',
      value: session.lastName,
    },
    {
      key: 'role',
      value: UserRoleEnum.Patient,
    },
    {
      key: 'practiceId',
      value: practiceId,
    },
  ];

  const filters = pureFilters.map((filter) => ({
    ...filter,
    operator: EntityCriteriaOperatorEnum.Equal,
    isCustomField: false,
  }));

  const existingPatient = await Api.remote._get_users_advanced(filters, {
    pageNumber: 0,
    pageSize: 10,
    sortBy: ['createdOn'],
    sortOrder: SortOrderEnum.DESC,
  });

  return existingPatient.data.content &&
    existingPatient.data.content?.length > 0
    ? existingPatient.data.content[0].userId
    : await createPatientUser(session, practiceId);
};

const submitRetinalExamData = async (
  session: Session,
  practiceId: string,
  baseExamSubmissionData: Omit<ExamSubmissionData, 'OrderId'>,
  product: Product,
) => {
  const { deviceDataModelId, devicePropertySetId } =
    getRetinalExamDataModelConfig(product);

  const patientUserId =
    session.patientUserId ?? (await getPatientUserId(session, practiceId));

  const orderId =
    session.orderId ??
    (await createExamOrder({
      session,
      patientUserId,
    }));

  const examSubmissionData = {
    ...baseExamSubmissionData,
    OrderId: orderId,
    Gender: session.gender,
  };

  const deviceDataId = await updateRetinalExamData({
    deviceDataModelId,
    devicePropertySetId,
    examSubmissionData,
    patientUserId,
  });

  await uploadImages({
    deviceDataModelId,
    deviceDataId,
    session,
  });

  return await setUploadStatus({
    deviceDataModelId,
    devicePropertySetId,
    deviceDataId,
    patientUserId,
  });
};

const submitTeleophthExamData = async (
  session: Session,
  baseExamSubmissionData: Omit<ExamSubmissionData, 'OrderId'>,
  product: Product,
) => {
  const { deviceDataModelId, devicePropertySetId } =
    getRetinalExamDataModelConfig(product);

  const patientUserId = session.patientUserId;
  const orderId = session.orderId;

  if (!patientUserId) {
    const errorMessage =
      'Patient user not found for teleophth exam submission.';
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  if (!orderId) {
    const errorMessage = 'Order not found for teleophth exam submission.';
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  const examSubmissionData = {
    ...baseExamSubmissionData,
    OrderId: orderId,
    DiabetesType: session.typeOfDiabetes,
    DurationOfDiabetes: session.lengthOfDiabetesDiagnosis,
    LastEyeExam: session.timeSinceLastEyeExam,
  };

  const deviceDataId = await updateRetinalExamData({
    deviceDataModelId,
    devicePropertySetId,
    examSubmissionData,
    patientUserId,
  });

  await uploadImages({
    deviceDataModelId,
    deviceDataId,
    session,
  });

  return await setUploadStatus({
    deviceDataModelId,
    devicePropertySetId,
    deviceDataId,
    patientUserId,
  });
};

const submitSession = async ({
  sessionId,
  practiceId,
  practiceUserId,
  session,
  models,
  product,
  cameraData,
}: {
  sessionId: string;
  practiceId: string;
  practiceUserId: string;
  session: Session;
  models: ModelEnum[];
  product: Product;
  cameraData?: CameraData;
}): Promise<string> => {
  const baseExamSubmissionData = {
    SessionId: sessionId,
    PracticeUserId: practiceUserId,
    PracticeId: practiceId,
    ExamSubmissionMetadata: {
      models,
    },
    ExamSubmissionDatetime: moment(session.createdAt).toISOString(),
    ReportDatetime: moment(session.date + ' ' + session.time).toISOString(),
    RightEyeDilatedTime: session.rightEyeDilatedTime,
    LeftEyeDilatedTime: session.leftEyeDilatedTime,
    IsHidden: false,
    CameraSn: cameraData?.sn,
    CameraVersion: cameraData?.version,
  };

  if (product === Product.TELEOPHTH) {
    return await submitTeleophthExamData(
      session,
      baseExamSubmissionData,
      product,
    );
  }

  return await submitRetinalExamData(
    session,
    practiceId,
    baseExamSubmissionData,
    product,
  );
};

export { setUploadStatus, submitSession, updateRetinalExamData, uploadImages };
