import * as React from 'react';

import { Text, View } from '@react-pdf/renderer';

import { GradingSummaryText } from 'src/models/Grading.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface ISummarySectionProps {
  session: Session;
}

const SummarySection: React.FC<ISummarySectionProps> = ({ session }) => {
  const summary = GradingSummaryText(session);

  const { handleReportT } = handleNSI18Next();

  return (
    <>
      <View>
        <Text style={cls('section-text lead mt-4')}>
          {handleReportT('summarySection.title')}
        </Text>
      </View>

      <View style={cls('border-top pt-4')}>
        {summary.map((text) => (
          <Text key={text} style={cls('p')}>
            {text}
          </Text>
        ))}
      </View>
    </>
  );
};

export default SummarySection;
