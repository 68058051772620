import { Gender } from 'src/models/Gender.model';

import { ModelEnum } from '../Model.model';

export enum RetinalExamKeyFiledEnum {
  Status = 'Status',
  IsHidden = 'IsHidden',
  CompletedTime = 'CompletedTime',
  ExamSubmissionDatetime = 'ExamSubmissionDatetime',
  RightEyeResultJson = 'RightEyeResultJson',
  LeftEyeResultJson = 'LeftEyeResultJson',
  LeftImage = 'LeftImage',
  RightImage = 'RightImage',
  CompletedReason = 'CompletedReason',
  UploadStatusComplete = 'UploadStatusComplete',
  PracticeUserId = 'PracticeUserId',
  PracticeId = 'PracticeId',
  SessionId = 'SessionId',
  ExamSubmissionMetadata = 'ExamSubmissionMetadata',
  Gender = 'Gender',
  ReportDatetime = 'ReportDatetime',
  OrderId = 'OrderId',
  RightEyeDilatedTime = 'RightEyeDilatedTime',
  LeftEyeDilatedTime = 'LeftEyeDilatedTime',
  DiabetesType = 'DiabetesType',
  DurationOfDiabetes = 'DurationOfDiabetes',
  LastEyeExam = 'LastEyeExam',
  CameraSn = 'CameraSn',
  CameraVersion = 'CameraVersion',
}

export interface CustomDeviceDataModel {
  deviceDataId: string;
  minValueProvidedOn: string;
  maxValueProvidedOn: string;
  processingStatus: string;
  data: ResponseData;
  deviceDataModelId: string;
  ownerId: string;
}

interface ResponseData {
  [RetinalExamKeyFiledEnum.Status]: DataField<string>;
  [RetinalExamKeyFiledEnum.IsHidden]: DataField<boolean>;
  [RetinalExamKeyFiledEnum.CompletedTime]: DataField<string>;
  [RetinalExamKeyFiledEnum.ExamSubmissionDatetime]: DataField<string>;
  [RetinalExamKeyFiledEnum.RightEyeResultJson]: DataField<object>;
  [RetinalExamKeyFiledEnum.LeftEyeResultJson]: DataField<object>;
  [RetinalExamKeyFiledEnum.LeftImage]?: DataField<string>;
  [RetinalExamKeyFiledEnum.RightImage]?: DataField<string>;
  [RetinalExamKeyFiledEnum.CompletedReason]: DataField<string>;
  [RetinalExamKeyFiledEnum.UploadStatusComplete]: DataField<boolean>;
  [RetinalExamKeyFiledEnum.PracticeUserId]: DataField<string>;
  [RetinalExamKeyFiledEnum.PracticeId]: DataField<string>;
  [RetinalExamKeyFiledEnum.SessionId]: DataField<string>;
  [RetinalExamKeyFiledEnum.ExamSubmissionMetadata]: DataField<ExamSubmissionMetadata>;
  [RetinalExamKeyFiledEnum.ReportDatetime]: DataField<string>;
  [RetinalExamKeyFiledEnum.OrderId]: DataField<string>;
  [RetinalExamKeyFiledEnum.RightEyeDilatedTime]?: DataField<Date>;
  [RetinalExamKeyFiledEnum.LeftEyeDilatedTime]?: DataField<Date>;
  [RetinalExamKeyFiledEnum.DiabetesType]?: DataField<string>;
  [RetinalExamKeyFiledEnum.DurationOfDiabetes]?: DataField<string>;
  [RetinalExamKeyFiledEnum.LastEyeExam]?: DataField<string>;
}

interface DataField<T> {
  value: T;
  valueProvidedOn: string;
  reference: string | null;
  link: string | null;
}

interface ExamSubmissionMetadata {
  models: ModelEnum[];
}

export interface ExamSubmissionData {
  [RetinalExamKeyFiledEnum.IsHidden]: boolean;
  [RetinalExamKeyFiledEnum.ExamSubmissionDatetime]: string;
  [RetinalExamKeyFiledEnum.ReportDatetime]: string;
  [RetinalExamKeyFiledEnum.PracticeUserId]: string;
  [RetinalExamKeyFiledEnum.PracticeId]: string;
  [RetinalExamKeyFiledEnum.SessionId]: string;
  [RetinalExamKeyFiledEnum.OrderId]: string;
  [RetinalExamKeyFiledEnum.ExamSubmissionMetadata]: ExamSubmissionMetadata;
  [RetinalExamKeyFiledEnum.RightEyeDilatedTime]?: Date;
  [RetinalExamKeyFiledEnum.LeftEyeDilatedTime]?: Date;
  [RetinalExamKeyFiledEnum.LeftImage]?: string;
  [RetinalExamKeyFiledEnum.RightImage]?: string;
  [RetinalExamKeyFiledEnum.CompletedTime]?: string;
  [RetinalExamKeyFiledEnum.CompletedReason]?: string;
  [RetinalExamKeyFiledEnum.UploadStatusComplete]?: boolean;
  [RetinalExamKeyFiledEnum.Gender]?: Gender;
  [RetinalExamKeyFiledEnum.CameraSn]?: string;
  [RetinalExamKeyFiledEnum.CameraVersion]?: string;
}
