import { SegmentationType } from './SegmentationType.model';

export interface Segmentation {
  type: SegmentationType;
  filename: string;
  createdAt: Date;
}

const SegmentationFile = (
  segs: Segmentation[],
  type: SegmentationType,
): string => {
  for (const seg of segs) {
    if (seg.type === type) {
      return seg.filename;
    }
  }

  return '';
};

export { SegmentationFile };
