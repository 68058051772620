import useSWR from 'swr';

import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { SWRKeysEnum } from 'src/models/SWRKeys.model';

export function useGetCameraSettings(shouldGetCameraSettings: boolean = true) {
  const { data, error, isLoading, mutate } = useSWR(
    shouldGetCameraSettings ? SWRKeysEnum.GET_CAMERA_SETTINGS : undefined,
    () => CameraApi.getSettings(),
  );

  return {
    settings: data?.data,
    isLoading,
    isError: error,
    getCameraSettings: mutate,
  };
}
