import { StateCreator } from 'zustand';

import { Api } from 'src/models/Api.model';
import { Camera } from 'src/models/Camera.model';
import { SESSIONSTORAGE_KEY } from 'src/models/GalenData/Auth.model';
import { UserRoleEnum } from 'src/models/GalenData/UserRole.model';
import { initSettings } from 'src/models/InitialSettings.model';
import { Product } from 'src/models/Product.model';
import { Settings } from 'src/models/Settings.model';

type AuthStateTypes = { authToken: string; refreshToken: string };

export type CurrentUserStateTypes = {
  username: string;
  userType: UserRoleEnum | undefined;
  country: string;
  organization: string;
  fullName: string;
  lastLogin: string;
  userId: string;
  practiceId: string;
  supplierId: string;

  product: Product;
  camera: Camera;
};

type LogoType = { logo: string };

type StateTypes = AuthStateTypes & CurrentUserStateTypes & LogoType & Settings;

type ActionsTypes = {
  setAuth: (auth: AuthStateTypes) => void;
  logOut: () => void;
  setCurrentUser: (currentUser: CurrentUserStateTypes) => void;
  setLogo: (logo: LogoType) => void;
  setSettings: (settings: Settings) => void;
  getSettings: () => Settings;
};

export type AppSliceTypes = StateTypes & ActionsTypes & LogoType & Settings;

export const createUserSlice: StateCreator<
  AppSliceTypes,
  [],
  [],
  AppSliceTypes
> = (set) => {
  const authInfo: AuthStateTypes = sessionStorage.getItem(
    SESSIONSTORAGE_KEY.AUTH_INFO,
  )
    ? JSON.parse(sessionStorage.getItem(SESSIONSTORAGE_KEY.AUTH_INFO) as string)
    : {
        authToken: '',
        refreshToken: '',
      };
  const currentUser: CurrentUserStateTypes = sessionStorage.getItem(
    SESSIONSTORAGE_KEY.CURRENT_USER,
  )
    ? JSON.parse(
        sessionStorage.getItem(SESSIONSTORAGE_KEY.CURRENT_USER) as string,
      )
    : {
        username: '',
        userType: UserRoleEnum.PracticeUser,
        country: '',
        organization: '',
        fullName: '',
        lastLogin: '',
        userId: '',
      };

  const logo: LogoType = sessionStorage.getItem(SESSIONSTORAGE_KEY.LOGO)
    ? JSON.parse(sessionStorage.getItem(SESSIONSTORAGE_KEY.LOGO) as string)
    : { logo: '' };

  const settings: Settings = sessionStorage.getItem(SESSIONSTORAGE_KEY.SETTINGS)
    ? JSON.parse(sessionStorage.getItem(SESSIONSTORAGE_KEY.SETTINGS) as string)
    : initSettings;

  return {
    ...authInfo,
    ...currentUser,
    ...logo,
    ...settings,
    setAuth: (authInfo: AuthStateTypes) => {
      set(authInfo);
      sessionStorage.setItem(
        SESSIONSTORAGE_KEY.AUTH_INFO,
        JSON.stringify(authInfo),
      );
    },
    setLogo: (logo: LogoType) => {
      set(logo);
      sessionStorage.setItem(SESSIONSTORAGE_KEY.LOGO, JSON.stringify(logo));
    },

    setSettings: (settings: Settings) => {
      set(settings);
      sessionStorage.setItem(
        SESSIONSTORAGE_KEY.SETTINGS,
        JSON.stringify(settings),
      );
    },

    getSettings: () => {
      return sessionStorage.getItem(SESSIONSTORAGE_KEY.SETTINGS)
        ? JSON.parse(
            sessionStorage.getItem(SESSIONSTORAGE_KEY.SETTINGS) as string,
          )
        : initSettings;
    },

    logOut: () => {
      set({
        authToken: '',
        refreshToken: '',
        username: '',
        userType: undefined,
        country: '',
        organization: '',
        fullName: '',
        lastLogin: '',
      });

      Api.remote.logout(true);
    },
    setCurrentUser: (currentUser: CurrentUserStateTypes) => {
      set(currentUser);

      sessionStorage.setItem(
        SESSIONSTORAGE_KEY.CURRENT_USER,
        JSON.stringify(currentUser),
      );
    },
  };
};
