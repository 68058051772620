import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { CriteriaGroupGroupElementsInnerOperatorEnum } from 'src/models/GalenData/CriteriaGroupGroupElementsInner.model';
import { OrderStatusEnum } from 'src/models/GalenData/Order.model';
import { CompletedReasonEnum, Session } from 'src/models/Session.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

export default function useActionAnalyzeModel(
  handleCloseAnalyzingProgressModal: () => void,
  handleResetSession: () => void,
  session: Session,
) {
  const history = useHistory();

  const [isUpdating, setIsUpdating] = useState(false);

  const { product } = useBoundStore();

  const handleCloseSession = async (completedReason: CompletedReasonEnum) => {
    try {
      setIsUpdating(true);

      const [sessionData, orderData] = await Promise.all([
        Api.remote.load_session({
          deviceDataId: session.iid,
          product,
        }),
        Api.remote.get_exam_order({
          groupElements: [
            {
              key: 'OrderId',
              operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
              value: session.orderId,
            },
          ],
        }),
      ]);
      if (!sessionData) {
        throw new Error('Failed to load this session.');
      }

      if (sessionData.completedTime) {
        throw new Error(
          `This session has already been completed, and its completed reason is ${session.completedReason?.toLowerCase()}.`,
        );
      }

      if (!orderData.data.content) {
        throw new Error('Order not found.');
      }

      const orderStatus =
        completedReason === CompletedReasonEnum.COMPLETED_EXAM
          ? OrderStatusEnum.PENDING
          : OrderStatusEnum.CLOSED;

      await Promise.all([
        Api.remote.save_session(
          session.iid,
          {
            completedReason,
            completedTime: new Date(),
          },
          product,
        ),
        Api.remote.update_exam_order(orderData.data.content[0], {
          status: orderStatus,
        }),
      ]);

      handleResetSession();

      history.push('/session-form');
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', errorMessage);
    } finally {
      setIsUpdating(false);
      handleCloseAnalyzingProgressModal();
    }
  };

  const handleCompleteExam = () => {
    handleCloseSession(CompletedReasonEnum.COMPLETED_EXAM);
  };

  const handleSaveAndDilate = () => {
    handleCloseAnalyzingProgressModal();
    handleResetSession();

    history.push('/results');
  };

  const handleEndSession = () => {
    handleCloseSession(CompletedReasonEnum.END_SESSION);
  };

  return {
    handleCompleteExam,
    handleSaveAndDilate,
    handleEndSession,
    isUpdating,
  };
}
