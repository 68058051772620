import { CustomPracticeProfileFieldEnum } from './GalenData/CustomField.model';
import { Language } from './Language.model';

export interface UserPermission {
  create: boolean;
  list: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  print: boolean;
}

export interface Settings {
  // username: string, // in database

  language: Language; // Practice level

  // User
  folder: string;

  // Practice
  reportGender: boolean;
  reportHeatmap: boolean;
  reportLogo: boolean;
  logoFile: string;

  broadcast: string; // Practice level but set by manufacturer

  billingCode: boolean; // Practice level

  watermark: string; // Practice level

  userPermission: UserPermission; // User but set by admin
}

export const updateSettings = (
  settings: Settings,
  fieldId: string,
  fieldData: any,
  checked?: boolean,
): Settings => {
  const newFormValues = Object.assign({}, settings);

  switch (fieldId) {
    // types
    case 'language': {
      newFormValues[fieldId] = fieldData as Language;
      break;
    }

    // strings
    case 'folder':
    case 'broadcast':
    case 'watermark':
    case 'logoFile': {
      newFormValues[fieldId] = fieldData;
      break;
    }

    // booleans
    case 'reportGender':
    case 'reportHeatmap':
    case 'reportLogo':
    case 'billingCode': {
      newFormValues[fieldId] = checked ?? false;
      break;
    }

    default: {
      console.log(`Unknown id: ${fieldId}`);
    }
  }
  return newFormValues;
};

export const isPracticeSetting = (fieldId: string): boolean => {
  return Object.values(CustomPracticeProfileFieldEnum).includes(
    fieldId as CustomPracticeProfileFieldEnum,
  );
};

export enum partnerSettings {
  broadcast = 'broadcast',
}

export const isPartnerSetting = (fieldId: string): boolean => {
  return Object.values(partnerSettings).includes(fieldId as partnerSettings);
};
