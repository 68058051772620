import { ReactNode } from 'react';

import { View } from '@react-pdf/renderer';
import cx from 'classnames';

import { cls } from 'src/utils/reactPdfStyles';

type customBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
};
export default function CustomBadge({ className, children }: customBadgeProps) {
  return (
    <View
      style={cls(cx('border rounded-pill text-center', className))}
      children={children}
    />
  );
}
