import { Col, Row, Spinner, Stack } from 'react-bootstrap';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BackendStatus,
  ModelStatus,
} from 'src/models/GalenData/DeviceDataView.model';

const ModelNameMapping: Record<
  keyof Omit<BackendStatus, 'GlobalStatus' | 'SessionId'>,
  string
> = {
  DrusStatus: 'Diabetic Retinopathy',
  DreuStatus: 'Diabetic Retinopathy',
  AmdeuStatus: 'Age-related Macular Degeneration',
  GlaucomaeuStatus: 'Glaucoma',
  CvdeuStatus: 'Cardiovascular Disease',
  CvdauStatus: 'Cardiovascular Disease (AU)',
  DreuSmallStatus: 'Diabetic Retinopathy',
  AmdeuSmallStatus: 'Age-related Macular Degeneration',
  GlaucomaeuSmallStatus: 'Glaucoma',
  CvdeuSmallStatus: 'Cardiovascular Disease',
  CvdauSmallStatus: 'Cardiovascular Disease (AU)',
  VesselSegStatus: 'Vessel Segmentation',
  GeneralQualityStatus: 'General Quality',
};

const ModelStatusMapping: Record<ModelStatus, string> = {
  [ModelStatus.NA]: 'Awaiting Upload',
  [ModelStatus.Triggered]: 'Queued',
  [ModelStatus.Running]: 'Running',
  [ModelStatus.Complete]: 'Complete',
  [ModelStatus.Failed]: 'Failed',
};

const ModelStack = ({
  name,
  status,
}: {
  name: keyof Omit<BackendStatus, 'GlobalStatus' | 'SessionId'>;
  status: BackendStatus;
}) => {
  const statusValue = status[name].value;

  return (
    <Stack gap={3}>
      <Row>
        <Col sm={9}>
          {statusValue === ModelStatus.Complete ||
          statusValue === ModelStatus.Failed ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-optain-900" />
          ) : (
            <Spinner variant="optain" size="sm" />
          )}

          <span className="ms-3">{ModelNameMapping[name]}</span>
        </Col>
        <Col sm={3} className="text-secondary-400 text-end">
          {ModelStatusMapping[statusValue]}
        </Col>
      </Row>
    </Stack>
  );
};

export default ModelStack;
