import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import './index.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './i18n';
import './services/sentry';
import App from './App';
import ErrorBoundaryPage from './components/ErrorBoundaryPage';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement,
);
root.render(
  <ErrorBoundary fallback={<ErrorBoundaryPage />}>
    <App />
  </ErrorBoundary>,
);
