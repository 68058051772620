import { Dispatch, SetStateAction, useState } from 'react';
import { Badge, Button, Container } from 'react-bootstrap';

import Sentry from 'src/services/sentry';

import useBoundStore from 'src/store/useBoundStore';

import ConfirmPatientInformationModal from 'src/components/ConfirmPatientInformationModal';
import WarnNoConnectedCameraModal from 'src/components/custom_components/WarnNoConnectedCameraModal';
import OHResults from 'src/components/OHResults/OHResults';

import { Camera } from 'src/models/Camera.model';
import { CameraMode } from 'src/models/CameraMode.model';
import { OrderStatusEnum } from 'src/models/GalenData/Order.model';
import { Session } from 'src/models/Session.model';

interface TeleophthResultsProps {
  cameraMode: CameraMode;
  setSession: Dispatch<SetStateAction<Session>>;
}

const TeleophthResults = ({
  cameraMode,
  setSession,
}: TeleophthResultsProps) => {
  const { camera, cameraData } = useBoundStore();

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleSetIsShowConfirmModal = (isShowConfirmModal: boolean) => {
    setIsShowConfirmModal(isShowConfirmModal);
  };

  const [currentSession, setCurrentSession] = useState<Session>();

  const [
    shouldShowWarnNoConnectedCameraModal,
    setShouldShowWarnNoConnectedCameraModal,
  ] = useState(false);

  const handleSetShouldShowWarnNoConnectedCameraModal = (
    shouldShowWarnNoConnectedCameraModal: boolean,
  ) => {
    setShouldShowWarnNoConnectedCameraModal(
      shouldShowWarnNoConnectedCameraModal,
    );
  };

  const OrderStatus = (status: OrderStatusEnum) => {
    const statusConfig = {
      [OrderStatusEnum.OPEN]: { color: 'secondary', label: status },
      [OrderStatusEnum.PENDING]: { color: 'primary', label: status },
      [OrderStatusEnum.COMPLETE]: { color: 'optain', label: status },
      [OrderStatusEnum.CLOSED]: { color: 'warning', label: status },
      [OrderStatusEnum.EXPIRED]: { color: 'danger', label: status },
    };

    const { color, label } = statusConfig[status];

    return (
      <Badge pill bg={color}>
        {label}
      </Badge>
    );
  };

  return (
    <Container fluid className="main-content">
      <OHResults
        extraColumns={[
          {
            title: 'Order Status',
            dataIndex: 'orderStatus',
            render: (record) => {
              if (!record.order) {
                Sentry.captureException(
                  new Error(`Missing order in session ${record.iid}`),
                );
                return <span>N/A</span>;
              }

              return OrderStatus(record.order?.Status.value);
            },
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (record) => {
              return (
                <Button
                  variant="optain"
                  disabled={!(record.graded && !record.completedReason)}
                  onClick={() => {
                    if (
                      camera === Camera.SMALL &&
                      cameraMode === CameraMode.API &&
                      !cameraData
                    ) {
                      handleSetShouldShowWarnNoConnectedCameraModal(true);
                      return;
                    }
                    setCurrentSession(record);
                    handleSetIsShowConfirmModal(true);
                  }}
                >
                  Resume
                </Button>
              );
            },
          },
        ]}
      />
      <>
        <WarnNoConnectedCameraModal
          isVisible={shouldShowWarnNoConnectedCameraModal}
        />
        {currentSession && (
          <ConfirmPatientInformationModal
            currentSession={currentSession}
            setSession={setSession}
            isShowConfirmModal={isShowConfirmModal}
            handleSetIsShowConfirmModal={handleSetIsShowConfirmModal}
          />
        )}
      </>
    </Container>
  );
};

export default TeleophthResults;
