import { useMemo } from 'react';

import useBoundStore from 'src/store/useBoundStore';

import { Product } from 'src/models/Product.model';

const useGetRetinalExamDataModelConfig = () => {
  const { product } = useBoundStore();

  const deviceDataModelId = useMemo(() => {
    return product === Product.TELEOPHTH
      ? process.env.REACT_APP_MODEL_ID_RETINAL_EXAM_TELEOPHTH
      : process.env.REACT_APP_DEVICE_MODEL_ID;
  }, [product]);

  const examSubmissionPropertySetId = useMemo(() => {
    return product === Product.TELEOPHTH
      ? process.env
          .REACT_APP_PROPERTY_SET_ID_EXAM_SUBMISSION_RETINAL_EXAM_TELEOPHTH
      : process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION;
  }, [product]);

  return {
    deviceDataModelId,
    examSubmissionPropertySetId,
  };
};

export default useGetRetinalExamDataModelConfig;
