import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Settings } from 'src/models/Settings.model';

interface IBroadcastMessageProps {
  settings: Settings;
}

const BroadcastMessage = ({ settings }: IBroadcastMessageProps) => {
  const [broadcastMessage, setBroadcastMessage] = useState('');

  useEffect(() => {
    setBroadcastMessage(settings.broadcast || '');
  }, [settings]);

  return broadcastMessage === '' ? (
    <></>
  ) : (
    <Alert
      variant="warning"
      className="ms-3 me-0 my-0"
      style={{ padding: 11.5 }}
    >
      <b className="me-2">
        <FontAwesomeIcon icon={faBullhorn} className="me-2"></FontAwesomeIcon>
      </b>
      {broadcastMessage}
    </Alert>
  );
};

export default BroadcastMessage;
