import { ReactNode } from 'react';

import { Text } from '@react-pdf/renderer';
import cx from 'classnames';

import CustomBadge from 'src/components/custom_components/CustomBadge';

type OverallEyeResultBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
  children: string | ReactNode;
};
export function OverallEyeResultBadge({
  className,
  children,
}: OverallEyeResultBadgeProps) {
  return (
    <CustomBadge
      className={cx('px-4 py-2 text-white', className)}
      children={children}
    />
  );
}

export function OverallPositiveResultBadge({
  children,
}: {
  children: string | ReactNode;
}) {
  return <OverallEyeResultBadge children={children} className="bg-danger" />;
}

export function OverallInsufficientResultBadge() {
  return (
    <OverallEyeResultBadge
      children={<Text>Insufficient Exam</Text>}
      className="bg-secondary-700"
    />
  );
}

export function OverallNegativeResultBadge() {
  return (
    <OverallEyeResultBadge
      children={<Text>Negative Result</Text>}
      className="bg-primary-600"
    />
  );
}
