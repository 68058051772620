enum Download {
  userManual = 'user_manual',
  termsOfService = 'terms_of_service',
  other = 'other',
}

const whichDownload = (downloadString: string) => {
  const lowerDownloadString = downloadString.toLowerCase();
  return (
    Object.values(Download).find(
      (type) => type.toLowerCase() === lowerDownloadString,
    ) || Download.other
  );
};

export { Download, whichDownload };
