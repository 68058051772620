import { AxiosResponse } from 'axios';

import {
  Api,
  getUserFromUserId,
  transformExamToSession,
} from 'src/models/Api.model';
import { Session } from 'src/models/Session.model';

import { CriteriaGroupGroupElementsInnerOperatorEnum } from '../CriteriaGroupGroupElementsInner.model';
import { DeviceDataView, ExamData } from '../DeviceDataView.model';
import { OrderData } from '../Order.model';
import { PageOfDeviceDataView } from '../PageOfDeviceDataView.model';
import { SortOrder } from '../SortOrder.model';
import { GalenDataUser } from '../User.model';

const attachExtraData = ({
  orderIds,
  userIds,
  users,
  orders,
  notHiddenSessions,
  hiddenExamsResponses,
}: {
  orderIds: string[];
  userIds: string[];
  users: (GalenDataUser | undefined)[];
  orders: (
    | AxiosResponse<PageOfDeviceDataView<OrderData>, any>
    | {
        data: {
          content: undefined;
          totalElements: number;
        };
      }
  )[];
  notHiddenSessions: Session[];
  hiddenExamsResponses: (
    | AxiosResponse<PageOfDeviceDataView<ExamData>, any>
    | {
        data: {
          content: undefined;
          totalElements: number;
        };
      }
  )[];
}) => {
  const hiddenExamsMap = new Map();
  for (const [index, hiddenExams] of hiddenExamsResponses.entries()) {
    hiddenExamsMap.set(orderIds[index], hiddenExams.data.content || []);
  }

  const userMap = new Map();
  for (const [index, user] of users.entries()) {
    if (user) {
      userMap.set(userIds[index], user.emailAddress ?? '');
    }
  }

  for (const order of orders) {
    const orderData = order.data.content?.[0]?.data;

    // Each not-hidden session should be associated with a unique order.
    const session = notHiddenSessions.find(
      (session) =>
        order.data.content && session.orderId === orderData?.OrderId.value,
    );

    if (session) {
      session.order = orderData;
    }
  }

  for (const session of notHiddenSessions) {
    const hiddenExams = hiddenExamsMap.get(session.orderId);
    if (hiddenExams) {
      session.retakeSessions = hiddenExams.map(transformExamToSession);
    }

    session.username = userMap.get(session.practiceUserId) || '';
  }

  return notHiddenSessions;
};

const getExtraData = async ({
  examsContent,
  deviceDataModelId,
}: {
  examsContent: DeviceDataView<ExamData>[];
  deviceDataModelId: string;
}) => {
  const orderIds = [
    ...new Set(examsContent.map((exam) => exam.data.OrderId.value)),
  ];
  const userIds = [
    ...new Set(examsContent.map((exam) => exam.data.PracticeUserId.value)),
  ];

  const [hiddenExamsResponses, users, orders] = await Promise.all([
    Promise.all(
      orderIds.map((orderId) =>
        Api.remote.get_hidden_exams({
          deviceDataModelId,
          groupElements: [
            {
              key: 'OrderId',
              operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
              value: orderId,
            },
          ],
        }),
      ),
    ),
    Promise.all(userIds.map((userId) => getUserFromUserId(userId))),
    Promise.all(
      orderIds.map((orderId) =>
        Api.remote.get_exam_order({
          groupElements: [
            {
              key: 'OrderId',
              operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
              value: orderId,
            },
          ],
        }),
      ),
    ),
  ]);
  return {
    orderIds,
    userIds,
    hiddenExamsResponses,
    users,
    orders,
  };
};

const loadSessionsWithExtraData = async (
  deviceDataModelId: string,
  page: number,
  numberPerPage: number,
  search: string,
  sortOrder: SortOrder,
): Promise<{
  sessions: Session[];
  totalElements: number;
  totalPages: number;
}> => {
  let searchedUserIds: string[] = [];

  if (search) {
    const patients = await Api.remote._load_patients(search);
    if (!patients.data.content?.length) {
      return {
        sessions: [],
        totalElements: 0,
        totalPages: 0,
      };
    }
    searchedUserIds = patients.data.content.map((patient) => patient.userId);
  }

  const notHiddenExams = await Api.remote._load_exams(
    deviceDataModelId,
    page,
    numberPerPage,
    sortOrder,
    searchedUserIds,
  );

  if (!notHiddenExams.data.content) {
    return {
      sessions: [],
      totalElements: 0,
      totalPages: 0,
    };
  }

  const examsContent = notHiddenExams.data.content;

  const { orderIds, userIds, hiddenExamsResponses, users, orders } =
    await getExtraData({
      examsContent,
      deviceDataModelId,
    });
  const notHiddenSessions = examsContent.map((exam) => ({
    ...transformExamToSession(exam),
    retakeSessions: [] as Session[],
  }));

  const sessionsWithExtraData = attachExtraData({
    orderIds,
    userIds,
    users,
    orders,
    notHiddenSessions,
    hiddenExamsResponses,
  });

  return {
    sessions: sessionsWithExtraData,
    totalElements: notHiddenExams.data.totalElements,
    totalPages: notHiddenExams.data.totalPages,
  };
};

export { loadSessionsWithExtraData };
