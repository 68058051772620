import { Product } from './Product.model';

export type MDRegion = 'EU' | 'US' | 'AU';

export namespace About {
  export const IsoMdSymbol = 'ISO MD Symbol';
  export const UdiSymbol = 'UDI Symbol';
  export const WhiteFactorySymbol = 'White Factory Symbol';
  export const ManufactureDate = '2024-10-30';
  export const BlackFactorySymbol = 'Black Factory Symbol';
  export const ManufacturerName =
    'Eyeltelligence Pty Ltd., an Optain Health Company';
  export const ManufacturerAddress =
    'Chadstone tower 1, Level 8, 1341 Dandenong Road, Chadstone 3148';
  export const ProductVersion = '1.0.0';
  export const UserManualSymbol = 'User Manual Symbol';

  export type RegionSpecificDisplay = {
    UdiNumber: string;
    MDName: { [key in Product]?: string };
    UdiQrCode: string;
    ChRepSymbol?: string;
    ChRepInfoLine1?: string;
    ChRepInfoLine2?: string;
    ChRepInfoLine3?: string;
    ChRepInfoLine4?: string;
    ChRepInfoLine5?: string;
    EcRepInfoLine1?: string;
    EcRepInfoLine2?: string;
    EcRepInfoLine3?: string;
    EcRepInfoLine4?: string;
    EcRepInfoLine5?: string;
    EcRepSymbol?: string;
  };

  export const EU: RegionSpecificDisplay = {
    MDName: {
      [Product.ASSUREPLUS]: 'Assure Plus',
    },
    UdiNumber: '(01)00860011973201(8012)',
    UdiQrCode: 'EU UDI QR code',
    ChRepSymbol: 'CH REP Symbol',
    ChRepInfoLine1: 'Prolens AG',
    ChRepInfoLine2: 'Josefstrasse 53',
    ChRepInfoLine3: '8005 Zürich',
    ChRepInfoLine4: 'Switzerland',
    ChRepInfoLine5: 'info@prolens.ch',
    EcRepInfoLine1: 'MedEnvoy Global BV',
    EcRepInfoLine2: 'Prinses Margrietplantsoen 33, Suite 123',
    EcRepInfoLine3: '2595 AM The Hague',
    EcRepInfoLine4: 'The Netherlands',
    EcRepInfoLine5: 'Vigilance@medenvoyglobal.com',
    EcRepSymbol: 'EC REP Symbol',
  };

  export const US: RegionSpecificDisplay = {
    MDName: {
      [Product.OPTAIN]: 'OPTDR01',
      [Product.TELEOPHTH]: 'Teleophthalmology',
    },
    UdiNumber: '(01)00860011973218(8012)',
    UdiQrCode: 'US UDI QR code',
  };

  export const AU: RegionSpecificDisplay = {
    MDName: {
      [Product.ASSUREPLUS_AU]: 'Assure Plus',
    },
    UdiNumber: '(01)00860011973225(8012)',
    UdiQrCode: 'AU UDI QR code',
  };
}
